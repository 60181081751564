import { Box, HelpPanel, Link } from '@amzn/awsui-components-react';
import { UEEE_WIKI_LINK } from 'config/navConfig';

const ToolsContent = {
  'ueee-info-link': (
    <HelpPanel
      header={<Box variant="h2">Used Electrical and Electronic Equipment (UEEE)</Box>}
      footer={
        <>
          <Box variant="h2">Resources</Box>
          <ul>
            <li>
              <Link variant="info" external externalIconAriaLabel="External Link" href={UEEE_WIKI_LINK}>
                UEEE Wiki
              </Link>
            </li>
          </ul>
        </>
      }
    >
      <Box variant="p">
        To ensure compliance with global requirements for shipping UEEE, AWS Global Trade and Product Compliance (GTPC;
        with legal advice from AWS Legal Regulatory Risk Management and Compliance (R2MC)) manages the UEEE Compliance
        Program. Please see the UEEE Wiki for more information.
      </Box>
      <Box variant="p">
        This field determines whether a UEEE declaration is required for this from country, to country, and transaction
        type.
      </Box>
    </HelpPanel>
  ),
};

export default ToolsContent;
