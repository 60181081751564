import { useNavigate } from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';
import { useCreateIncotermRelationMutation } from 'api/baseApi';
import { RelationType } from '@amzn/cip-bff-schema';
import { v4 as uuid } from 'uuid';
import IncotermCommonForm, { IncotermRelationFormInputs, incoFormSchemaToApiSchema } from 'features/IncotermCommon';
import { IncotermRelationDisplayConfig } from 'types/constants';
import { setIncoCreateFlashbarItems, setIncoLandingFlashbarItems } from 'store/incoterms';
import { useAppDispatch } from 'store/store';
import { PROGRAM_TYPES } from 'config/incotermConstants';
import { FlashbarItemsProps } from 'types';

interface IncotermRelationCreateProps {
  relationType: RelationType;
  relationDisplay: IncotermRelationDisplayConfig;
}

const IncotermRelationCreatePage: React.FC<IncotermRelationCreateProps> = ({ relationType, relationDisplay }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { shortName: relationshipShortName } = relationDisplay;
  const routePath = relationshipShortName.toLowerCase();
  const [createIncotermRelation, { isLoading }] = useCreateIncotermRelationMutation();

  const onSubmit: SubmitHandler<IncotermRelationFormInputs> = async (data) => {
    const apiSchemaRelation = incoFormSchemaToApiSchema(data, relationType);

    const errorMessage: FlashbarItemsProps = {
      header: 'Error',
      type: 'error',
      content: `There was an error creating the ${relationshipShortName} decision.`,
      dismissId: uuid(),
    };

    const res = await createIncotermRelation(apiSchemaRelation);
    if (res && 'data' in res) {
      const createResponse = res?.data?.createIncotermRelation;
      const successes: FlashbarItemsProps[] = createResponse?.data
        ? createResponse?.data?.map(() => ({
            header: 'Decision Created Successfully',
            type: 'success',
            content: `The ${relationshipShortName} decision has been created.`,
            dismissId: uuid(),
          }))
        : [];
      const failures: FlashbarItemsProps[] = createResponse?.failed
        ? createResponse?.failed?.map((failure) => {
            errorMessage.content += ` Error: ${failure?.errorType}`;
            return errorMessage;
          })
        : [];
      dispatch(setIncoLandingFlashbarItems([...successes, ...failures]));
      navigate(`/incoterms/${routePath}`);
    } else {
      dispatch(setIncoCreateFlashbarItems([errorMessage]));
    }
  };

  const incotermCreate = (
    <IncotermCommonForm
      isLoading={isLoading}
      relationType={relationType}
      onSubmit={onSubmit}
      formHeaderText={`${relationshipShortName} Decision`}
      submitText="Submit"
      cancelRoute={`/incoterms/${routePath}`}
      defaultValues={{ programType: PROGRAM_TYPES[0] }}
    />
  );

  return incotermCreate;
};

export default IncotermRelationCreatePage;
