import {
  Alert,
  ColumnLayout,
  Container,
  Header,
  Pagination,
  SpaceBetween,
  Spinner,
  Table,
} from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { useGetTradeRouteAuditHistoryQuery } from 'api/baseApi';
import { Audit, RouteSchedule, RouteScheduleStatus, TaxInfo, TransactionType } from '@amzn/cip-bff-schema';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { getTableSelectionLabels } from 'utils/tableUtils';
import { COLUMN_DEFS } from '.';
import React, { useEffect, useState } from 'react';
import ValueWithLabel from 'components/ValueWithLabel';
import { useAppSelector } from 'store/store';
import ObjectFieldDiff, { ChangeType } from 'components/ObjectFieldDiff';
import { ROUTE_STATUSES, SCHEDULE_STATUSES } from '@amzn/gtpccipstatic-config/dist/constants';
import { Mode } from '@amzn/awsui-global-styles';
import { formatFromEpoch } from 'utils/dateTimeUtils';
import { setTRSAuditFlashbarItems } from 'store/trs';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { formatPercentage } from '../../utils/formatUtils';
import { areAllNullish } from '../../utils/formUtils';

interface TRSHistoryPageProps {
  fromCountry?: OptionDefinition;
  toCountry?: OptionDefinition;
  transactionType?: OptionDefinition;
  hasRequiredFields: boolean;
}

export const TaxRateInformationDifference: React.FC<{
  oldTaxInfo: TaxInfo | undefined;
  newTaxInfo: TaxInfo | undefined;
  visualMode: Mode;
  changeType: ChangeType;
}> = ({ oldTaxInfo, newTaxInfo, visualMode, changeType }) => {
  // check for legal entity based tax rate changes
  let legalEntityBasedTaxRateSection;
  if (oldTaxInfo?.legalEntityBasedTaxRate || newTaxInfo?.legalEntityBasedTaxRate) {
    const oldLegalEntityBasedTaxRate = oldTaxInfo?.legalEntityBasedTaxRate;
    const newLegalEntityBasedTaxRate = newTaxInfo?.legalEntityBasedTaxRate;
    const oldSameLegalEntityTaxRate = oldLegalEntityBasedTaxRate?.sameLegalEntityTaxDocumentNotRequired
      ? 'Not Required'
      : formatPercentage(oldLegalEntityBasedTaxRate?.sameLegalEntityTaxRatePercentage);
    const newSameLegalEntityTaxRate = newLegalEntityBasedTaxRate?.sameLegalEntityTaxDocumentNotRequired
      ? 'Not Required'
      : formatPercentage(newLegalEntityBasedTaxRate?.sameLegalEntityTaxRatePercentage);
    legalEntityBasedTaxRateSection = (
      <SpaceBetween size="l">
        <ValueWithLabel label="Tax Rate - Same Legal Entity">
          <ObjectFieldDiff
            oldField={oldSameLegalEntityTaxRate}
            newField={newSameLegalEntityTaxRate}
            visualMode={visualMode}
            changeType={changeType}
          />
        </ValueWithLabel>
        <ValueWithLabel label="Tax Rate - Different Legal Entities">
          <ObjectFieldDiff
            oldField={formatPercentage(oldLegalEntityBasedTaxRate?.differentLegalEntitiesTaxRatePercentage)}
            newField={formatPercentage(newLegalEntityBasedTaxRate?.differentLegalEntitiesTaxRatePercentage)}
            visualMode={visualMode}
            changeType={changeType}
          />
        </ValueWithLabel>
      </SpaceBetween>
    );
  }

  // check for generic tax rate changes
  let genericTaxRateSection;
  if (!areAllNullish(oldTaxInfo?.genericTaxRatePercentage) || !areAllNullish(newTaxInfo?.genericTaxRatePercentage)) {
    genericTaxRateSection = (
      <ValueWithLabel label="Tax Rate - Generic">
        <ObjectFieldDiff
          oldField={formatPercentage(oldTaxInfo?.genericTaxRatePercentage)}
          newField={formatPercentage(newTaxInfo?.genericTaxRatePercentage)}
          visualMode={visualMode}
          changeType={changeType}
        />
      </ValueWithLabel>
    );
  }

  // check for tax rate status changes
  let taxRateStatusSection;
  if (oldTaxInfo?.taxRateStatus || newTaxInfo?.taxRateStatus) {
    taxRateStatusSection = (
      <ValueWithLabel label="Tax Rate Status">
        <ObjectFieldDiff
          oldField={oldTaxInfo?.taxRateStatus}
          newField={newTaxInfo?.taxRateStatus}
          visualMode={visualMode}
          changeType={changeType}
        />
      </ValueWithLabel>
    );
  }

  // check for tax comments changes
  let taxCommentsSection;
  if (oldTaxInfo?.taxComments || newTaxInfo?.taxComments) {
    taxCommentsSection = (
      <ValueWithLabel label="Tax Comments">
        <ObjectFieldDiff
          oldField={oldTaxInfo?.taxComments}
          newField={newTaxInfo?.taxComments}
          visualMode={visualMode}
          changeType={changeType}
        />
      </ValueWithLabel>
    );
  }

  return (
    <SpaceBetween size="l">
      {legalEntityBasedTaxRateSection}
      {genericTaxRateSection}
      {taxRateStatusSection}
      {taxCommentsSection}
    </SpaceBetween>
  );
};

export const getChangeDifference = (selectedItem: Audit, visualMode: Mode): JSX.Element | null => {
  try {
    const oldSchedule: RouteSchedule = JSON.parse(selectedItem?.oldData);
    const newSchedule: RouteSchedule = JSON.parse(selectedItem?.newData);

    let changeType: ChangeType = 'UPDATE';
    if (selectedItem?.oldData && selectedItem?.oldData === '{}') changeType = 'CREATE';
    else if (newSchedule?.scheduleStatus === RouteScheduleStatus.DELETED) changeType = 'DELETE';

    return (
      <ColumnLayout columns={3} variant="text-grid">
        <SpaceBetween size="l">
          <ValueWithLabel label="Effective Date">
            <ObjectFieldDiff
              oldField={oldSchedule?.effectiveDate ? formatFromEpoch(oldSchedule?.effectiveDate) : ''}
              newField={newSchedule?.effectiveDate ? formatFromEpoch(newSchedule?.effectiveDate) : ''}
              visualMode={visualMode}
              changeType={changeType}
            />
          </ValueWithLabel>
          <ValueWithLabel label="End Date">
            <ObjectFieldDiff
              oldField={oldSchedule?.endDate ? formatFromEpoch(oldSchedule?.endDate) : ''}
              newField={newSchedule?.endDate ? formatFromEpoch(newSchedule?.endDate) : ''}
              visualMode={visualMode}
              changeType={changeType}
            />
          </ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="l">
          <ValueWithLabel label="Schedule Status">
            <ObjectFieldDiff
              oldField={oldSchedule?.scheduleStatus}
              newField={newSchedule?.scheduleStatus}
              visualMode={visualMode}
              changeType={changeType}
              displayValueMapping={SCHEDULE_STATUSES}
            />
          </ValueWithLabel>
          <ValueWithLabel label="Blocked Route">
            <ObjectFieldDiff
              oldField={oldSchedule?.routeStatus}
              newField={newSchedule?.routeStatus}
              visualMode={visualMode}
              changeType={changeType}
              displayValueMapping={ROUTE_STATUSES}
            />
          </ValueWithLabel>
          <ValueWithLabel label="Manual Trade Action Required">
            <ObjectFieldDiff
              oldField={oldSchedule?.manualTradeActionRequired ? 'Yes' : 'No'}
              newField={newSchedule?.manualTradeActionRequired ? 'Yes' : 'No'}
              visualMode={visualMode}
              changeType={changeType}
            />
          </ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="l">
          <ValueWithLabel label="Commercial Invoice Required">
            <ObjectFieldDiff
              oldField={oldSchedule?.commercialInvoiceRequired ? 'Yes' : 'No'}
              newField={newSchedule?.commercialInvoiceRequired ? 'Yes' : 'No'}
              visualMode={visualMode}
              changeType={changeType}
            />
          </ValueWithLabel>
          <ValueWithLabel label="CI Statement">
            <ObjectFieldDiff
              oldField={oldSchedule?.ciStatement}
              newField={newSchedule?.ciStatement}
              visualMode={visualMode}
              changeType={changeType}
            />
          </ValueWithLabel>
          <ValueWithLabel label="Used Equipment Allowed">
            <ObjectFieldDiff
              oldField={oldSchedule?.usedEquipmentAllowed ? 'Yes' : 'No'}
              newField={newSchedule?.usedEquipmentAllowed ? 'Yes' : 'No'}
              visualMode={visualMode}
              changeType={changeType}
            />
          </ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="l">
          <ValueWithLabel label="Tax Invoice Required">
            <ObjectFieldDiff
              oldField={oldSchedule?.vatInvoiceRequired ? 'Yes' : 'No'}
              newField={newSchedule?.vatInvoiceRequired ? 'Yes' : 'No'}
              visualMode={visualMode}
              changeType={changeType}
            />
          </ValueWithLabel>
          <TaxRateInformationDifference
            oldTaxInfo={oldSchedule?.taxInfo || undefined}
            newTaxInfo={newSchedule?.taxInfo || undefined}
            visualMode={visualMode}
            changeType={changeType}
          />
          <ValueWithLabel label="Tax Statement">
            <ObjectFieldDiff
              oldField={oldSchedule?.vatStatement}
              newField={newSchedule?.vatStatement}
              visualMode={visualMode}
              changeType={changeType}
            />
          </ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="l">
          <ValueWithLabel label="Importer of Record">
            <ObjectFieldDiff
              oldField={oldSchedule?.ior}
              newField={newSchedule?.ior}
              visualMode={visualMode}
              changeType={changeType}
            />
          </ValueWithLabel>
          <ValueWithLabel label="Exporter of Record">
            <ObjectFieldDiff
              oldField={oldSchedule?.eor}
              newField={newSchedule?.eor}
              visualMode={visualMode}
              changeType={changeType}
            />
          </ValueWithLabel>
          <ValueWithLabel label="Incoterms">
            <ObjectFieldDiff
              oldField={oldSchedule?.incoterms}
              newField={newSchedule?.incoterms}
              visualMode={visualMode}
              changeType={changeType}
            />
          </ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="l">
          <ValueWithLabel label="UEEE Declaration Required">
            <ObjectFieldDiff
              oldField={oldSchedule?.ueeeNeeded}
              newField={newSchedule?.ueeeNeeded}
              visualMode={visualMode}
              changeType={changeType}
            />
          </ValueWithLabel>
          <ValueWithLabel label="COO Required">
            <ObjectFieldDiff
              oldField={oldSchedule?.cooRequired}
              newField={newSchedule?.cooRequired}
              visualMode={visualMode}
              changeType={changeType}
            />
          </ValueWithLabel>
          <ValueWithLabel label="Packing List Required">
            <ObjectFieldDiff
              oldField={oldSchedule?.packingListRequired}
              newField={newSchedule?.packingListRequired}
              visualMode={visualMode}
              changeType={changeType}
            />
          </ValueWithLabel>
        </SpaceBetween>
      </ColumnLayout>
    );
  } catch {
    throw new Error(`Error parsing JSON in change difference`);
  }
};

const TRSHistoryPage: React.FC<TRSHistoryPageProps> = ({
  fromCountry,
  toCountry,
  transactionType,
  hasRequiredFields,
}) => {
  const dispatch = useDispatch();
  const { visualMode } = useAppSelector((state) => state.config);
  const [defaultSelectedItem, setDefaultSelectedItem] = useState<Audit[] | undefined>(undefined);
  const [pageSize] = useState(15);
  const { data, isLoading, isSuccess, isError } = useGetTradeRouteAuditHistoryQuery(
    {
      fromCountry: fromCountry?.value ?? '',
      toCountry: toCountry?.value ?? '',
      transactionType: transactionType?.value as TransactionType,
    },
    {
      skip: !hasRequiredFields,
    }
  );

  useEffect(() => {
    if (isError)
      dispatch(
        setTRSAuditFlashbarItems([
          {
            header: 'Error',
            type: 'error',
            content: `Could not retrieve audit history. Please wait a moment and try again.`,
            dismissId: uuid(),
          },
        ])
      );
  }, [dispatch, isError]);

  const auditHistory = data?.routeAuditHistory?.auditHistory;

  useEffect(() => {
    if (isSuccess && auditHistory && auditHistory?.length > 0) setDefaultSelectedItem([auditHistory[0]]);
  }, [auditHistory, isSuccess]);

  const { items, collectionProps, paginationProps } = useCollection<Audit>(auditHistory ?? [], {
    filtering: {
      empty: hasRequiredFields ? 'No route history' : '',
      noMatch: 'No match',
    },
    sorting: {},
    pagination: { pageSize },
    selection: {
      defaultSelectedItems: defaultSelectedItem,
    },
  });

  const { selectedItems } = collectionProps;
  const isOnlyOneSelected = selectedItems?.length === 1;
  const selectedItem = isOnlyOneSelected ? (selectedItems[0] as Audit) : undefined;

  const routeHistoryHeader = <Header>Route History</Header>;
  const routeHistoryTable = (
    <Table
      header={routeHistoryHeader}
      columnDefinitions={COLUMN_DEFS}
      items={items}
      selectionType="single"
      ariaLabels={getTableSelectionLabels<Audit>('Trade route schedule')}
      loading={isLoading}
      pagination={<Pagination {...paginationProps} />}
      {...collectionProps}
      trackBy="modifiedAt"
    />
  );

  const changeDiffHeader = (
    <Header description="Shows the difference in data for each change made to a trade route. Green - addition; Red - deletion; No background - no change or a default value.">
      Change Difference
    </Header>
  );
  const changeDiffContainer = (
    <Container header={changeDiffHeader}>
      {selectedItem ? (
        getChangeDifference(selectedItem, visualMode)
      ) : (
        <Alert type="info">Nothing to compare, please select an item in the table</Alert>
      )}
    </Container>
  );

  return !isLoading ? (
    <SpaceBetween size="l">
      {routeHistoryTable}
      {changeDiffContainer}
    </SpaceBetween>
  ) : (
    <Spinner />
  );
};

export default TRSHistoryPage;
