import { Box, HelpPanel, Link } from '@amzn/awsui-components-react';

const INCOTERMS_EXPLANATION_WIKI_LINK =
  'https://w.amazon.com/bin/view/AWS_Global_Trade_Product_Compliance_GTPC/Import101/#HIncoterms';

const ToolsContent = {
  'incoterms-info-link': (
    <HelpPanel
      header={<Box variant="h2">Intoterm Relations</Box>}
      footer={
        <>
          <Box variant="h2">Resources</Box>
          <ul>
            <li>
              <Link
                variant="info"
                external
                externalIconAriaLabel="External Link"
                href={INCOTERMS_EXPLANATION_WIKI_LINK}
              >
                Import Incoterms Wiki
              </Link>
            </li>
          </ul>
        </>
      }
    >
      <Box variant="p">{/* Add content here */}</Box>
      <Box variant="p"></Box>
    </HelpPanel>
  ),
};

export default ToolsContent;
