import { areAllNullish } from '../formUtils';

/**
 * Format Tax Rate Percentage to a string if the input is not null or undefined
 * @param {number | null | undefined} percentNumber - number to format
 * @return {string  | undefined} - formatted string or undefined
 */
export function formatPercentage(percentNumber: number | null | undefined): string | undefined {
  if (!areAllNullish(percentNumber)) {
    return `${percentNumber}%`;
  }
  return undefined;
}
