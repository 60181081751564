import { gql } from 'graphql-request';

export const CREATE_TRS = gql`
  mutation CreateRouteSchedule($input: CreateRouteScheduleInput!) {
    createRouteSchedule(input: $input) {
      scheduleId
      scheduleStatus
      fromCountry
      toCountry
      transactionType
      effectiveDate
      usedEquipmentAllowed
    }
  }
`;

export const UPDATE_TRS = gql`
  mutation UpdateRouteSchedule($input: UpdateRouteScheduleInput!) {
    updateRouteSchedule(input: $input) {
      scheduleId
      scheduleStatus
      fromCountry
      toCountry
      transactionType
      effectiveDate
      usedEquipmentAllowed
    }
  }
`;

export const ASSIGN_LICENSE = gql`
  mutation AssignLicense($input: AssignLicenseInput!) {
    assignLicense(input: $input) {
      partId
      transactionId
      licenseData {
        licenseType
        licenseCategory
        licenseValue
      }
    }
  }
`;

/** CI Review */
export const UPDATE_CI_REVIEW = gql`
  mutation UpdateCommercialInvoiceReview($input: UpdateCommercialInvoiceReviewInput!) {
    updateCommercialInvoiceReview(input: $input) {
      transactionId
    }
  }
`;

/* INCOTERMS */

export const CREATE_INCOTERM_RELATION = gql`
  mutation CreateIncotermRelation($input: CreateIncotermRelationInput!) {
    createIncotermRelation(input: $input) {
      data {
        identifierType
        relationType
        supplier
        supplierName
        supplierHasImportCapability
        awsHasImportCapability
        programType
        productCategory
        destinationCountry
        destinationCountryName
        orderingEntity
        orderingEntityName
        relationValue
        changeNotes
        modifiedBy
        modifiedAt
        version
      }
      failed {
        reason
        errorType
        identifierType
      }
    }
  }
`;

export const UPDATE_INCOTERM_RELATION = gql`
  mutation UpdateIncotermRelation($input: UpdateIncotermRelationInput!) {
    updateIncotermRelation(input: $input) {
      identifierType
      relationType
      supplier
      supplierName
      supplierHasImportCapability
      awsHasImportCapability
      programType
      productCategory
      destinationCountry
      destinationCountryName
      orderingEntity
      orderingEntityName
      relationValue
      changeNotes
      modifiedBy
      modifiedAt
      version
    }
  }
`;

export const DELETE_INCOTERM_RELATION = gql`
  mutation DeleteIncotermRelation($input: DeleteIncotermRelationInput!) {
    deleteIncotermRelation(input: $input)
  }
`;
