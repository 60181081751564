import { areAllNullish, isOneNullish, optionDefinitionSchema } from 'utils/formUtils';
import { getUnixTime, isValid, parseISO } from 'date-fns';
import * as z from 'zod';
import {
  INCOTERMS,
  RECORD_ROLES_EOR,
  RECORD_ROLES_IOR,
  ROUTE_STATUSES,
  SCHEDULE_STATUSES,
  VAT_STATUS,
} from '@amzn/gtpccipstatic-config/dist/constants';
import { POLARIS_DATE_PATTERN, POLARIS_TIME_PATTERN, formatFromEpoch } from 'utils/dateTimeUtils';
import { Incoterms, RecordRole, RouteScheduleStatus, RouteStatus, TaxRateStatus } from '@amzn/cip-bff-schema';
import { ZOD_NONEMPTY_STRING } from '@amzn/gtpccipstatic-config/dist/constants';

const taxRateSchema = z
  .object({
    vatInvoiceRequired: z.boolean().default(false),
    isTaxRateLEBased: z.boolean(),
    hasTaxRate: z.boolean(),
    taxRateSameLE: z.number().min(0).max(100).nullable(),
    taxRateDiffLE: z.number().min(0).max(100).nullable(),
    taxRate: z.number().min(0).max(100).nullable(),
    taxComments: z.string().max(1000).optional().nullable(),
  })
  .partial()
  .superRefine((data, ctx) => {
    if (data.hasTaxRate && data.isTaxRateLEBased && !data.taxRateDiffLE) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['taxRateDiffLE'],
        message: 'Tax rate for differing legal entities is required if tax rate is legal entity based.',
      });
    }
    if (data.vatInvoiceRequired && data.hasTaxRate && !data.isTaxRateLEBased && isOneNullish(data?.taxRate)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['taxRate'],
        message:
          'Tax rate is required if tax invoice is required, the route has a tax rate, and tax rate is not based on legal entities.',
      });
    }
  });

export const trsFormSchema = z.object({
  effectiveDate: z.string().min(10),
  effectiveTime: z.string().min(4).default('00:00'),
  endDate: z.string().optional(),
  endTime: z.string().optional(),
  scheduleStatus: z.boolean().default(false),
  routeStatus: optionDefinitionSchema,
  changeNotes: z.string().max(256).optional(),
  usedEquipmentAllowed: z.boolean().default(false),
  manualTradeActionRequired: z.boolean().default(false),
  commercialInvoiceRequired: z.boolean().default(false),
  vatStatement: z.string().max(1000).optional(),
  ciStatement: z.string().max(1000).optional(),
  incoterms: optionDefinitionSchema,
  ior: optionDefinitionSchema.optional(),
  eor: optionDefinitionSchema.optional(),
  ueeeNeeded: z.boolean().default(false),
  cooRequired: z.boolean().default(false),
  packingListRequired: z.boolean().default(false),
  taxRateSchema,
});

export const formSchemaToApiSchema = trsFormSchema.transform((tr) => {
  const { taxRate, taxRateSameLE, taxRateDiffLE, hasTaxRate, vatInvoiceRequired, taxComments } = tr.taxRateSchema;
  const effectiveISO = parseISO(`${tr.effectiveDate}T${tr.effectiveTime}:00`);
  const effective = isValid(effectiveISO) ? getUnixTime(effectiveISO) : undefined;
  const endISO = parseISO(`${tr.endDate}T${tr.endTime ? `${tr.endTime}:00` : ''}`);
  // end time, if not valid, should be sent as null since undefined will be a no-op
  const end = isValid(endISO) ? getUnixTime(endISO) : null;
  return {
    effectiveDate: effective,
    endDate: end,
    scheduleStatus: tr.scheduleStatus === true ? RouteScheduleStatus.ACTIVE : RouteScheduleStatus.INACTIVE,
    routeStatus: tr.routeStatus?.value as RouteStatus,
    incoterms: tr.incoterms?.value as Incoterms,
    ior: tr.ior?.value as RecordRole,
    eor: tr.eor?.value as RecordRole,
    changeNotes: tr.changeNotes ?? '',
    usedEquipmentAllowed: tr.usedEquipmentAllowed,
    manualTradeActionRequired: tr.manualTradeActionRequired,
    commercialInvoiceRequired: tr.commercialInvoiceRequired,
    vatInvoiceRequired: tr.taxRateSchema?.vatInvoiceRequired ?? false,
    vatStatement: tr.vatStatement,
    ciStatement: tr.ciStatement,
    ueeeNeeded: tr.ueeeNeeded,
    cooRequired: tr.cooRequired,
    packingListRequired: tr.packingListRequired,
    taxInfo: vatInvoiceRequired
      ? {
          taxRateStatus: hasTaxRate ? undefined : TaxRateStatus.NOT_APPROVED,
          legalEntityBasedTaxRate:
            !areAllNullish(taxRateSameLE, taxRateDiffLE) && hasTaxRate
              ? {
                  sameLegalEntityTaxRatePercentage: taxRateSameLE,
                  differentLegalEntitiesTaxRatePercentage: taxRateDiffLE,
                }
              : undefined,
          genericTaxRatePercentage: hasTaxRate && areAllNullish(taxRateSameLE, taxRateDiffLE) ? taxRate : undefined,
          taxComments,
        }
      : undefined,
  };
});

/** change notes are required in edit but not in create */
export const trsEditFormSchema = trsFormSchema.extend({ changeNotes: ZOD_NONEMPTY_STRING.max(256) });

export type TradeRouteScheduleFormInputs = z.infer<typeof trsFormSchema>;

export const trsApiSchema = z.object({
  effectiveDate: z.number(),
  endDate: z.number().optional().nullable(),
  scheduleStatus: z.string(),
  routeStatus: z.string(),
  changeNotes: z.string().optional(),
  usedEquipmentAllowed: z.boolean().default(false),
  ueeeNeeded: z.boolean().default(false).nullable(),
  cooRequired: z.boolean().default(false).nullable(),
  packingListRequired: z.boolean().default(false).nullable(),
  manualTradeActionRequired: z.boolean().default(false),
  commercialInvoiceRequired: z.boolean().default(false),
  vatInvoiceRequired: z.boolean().default(false),
  vatStatement: z.string().optional().nullable(),
  ciStatement: z.string().optional().nullable(),
  incoterms: z.string().optional().nullable(),
  ior: z.string().optional().nullable(),
  eor: z.string().optional().nullable(),
  taxInfo: z
    .object({
      taxRateStatus: z.nativeEnum(TaxRateStatus).nullable().optional(),
      legalEntityBasedTaxRate: z
        .object({
          sameLegalEntityTaxRatePercentage: z.number().optional().nullable(),
          differentLegalEntitiesTaxRatePercentage: z.number().optional().nullable(),
        })
        .nullable(),
      genericTaxRatePercentage: z.number().optional().nullable(),
      taxComments: z.string().optional().nullable(),
    })
    .optional()
    .nullable(),
});

export const apiSchemaToFormSchema = trsApiSchema.transform((tr) => {
  const leBasedTaxRate = tr?.taxInfo?.legalEntityBasedTaxRate;
  return {
    ...tr,
    effectiveDate: formatFromEpoch(tr.effectiveDate, POLARIS_DATE_PATTERN),
    effectiveTime: formatFromEpoch(tr.effectiveDate, POLARIS_TIME_PATTERN),
    endDate: tr.endDate ? formatFromEpoch(tr?.endDate, POLARIS_DATE_PATTERN) : undefined,
    endTime: tr.endDate ? formatFromEpoch(tr?.endDate, POLARIS_TIME_PATTERN) : undefined,
    scheduleStatus: SCHEDULE_STATUSES.find((s) => s.value === tr.scheduleStatus)?.value === 'ACTIVE',
    routeStatus: ROUTE_STATUSES.find((s) => s.value === tr.routeStatus),
    incoterms: INCOTERMS.find((i) => i.value === tr.incoterms),
    ior: RECORD_ROLES_IOR.find((rr) => rr.value === tr.ior),
    eor: RECORD_ROLES_EOR.find((rr) => rr.value === tr.eor),
    ciStatement: tr.ciStatement ?? undefined,
    vatStatement: tr.vatStatement ?? undefined,
    ueeeNeeded: tr.ueeeNeeded ?? false,
    cooRequired: tr.cooRequired ?? false,
    packingListRequired: tr.packingListRequired ?? false,
    taxRateSchema: {
      hasTaxRate: VAT_STATUS.find((vs) => vs.value === tr.taxInfo?.taxRateStatus)?.value !== 'NOT_APPROVED',
      vatInvoiceRequired: tr?.vatInvoiceRequired ?? false,
      isTaxRateLEBased:
        isOneNullish(tr?.taxInfo?.genericTaxRatePercentage) &&
        !areAllNullish(
          leBasedTaxRate?.sameLegalEntityTaxRatePercentage,
          leBasedTaxRate?.differentLegalEntitiesTaxRatePercentage
        ),
      taxRateSameLE: leBasedTaxRate?.sameLegalEntityTaxRatePercentage,
      taxRateDiffLE: leBasedTaxRate?.differentLegalEntitiesTaxRatePercentage,
      taxRate: tr?.taxInfo?.genericTaxRatePercentage,
      taxComments: tr?.taxInfo?.taxComments,
    },
  };
});

export { default } from './TradeRouteCommon';
