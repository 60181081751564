import { addHours, format, fromUnixTime, parse } from 'date-fns';

export const DATE_TIME_PATTERN = "LLLL dd, yyyy HH:mm ('UTC'X)";
export const DATE_TIME_PATTERN_SHORT = "yyyy-MM-dd HH:mm ('UTC'X)";
export const POLARIS_DATE_PATTERN = 'yyyy-MM-dd';
export const POLARIS_TIME_PATTERN = 'HH:mm';

export const getEndTimeFromSetEffectiveTime = (
  value: string,
  effectiveDate: string,
  effectiveTime: string,
  endDate?: string,
  endTime?: string
): string => {
  // check that endTime is always ahead of effectiveTime if schedule starts and ends on the same day
  if (effectiveDate === endDate) {
    const effectiveTimeValue = parse(effectiveTime, POLARIS_TIME_PATTERN, new Date());
    const endTimeValue = parse(endTime ?? '', POLARIS_TIME_PATTERN, new Date());
    if ((endDate && !endTime) || effectiveTimeValue >= endTimeValue) {
      return format(addHours(effectiveTimeValue, 1), POLARIS_TIME_PATTERN);
    }
  } else if (endDate) {
    // if we have end date set then default end time to the end of the day
    return '23:59';
  }
  return value;
};

export const formatFromEpoch = (epochSeconds: number, pattern?: string): string => {
  return format(fromUnixTime(epochSeconds), pattern ?? DATE_TIME_PATTERN);
};
