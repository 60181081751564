import { Button, Container, Form, FormField, Header, Input, Select, SpaceBetween } from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { InvalidInput, LicenseCategory, Mutation, TransactionErrorType } from '@amzn/cip-bff-schema';
import { LICENSE_CATEGORIES, LICENSE_TYPES } from '@amzn/gtpccipstatic-config/dist/constants';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorResponse, useAssignLicenseMutation } from 'api/baseApi';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { setAssignLicenseFlashbarItems } from 'store/assignLicense';
import { AssignLicenseFormInputs, assignLicenseFormSchema, formSchemaToApiSchema } from '.';
import { useSearchParams } from 'react-router-dom';
import { hasGQLError } from 'utils/typeUtils';

/* All messaging on the UI is using 'Authorization'. Backend endpoints and data models are using 'License'. The terminologies are interchangeable */
const AssignLicense: React.FC = () => {
  const dispatch = useDispatch();
  const [queryParams] = useSearchParams();

  const partId = queryParams.get('partId');
  const transactionId = queryParams.get('transactionId');

  const { control, handleSubmit, watch } = useForm<AssignLicenseFormInputs>({
    resolver: zodResolver(assignLicenseFormSchema),
    defaultValues: { partId: partId ?? undefined, transactionId: transactionId ?? undefined },
    mode: 'onChange',
  });

  const [assignLicenseMutation, { isLoading }] = useAssignLicenseMutation();
  const { licenseData } = watch();
  const onSubmit: SubmitHandler<AssignLicenseFormInputs> = async (data) => {
    const assignLicenseInput = formSchemaToApiSchema.parse(data);
    const res = (await assignLicenseMutation(assignLicenseInput)) as { data: Mutation; error: ErrorResponse };
    if (res?.data) {
      dispatch(
        setAssignLicenseFlashbarItems([
          {
            header: 'Authorization Assignment Successful',
            type: 'success',
            content: `The authorization has been assigned successfully.`,
            dismissId: uuid(),
          },
        ])
      );
    } else {
      // TODO: This is pretty ugly. Improve it once a more general error handler is implemented in the FE.
      let content = `There was an unknown error while assigning the authorization.`;
      if (hasGQLError(res) && res?.error?.errors?.length > 0) {
        const laInputError = res?.error?.errors?.find(
          (er) => er.errorType === TransactionErrorType.INVALID_INPUT_ERROR
        );
        if (laInputError) content = 'There was an error while assigning the authorization due to invalid input.';
        const errorInfo = laInputError?.errorInfo;
        if (errorInfo && 'invalidInput' in errorInfo) {
          const transactionPartNotFound = errorInfo?.invalidInput?.includes(InvalidInput.TRANSACTION_PART_NOT_FOUND);
          if (transactionPartNotFound) {
            content += ' The provided combination of part and transaction ID was not found.';
          }
          const licenseNotFound = errorInfo?.invalidInput?.includes(InvalidInput.LICENSE_NOT_FOUND);
          if (licenseNotFound) {
            content +=
              ' The provided authorization ID was not found (please double check you are inputting the UUID from Artifacts Manager, e.g. aa0c31b6-415b-48fb-8b0c-abd97075bc97).';
          }
          const licenseOverLimit = errorInfo?.invalidInput?.includes(InvalidInput.LICENSE_OVER_LIMIT);
          if (licenseOverLimit) {
            content +=
              ' The provided authorization cannot be used: Authorization will be over usage limit with the quantity and value of the part.';
          }
          const licenseInconsistentCurrency = errorInfo?.invalidInput?.includes(
            InvalidInput.LICENSE_INCONSISTENT_CURRENCY
          );
          if (licenseInconsistentCurrency) {
            content +=
              ' The provided authorization cannot be used: Authorization currency is inconsistent with the currency of the part.';
          }
        }
      }
      dispatch(
        setAssignLicenseFlashbarItems([
          {
            header: 'Error',
            type: 'error',
            content,
            dismissId: uuid(),
          },
        ])
      );
    }
  };

  const formContent = (
    <Container
      header={
        <Header description="Please only utilize transaction and part IDs received via SIM ticket">
          Assign an Authorization
        </Header>
      }
    >
      <SpaceBetween size="l">
        <Controller
          name="transactionId"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormField label="Transaction ID" description="TOA transaction ID" errorText={error && error.message}>
              <Input value={value as string} onChange={(e) => onChange(e.detail.value)} placeholder="Enter a value" />
            </FormField>
          )}
        />
        <Controller
          name="partId"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormField
              label="Part ID"
              description="Part ID you are assigning an authorization to"
              errorText={error && error.message}
            >
              <Input value={value as string} onChange={(e) => onChange(e.detail.value)} placeholder="Enter a value" />
            </FormField>
          )}
        />
        <Controller
          name="licenseData.licenseCategory"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormField label="Authorization Category" errorText={error && error.message}>
              <Select
                selectedOption={value as OptionDefinition}
                options={LICENSE_CATEGORIES}
                onChange={(e) => onChange(e.detail.selectedOption)}
                placeholder="Select value"
              />
            </FormField>
          )}
        />
        <Controller
          name="licenseData.licenseType"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormField label="Authorization Type" errorText={error && error.message}>
              <Select
                selectedOption={value as OptionDefinition}
                options={
                  licenseData?.licenseCategory?.value === LicenseCategory.EXPORT
                    ? LICENSE_TYPES.filter((option) => option.value !== 'LICENSE_NUMBER')
                    : LICENSE_TYPES.filter((option) => option.value !== 'LICENSE_ID')
                }
                onChange={(e) => onChange(e.detail.selectedOption)}
                placeholder="Select value"
              />
            </FormField>
          )}
        />
        <Controller
          name="licenseData.licenseValue"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormField label="Authorization Value" errorText={error && error.message}>
              <Input value={value as string} onChange={(e) => onChange(e.detail.value)} placeholder="Enter a value" />
            </FormField>
          )}
        />
      </SpaceBetween>
    </Container>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Form
        actions={
          <SpaceBetween size="xs" direction="horizontal">
            <Button
              disabled={isLoading}
              loading={isLoading}
              formAction="submit"
              variant="primary"
              data-testid="submit-form-btn"
            >
              Assign Authorization
            </Button>
          </SpaceBetween>
        }
      >
        {formContent}
      </Form>
    </form>
  );
};

export default AssignLicense;
