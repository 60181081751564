import { PaginationProps } from '@amzn/awsui-components-react';
import { useEffect, useState } from 'react';
import { useLazyListIncotermRelationsQuery } from 'api/baseApi';
import { ListIncotermRelationsInputFilters, Maybe, RelationType } from '@amzn/cip-bff-schema';
import { IncotermRelationViewModel } from 'types/incoterms';
import { PropertyFilterProps } from '@amzn/awsui-components-react/polaris/property-filter/interfaces';
import { PROPERTY_FILTERING_I18N_CONSTANTS } from 'utils/tableUtils';
import { FREIGHT_FILTERING_PROPERTIES, IOR_FILTERING_PROPERTIES } from 'features/IncotermRelations/incotermTableConfig';
import { LABEL_TO_VALUE_KEY_OPTIONS_MAP, VALUE_KEY_OPTIONS_MAP } from 'config/incotermConstants';
import { PropertyFilterOption } from '@amzn/awsui-collection-hooks';

export type ServerFiltrationProps = {
  pageSize: number;
  filters?: ListIncotermRelationsInputFilters[] | undefined;
  relationType: RelationType;
};

type ServerSideFiltrationResult = {
  resetPageCount: () => void;
  paginationProps: PaginationProps;
  propertyFilteringProps: PropertyFilterProps;
  relations: IncotermRelationViewModel[];
  isFetching: boolean;
  countText?: string;
  upperLimitReached?: boolean;
};

const DEFAULT_FILTERING_QUERY: PropertyFilterProps.Query = { tokens: [], operation: 'and' };
/** number of items to query from the backend at a time */
const LIMIT = 500;
/** upper limit of table items the UI can take before the experience degrades exponentially */
const UI_LIMIT = 6000;

const useIncotermsServerFiltration = ({
  pageSize,
  relationType,
}: ServerFiltrationProps): ServerSideFiltrationResult => {
  /** total relations queried for (rolling quantity by LIMIT) */
  const [relations, setRelations] = useState<IncotermRelationViewModel[]>([]);
  const dataSize = relations?.length;

  /** filtering options for currently selected property filter key i.e. destinationCountry */
  const [filteringPropertyOptions, setFilteringPropertyOptions] = useState<PropertyFilterOption[]>([]);
  /** incoterm service filters */
  const [filters, setFilters] = useState<ListIncotermRelationsInputFilters[]>();
  /** query tokens displayed to the user and set by the PropertyFilter component */
  const [query, setQuery] = useState<PropertyFilterProps.Query>(DEFAULT_FILTERING_QUERY);

  // use a lazy query so we can trigger when query is fired
  const [trigger, result] = useLazyListIncotermRelationsQuery();
  const { isFetching, isError, currentData } = result;

  const getRelations = async (
    filters?: ListIncotermRelationsInputFilters[],
    nextToken?: Maybe<string>
  ): Promise<void> => {
    const res = await trigger({
      relationType: relationType,
      filters,
      limit: LIMIT,
      nextToken,
    }).unwrap();

    setRelations((prev) =>
      res.incotermRelations && nextToken ? [...prev, ...res.incotermRelations] : res?.incotermRelations ?? []
    );
    // if no results reset page count to 1 so it will get recalculated
    if (!res.incotermRelations?.length) resetPageCount();
  };

  const [pagesCount, setPagesCount] = useState(1);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const hasMoreResults = !isFetching && Boolean(currentData?.nextToken) && !isError;

  const resetPageCount = (): void => {
    setPagesCount(1);
    setCurrentPageIndex(1);
  };

  useEffect(() => {
    // clear property filters and previous state
    setQuery(DEFAULT_FILTERING_QUERY);
    resetPageCount();
    setRelations([]);

    // get relations for new relation type
    getRelations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relationType]);

  useEffect(() => {
    // calculate page count based off of the amount of data returned since the service doesn't return total item count
    if (pageSize && relations.length && !isFetching) {
      const initialCount = Math.ceil((relations.length ?? 1) / (pageSize ?? 1)) ?? 1;
      setPagesCount(initialCount);
    }
  }, [pageSize, relations, isFetching]);

  const upperLimitReached = dataSize >= UI_LIMIT;

  // properties spread to the Pagination component
  const paginationProps: PaginationProps = {
    pagesCount,
    currentPageIndex,
    onChange: ({ detail }) => {
      setCurrentPageIndex(detail.currentPageIndex);
      if (detail.currentPageIndex === pagesCount + 1 && currentData?.nextToken) {
        getRelations(filters, currentData?.nextToken);
      }
    },
    disabled: isFetching,
    openEnd: hasMoreResults && !upperLimitReached,
  };

  // properties spread to the PropertyFilter component
  const propertyFilteringProps: PropertyFilterProps = {
    i18nStrings: PROPERTY_FILTERING_I18N_CONSTANTS,
    filteringProperties: relationType === RelationType.IOR ? IOR_FILTERING_PROPERTIES : FREIGHT_FILTERING_PROPERTIES,
    filteringOptions: filteringPropertyOptions,
    query: query,
    onChange: (event) => {
      const { detail } = event;

      const filters: ListIncotermRelationsInputFilters[] = detail.tokens.map(({ propertyKey, value }) => {
        // there is mapping to do from label to value
        if (propertyKey && propertyKey in LABEL_TO_VALUE_KEY_OPTIONS_MAP) {
          // retrieve correct key and options from map
          const map = LABEL_TO_VALUE_KEY_OPTIONS_MAP?.[propertyKey as keyof typeof LABEL_TO_VALUE_KEY_OPTIONS_MAP];

          // find correct value and return
          const trueValue = map?.options?.find((op) => op?.label === value)?.value;
          return { propertyName: map?.key ?? '', value: trueValue ? trueValue : null };
        }
        // if no mapping use original key and value
        return { propertyName: propertyKey ?? '', value };
      });
      setQuery(detail);
      setFilters(filters);
      getRelations(filters);
    },
    onLoadItems: ({ detail: { firstPage, filteringProperty } }) => {
      if (firstPage) {
        setFilteringPropertyOptions([]);
      }
      if (filteringProperty?.key) {
        const isValueProperty = filteringProperty?.key in VALUE_KEY_OPTIONS_MAP;
        const properties = isValueProperty
          ? VALUE_KEY_OPTIONS_MAP[filteringProperty?.key as keyof typeof VALUE_KEY_OPTIONS_MAP]
          : LABEL_TO_VALUE_KEY_OPTIONS_MAP?.[filteringProperty?.key as keyof typeof LABEL_TO_VALUE_KEY_OPTIONS_MAP];

        const transformedFilterOptions: PropertyFilterOption[] = properties?.options?.map(({ value, label }) => ({
          value: isValueProperty ? value : label,
          propertyKey: filteringProperty.key,
        }));
        setFilteringPropertyOptions(transformedFilterOptions);
      }
    },
  };

  return {
    resetPageCount,
    paginationProps,
    propertyFilteringProps,
    relations: relations.slice((currentPageIndex - 1) * pageSize, currentPageIndex * pageSize),
    countText: !isFetching ? `${relations?.length}${hasMoreResults ? '+' : ''}` : '...',
    isFetching,
    upperLimitReached,
  };
};

export default useIncotermsServerFiltration;
