import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FlashbarItemsProps } from 'types';

export type DraftCILocalState = {
  draftCIFlashbarItems: FlashbarItemsProps[];
};

const initialState: DraftCILocalState = {
  draftCIFlashbarItems: [],
};

/** CI Review Slice */
const { reducer, actions } = createSlice({
  name: 'draftCI',
  initialState,
  reducers: {
    setDraftCIFlashbarItems: (state, { payload }: PayloadAction<FlashbarItemsProps[]>) => {
      state.draftCIFlashbarItems = payload;
    },
  },
});

export default reducer;

export const { setDraftCIFlashbarItems } = actions;
