import { useUpdateCIReviewMutation } from 'api/baseApi';
import SubmitModal from 'components/SubmitModal';
import { useState } from 'react';
import { getOptionByValue } from 'utils/formUtils';
import { DRAFT_CI_STATUS } from '@amzn/gtpccipstatic-config/dist/constants';
import { setDraftCIFlashbarItems } from 'store/draftCI';
import { v4 as uuid } from 'uuid';
import {
  CommercialInvoiceReviewStatusFromUser,
  TransactionErrorType,
  TransactionType,
  UpdateCommercialInvoiceReviewInput,
} from '@amzn/cip-bff-schema';
import { hasGQLError, hasResponseWithoutErrors } from 'utils/typeUtils';
import { FlashbarItemsProps } from 'types';
import { useDispatch } from 'react-redux';

const DRAFT_CI_ERR_MSG_MAP = {
  [TransactionErrorType.TRANSACTION_NOT_FOUND]:
    'There was an error while submitting commercial invoice review due to invalid input. The provided combination of transaction ID and transaction type was not found.',
  [TransactionErrorType.UNSUPPORTED_OPERATION]:
    'There was an error while submitting commercial invoice review. This operation is not currently supported.',
  default: 'There was an unknown error while submitting commercial invoice review.',
};

const useSubmitDraftCIModal = (): [
  JSX.Element | null,
  (value: React.SetStateAction<boolean>) => void,
  (value: React.SetStateAction<UpdateCommercialInvoiceReviewInput>) => void,
  boolean
] => {
  const dispatch = useDispatch();
  const [submitModalOpen, setSubmitModalOpen] = useState(false);
  const [input, setSubmitModalInput] = useState({
    transactionId: '',
    // Unsafe casts, but these should always be set to valid values by the caller before the modal is opened
    transactionType: '' as TransactionType,
    status: '' as CommercialInvoiceReviewStatusFromUser,
  });
  const [updateCIReview, { isLoading }] = useUpdateCIReviewMutation();

  const onSubmit = async (): Promise<void> => {
    const res = await updateCIReview(input);
    setSubmitModalOpen(false);
    if (hasResponseWithoutErrors(res)) {
      dispatch(
        setDraftCIFlashbarItems([
          {
            header: 'Commercial Invoice Review Completed Successfully',
            type: 'success',
            content: `The commercial invoice review for transaction ID ${input.transactionId} has been updated.`,
            dismissId: uuid(),
          },
        ])
      );
    } else if (hasGQLError(res)) {
      const errProps: Partial<FlashbarItemsProps> = {
        header: 'Error',
        type: 'error',
        dismissId: uuid(),
        content: DRAFT_CI_ERR_MSG_MAP.default,
      };
      const flashbarItems: FlashbarItemsProps[] = [];
      const errors = res?.error?.errors;
      if (errors?.length > 0) {
        errors.forEach((err) => {
          if (Object.keys(DRAFT_CI_ERR_MSG_MAP).includes(err.errorType)) {
            flashbarItems.push({
              ...errProps,
              // safe cast due to the above check that the array includes the errorType
              content: DRAFT_CI_ERR_MSG_MAP[err.errorType as keyof typeof DRAFT_CI_ERR_MSG_MAP],
            });
          }
        });
        if (!flashbarItems.length) flashbarItems.push(errProps);
      }
      dispatch(setDraftCIFlashbarItems(flashbarItems));
    }
  };

  const submitModal = (
    <SubmitModal
      visible={submitModalOpen}
      setVisible={setSubmitModalOpen}
      submitText="Confirm"
      onSubmit={onSubmit}
      isLoading={isLoading}
      header={`Submit review for commercial invoice?`}
    >
      {`Are you sure you want to mark this commercial invoice as "${
        getOptionByValue(input.status, DRAFT_CI_STATUS)?.label
      }"?${
        input.status === CommercialInvoiceReviewStatusFromUser.CI_REVIEW_REJECTED
          ? ' Please ensure all values have been corrected before proceeding, otherwise the corrections will not be included in the next invoice version.'
          : ''
      }`}
    </SubmitModal>
  );

  return [submitModal, setSubmitModalOpen, setSubmitModalInput, isLoading];
};

export default useSubmitDraftCIModal;
