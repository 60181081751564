import { IncotermRelation, RelationType } from '@amzn/cip-bff-schema';
import { TransactionType } from '@amzn/gtpccipstatic-config/dist/types';
import { useCallback, useMemo } from 'react';
import { NavigateOptions, useSearchParams } from 'react-router-dom';

/**
 * This custom hook is a wrapper around `useSearchParams()` that parses and
 * serializes the search param value
 * @param {string} key key of search param
 * @return {[searchParams, setSearchParams]} array of query param and function to set query param
 */
function useQueryParams<T>(key: string): [T, (newQuery: T | undefined, options?: NavigateOptions) => void] {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramValue = searchParams.get(key);

  // searchParams.get is always going to return a string, cast to unknown so we can cast back to T
  const value = useMemo(() => paramValue, [paramValue]) as unknown;

  const setValue = useCallback(
    (newValue: T | undefined, options?: NavigateOptions) => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set(key, String(newValue));
      setSearchParams(newSearchParams, options);
    },
    [key, searchParams, setSearchParams]
  );

  return [value as T, setValue];
}

export const buildIncoRSQueryParams = (relation?: IncotermRelation): string => {
  if (relation) {
    const {
      destinationCountry,
      identifierType,
      supplier,
      orderingEntity,
      productCategory,
      programType,
      relationType,
    } = relation;
    let params = `idType=${identifierType}`;
    if (destinationCountry) params += `&dc=${destinationCountry}`;
    if (supplier) params += `&supplier=${supplier}`;
    if (orderingEntity) params += `&oe=${orderingEntity}`;
    if (productCategory) params += `&pc=${productCategory}`;
    if (programType && relationType === RelationType.IOR) params += `&pt=${programType}`;
    return params;
  }
  return '';
};

export const getTRSBaseQueryParams = (queryParams: URLSearchParams): string => {
  const fromCountry = queryParams.get('fc') as string;
  const toCountry = queryParams.get('tc') as string;
  const transactionType = queryParams.get('tt') as TransactionType;

  return `tt=${transactionType}&fc=${fromCountry}&tc=${toCountry}`;
};

export default useQueryParams;
