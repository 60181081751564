import { useNavigate } from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';
import { useUpdateIncotermRelationMutation } from 'api/baseApi';
import { RelationType } from '@amzn/cip-bff-schema';
import { v4 as uuid } from 'uuid';
import IncotermCommonForm, { IncotermRelationFormInputs, incoFormSchemaToEditSchema } from 'features/IncotermCommon';
import { IncotermRelationDisplayConfig } from 'types/constants';
import { setIncoEditFlashbarItems, setIncoLandingFlashbarItems } from 'store/incoterms';
import { useAppDispatch } from 'store/store';
import { ColumnLayout, Container, Header, SpaceBetween, Spinner } from '@amzn/awsui-components-react';
import ValueWithLabel from 'components/ValueWithLabel';
import { getOptionByValue, isOneNullish } from 'utils/formUtils';
import { PRODUCT_CATEGORIES, PROGRAM_TYPES, SUPPLIER_DISMISSED } from 'config/incotermConstants';
import { COUNTRIES } from '@amzn/gtpccipstatic-config/dist/constants';
import useValidateIncoRelationParams from 'hooks/useValidateIncoRelationParams';
import { DATE_TIME_PATTERN } from 'utils/dateTimeUtils';
import { format } from 'date-fns';

interface IncotermRelationEditProps {
  relationType: RelationType;
  relationDisplay: IncotermRelationDisplayConfig;
}

const IncotermRelationEditPage: React.FC<IncotermRelationEditProps> = ({ relationType, relationDisplay }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { shortName: relationshipShortName } = relationDisplay;
  const routePath = relationshipShortName.toLowerCase();

  const { relation, isLoading: isIncotermLoading } = useValidateIncoRelationParams(relationType);
  const [updateIncotermRelation, { isLoading: isUpdateLoading }] = useUpdateIncotermRelationMutation();

  const hasSupplier = relation?.supplier && relation?.supplier !== SUPPLIER_DISMISSED;

  const onSubmit: SubmitHandler<IncotermRelationFormInputs> = async (data) => {
    if (relation) {
      const editedRelation = incoFormSchemaToEditSchema(data, relation);

      const res = await updateIncotermRelation(editedRelation);

      if (res && 'data' in res) {
        dispatch(
          setIncoLandingFlashbarItems([
            {
              header: 'Decision Updated Successfully',
              type: 'success',
              content: `The ${relationshipShortName} decision has been updated.`,
              dismissId: uuid(),
            },
          ])
        );
        navigate(`/incoterms/${routePath}`);
      } else {
        dispatch(
          setIncoEditFlashbarItems([
            {
              header: 'Error',
              type: 'error',
              content: `There was an error updating the ${relationshipShortName} relation.`,
              dismissId: uuid(),
            },
          ])
        );
      }
    }
  };

  const incotermRelInfoHeader = <Header>{relationshipShortName} Decision Info</Header>;
  const incotermRelInfo = (
    <Container header={incotermRelInfoHeader}>
      <ColumnLayout columns={2} variant="text-grid">
        <SpaceBetween size="l">
          <ValueWithLabel label="Destination Country">
            {getOptionByValue(relation?.destinationCountry, COUNTRIES)?.label ?? '-'}
          </ValueWithLabel>
          {relation?.productCategory && (
            <ValueWithLabel label="Product Category">
              {getOptionByValue(relation?.productCategory, PRODUCT_CATEGORIES)?.label ?? '-'}
            </ValueWithLabel>
          )}
          {relation?.programType && (
            <ValueWithLabel label="Program Type">
              {getOptionByValue(relation?.programType, PROGRAM_TYPES)?.label ?? '-'}
            </ValueWithLabel>
          )}
          {relationType === RelationType.IOR && (
            <>
              <ValueWithLabel label="Supplier Import Capability">
                {isOneNullish(relation?.supplierHasImportCapability)
                  ? '-'
                  : relation?.supplierHasImportCapability
                  ? 'Yes'
                  : 'No'}
              </ValueWithLabel>
              <ValueWithLabel label="AWS Import Capability">
                {isOneNullish(relation?.awsHasImportCapability) ? '-' : relation?.awsHasImportCapability ? 'Yes' : 'No'}
              </ValueWithLabel>
            </>
          )}

          <ValueWithLabel label="Modified At">
            {relation?.modifiedAt ? format(relation?.modifiedAt, DATE_TIME_PATTERN) : '-'}
          </ValueWithLabel>
          <ValueWithLabel label="Modified By">{relation?.modifiedBy ?? '-'}</ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="l">
          {relation?.orderingEntity && (
            <ValueWithLabel label="Ordering Entity">
              <div>
                <b>Name:</b> {relation?.orderingEntityName ?? '-'}
              </div>
              <div>
                <b>ID:</b> {relation?.orderingEntity ?? '-'}
              </div>
            </ValueWithLabel>
          )}
          <ValueWithLabel label="Supplier/Vendor">
            <div>
              <b>Name:</b> {hasSupplier ? relation?.supplierName ?? relation?.supplier : '-'}
            </div>
            <div>
              <b>ID:</b> {hasSupplier ? relation?.supplier : '-'}
            </div>
          </ValueWithLabel>
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  );

  const incotermEdit = (
    <IncotermCommonForm
      isLoading={isUpdateLoading || isIncotermLoading}
      relationType={relationType}
      onSubmit={onSubmit}
      formHeaderText={`${relationshipShortName} Decision`}
      submitText="Save Changes"
      cancelRoute={`/incoterms/${routePath}`}
      incotermRelation={relation}
      isEdit
    />
  );

  return !isIncotermLoading ? (
    <SpaceBetween size="l">
      {incotermRelInfo}
      {incotermEdit}
    </SpaceBetween>
  ) : (
    <Spinner />
  );
};

export default IncotermRelationEditPage;
