import SubmitModal from 'components/SubmitModal';
import { IncotermRelation } from '@amzn/cip-bff-schema';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { getOptionByValue } from 'utils/formUtils';
import { DECISION_VALUES_ALL } from 'config/incotermConstants';
import { useDeleteIncotermRelationMutation } from 'api/baseApi';
import { setIncoLandingFlashbarItems } from 'store/incoterms';

const useDeleteIncotermModal = (
  relation?: IncotermRelation | undefined
): [JSX.Element | null, (value: React.SetStateAction<boolean>) => void, boolean] => {
  const dispatch = useDispatch();
  const { relationValue, relationType } = relation ?? {};
  const valueLabel = getOptionByValue(relationValue, DECISION_VALUES_ALL)?.label;

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteRelation, { isLoading: isDeleteLoading, isError, isSuccess }] = useDeleteIncotermRelationMutation();

  useEffect(() => {
    if (isError) {
      dispatch(
        setIncoLandingFlashbarItems([
          {
            dismissId: uuid(),
            header: 'Error',
            type: 'error',
            content: `There was an error deleting the specified incoterm ${relationType} decision. Please wait a moment and try again.`,
          },
        ])
      );
    }
    if (isSuccess) {
      dispatch(
        setIncoLandingFlashbarItems([
          {
            dismissId: uuid(),
            header: 'Success',
            type: 'success',
            content: `The specified incoterm relation was deleted successfully.`,
          },
        ])
      );
    }
  }, [isError, dispatch, isSuccess, relationType]);

  const onDelete = async (): Promise<void> => {
    if (relation) {
      const {
        identifierType,
        relationType,
        version,
        supplier,
        destinationCountry,
        orderingEntity,
        programType,
        productCategory,
      } = relation;
      await deleteRelation({
        identifierType,
        relationType,
        version,
        input: {
          supplier,
          destinationCountry,
          orderingEntity: orderingEntity ?? undefined,
          programType: programType ?? undefined,
          productCategory: productCategory ?? undefined,
        },
      });
    }
    setDeleteModalOpen(false);
  };

  const deleteModal = relation ? (
    <SubmitModal
      visible={deleteModalOpen}
      setVisible={setDeleteModalOpen}
      submitText="Delete"
      onSubmit={onDelete}
      isLoading={isDeleteLoading}
      header={`Delete this ${relationType} decision?`}
    >
      {`Are you sure you want to delete this ${relationType} incoterm decision with value ${valueLabel}?`}
    </SubmitModal>
  ) : null;

  return [deleteModal, setDeleteModalOpen, isDeleteLoading];
};

export default useDeleteIncotermModal;
