import { AppLayout, BreadcrumbGroup, Flashbar, Header } from '@amzn/awsui-components-react';
import { CIPSideNavigation } from 'components/SideNavigation/SideNavigation';
import { APP_LAYOUT_LABELS, HEADER_SELECTOR } from 'config/navConfig';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { useAppSelector } from 'store/store';
import { DEFAULT_REQUIRED_MSG, flashbarItemsCreator, yupOptionDefinitionSchema } from 'utils/formUtils';
import DraftCI from './DraftCI';
import { setDraftCIFlashbarItems } from 'store/draftCI';

export const draftCISchema = yup.object({
  transactionId: yup.string().required(DEFAULT_REQUIRED_MSG),
  transactionType: yupOptionDefinitionSchema.required(DEFAULT_REQUIRED_MSG),
  status: yupOptionDefinitionSchema.required(DEFAULT_REQUIRED_MSG),
});

export type DraftCIFormInputs = yup.InferType<typeof draftCISchema>;

const DraftCIPage: React.FC = () => {
  const dispatch = useDispatch();
  const { draftCIFlashbarItems } = useAppSelector((state) => state.draftCI);

  return (
    <AppLayout
      maxContentWidth={800}
      headerSelector={HEADER_SELECTOR}
      toolsHide
      contentHeader={
        <Header variant="h1" description="Approve or reject a commercial invoice">
          Commercial Invoice Review
        </Header>
      }
      stickyNotifications
      notifications={<Flashbar items={flashbarItemsCreator(draftCIFlashbarItems, dispatch, setDraftCIFlashbarItems)} />}
      navigation={<CIPSideNavigation />}
      breadcrumbs={
        <BreadcrumbGroup
          items={[
            { text: 'Compliance Integration', href: '/' },
            { text: 'Commercial Invoice Review', href: '#' },
          ]}
        />
      }
      content={<DraftCI />}
      ariaLabels={APP_LAYOUT_LABELS}
    />
  );
};

export default DraftCIPage;
