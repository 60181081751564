import React from 'react';
import { SideNavigation, SideNavigationProps } from '@amzn/awsui-components-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SideNavLink, SideNavProps, SideNavSection, getSideNavItems } from 'config/navConfig';
import { useAppSelector } from 'store/store';

const Navigation: React.FC<SideNavigationProps> = ({ items }) => {
  const { pathname } = useLocation();
  const [activeHref, setActiveHref] = React.useState(pathname);
  const navigate = useNavigate();
  return (
    <SideNavigation
      activeHref={activeHref}
      header={{ href: '/', text: 'Compliance Integration' }}
      onFollow={(event) => {
        if (!event.detail.external) {
          event.preventDefault();
          setActiveHref(event.detail.href);
          navigate(event.detail.href);
        }
      }}
      items={items}
    />
  );
};

const isSideNavLink = (item: SideNavProps): item is SideNavLink => 'groupAllowList' in item && item.type === 'link';
const isSideNavSection = (item: SideNavProps): item is SideNavSection => 'items' in item && item.type === 'section';

const recursiveSideNavItemCheck = (
  items: ReadonlyArray<SideNavProps>,
  groupMembership: string[],
  stage?: string
): ReadonlyArray<SideNavProps> => {
  const result: SideNavProps[] = [];
  items.forEach((item) => {
    if (isSideNavLink(item)) {
      const { groupAllowList } = item;
      if (!groupAllowList || groupMembership?.some((r) => groupAllowList.indexOf(r) >= 0)) result.push(item);
    } else if (isSideNavSection(item)) {
      const { groupAllowList, items: sectionItems, restrictedStages } = item;
      const isStageRestricted = restrictedStages?.includes(stage ?? '');
      // don't add section if it is restricted by stage or by group membership
      if (!isStageRestricted && (!groupAllowList || groupMembership?.some((r) => groupAllowList.indexOf(r) >= 0))) {
        const subItems = recursiveSideNavItemCheck(sectionItems, groupMembership);
        item.items = subItems;
        result.push(item);
      }
    } else {
      result.push(item);
    }
  });
  return result;
};

export const CIPSideNavigation: React.FC = () => {
  const { groupMembership } = useAppSelector((state) => state.user);
  const { stage } = useAppSelector((state) => state.config);
  const sideNavEntries = getSideNavItems();
  const items = recursiveSideNavItemCheck(sideNavEntries, groupMembership, stage);

  return <Navigation items={items} />;
};

export default Navigation;
