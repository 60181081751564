import { Helmet } from 'react-helmet-async';
import Box from '@amzn/awsui-components-react/polaris/box';
import { PageWrapperProps } from 'types';
import TopNavigation from '../TopNavigation';

import 'assets/styles/global.scss';
import '../TopNavigation/TopNavigation.scss';
import './PageWrapper.scss';

export const MOBILE_BREAKPOINT = 1100;

const PageWrapper: React.FC<PageWrapperProps> = ({ pageTitle, children, loading }) => {
  const helmet = (
    <Helmet title={pageTitle}>
      <html lang="en" />
    </Helmet>
  );

  return (
    <Box>
      {helmet}
      <TopNavigation />
      <div>{children}</div>
    </Box>
  );
};

export default PageWrapper;
