import { useUpdateTradeRouteScheduleMutation } from 'api/baseApi';
import SubmitModal from 'components/SubmitModal';
import { RouteSchedule, RouteScheduleStatus, UpdateRouteScheduleInput } from '@amzn/cip-bff-schema';
import { useEffect, useState } from 'react';
import { getOptionByValue } from 'utils/formUtils';
import { formatFromEpoch } from 'utils/dateTimeUtils';
import { COUNTRIES, TRANSACTION_TYPES } from '@amzn/gtpccipstatic-config/dist/constants';
import { useDispatch } from 'react-redux';
import { setTRSLandingFlashbarItems } from 'store/trs';
import { v4 as uuid } from 'uuid';

const useDeleteTRSScheduleModal = (
  schedule?: RouteSchedule | undefined
): [JSX.Element | null, (value: React.SetStateAction<boolean>) => void, boolean] => {
  const dispatch = useDispatch();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [
    softDeleteSchedule,
    { isLoading: isDeleteLoading, isError, isSuccess },
  ] = useUpdateTradeRouteScheduleMutation();
  const transactionType = getOptionByValue(schedule?.transactionType, TRANSACTION_TYPES);
  const fromCountry = getOptionByValue(schedule?.fromCountry, COUNTRIES);
  const toCountry = getOptionByValue(schedule?.toCountry, COUNTRIES);

  useEffect(() => {
    if (isError) {
      dispatch(
        setTRSLandingFlashbarItems([
          {
            dismissId: uuid(),
            header: 'Error',
            type: 'error',
            content: `There was an error deleting the specified trade route schedule. Please wait a moment and try again.`,
          },
        ])
      );
    }
    if (isSuccess) {
      dispatch(
        setTRSLandingFlashbarItems([
          {
            dismissId: uuid(),
            header: 'Schedule Deleted',
            type: 'success',
            content: `The specified route schedule was deleted successfully.`,
          },
        ])
      );
    }
  }, [isError, dispatch, isSuccess]);

  const onDelete = async (): Promise<void> => {
    if (schedule) {
      await softDeleteSchedule({
        ...schedule,
        scheduleStatus: RouteScheduleStatus.DELETED,
        changeNotes: 'Schedule delete',
      } as UpdateRouteScheduleInput);
    }
    setDeleteModalOpen(false);
  };

  const deleteModal = schedule ? (
    <SubmitModal
      visible={deleteModalOpen}
      setVisible={setDeleteModalOpen}
      submitText="Delete"
      onSubmit={onDelete}
      isLoading={isDeleteLoading}
      header={`Delete this route schedule ${schedule.scheduleId}?`}
    >
      {`Are you sure you want to delete this ${transactionType?.label} route schedule from ${fromCountry?.label} to ${
        toCountry?.label
      } effective ${formatFromEpoch(schedule?.effectiveDate)}  ${
        schedule?.endDate ? `through ${formatFromEpoch(schedule?.endDate)}` : ''
      }?`}
    </SubmitModal>
  ) : null;

  return [deleteModal, setDeleteModalOpen, isDeleteLoading];
};

export default useDeleteTRSScheduleModal;
