import {
  Button,
  Container,
  DatePicker,
  DatePickerProps,
  Form,
  FormField,
  Grid,
  Header,
  Input,
  Select,
  SpaceBetween,
  Textarea,
  TimeInput,
  Toggle,
} from '@amzn/awsui-components-react';
import { DATE_PICKER_ARIA_LABELS, getOptionalLabel } from 'utils/formUtils';
import {
  INCOTERMS,
  RECORD_ROLES_EOR,
  RECORD_ROLES_IOR,
  ROUTE_STATUSES,
} from '@amzn/gtpccipstatic-config/dist/constants';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import { NonCancelableCustomEvent } from '@amzn/awsui-components-react/polaris/internal/events';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { parse, subDays } from 'date-fns';
import { zodResolver } from '@hookform/resolvers/zod';
import { TradeRouteScheduleFormInputs, apiSchemaToFormSchema, trsEditFormSchema } from '.';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { RouteSchedule } from '@amzn/cip-bff-schema';
import { POLARIS_DATE_PATTERN, getEndTimeFromSetEffectiveTime } from 'utils/dateTimeUtils';
import InfoLink from 'components/InfoLink';
import { useDispatch } from 'react-redux';
import { setTRSSideNavState } from 'store/trs';

interface TRSCommonFormProps {
  onSubmit: SubmitHandler<TradeRouteScheduleFormInputs>;
  formHeaderText: string;
  submitText: string;
  cancelRoute: string;
  isLoading?: boolean;
  isEdit?: boolean;
  routeSchedule?: RouteSchedule;
  defaultValues?: Partial<TradeRouteScheduleFormInputs>;
}

const TRSCommonForm: React.FC<TRSCommonFormProps> = ({
  onSubmit,
  formHeaderText,
  submitText,
  cancelRoute,
  routeSchedule,
  isLoading = false,
  isEdit = false,
  defaultValues,
}) => {
  const { control, handleSubmit, watch } = useForm<TradeRouteScheduleFormInputs>({
    defaultValues: routeSchedule
      ? apiSchemaToFormSchema.parse(routeSchedule)
      : {
          effectiveTime: '00:00',
          changeNotes: isEdit ? undefined : 'Creating new route schedule',
          taxRateSchema: { hasTaxRate: true },
          ...defaultValues,
        },
    resolver: zodResolver(trsEditFormSchema),
    mode: 'onChange',
  });
  const dispatch = useDispatch();

  const { effectiveDate, effectiveTime, endDate, endTime, taxRateSchema } = watch();

  const handleOnChange = useCallback(
    (formOnChange: (value: unknown) => void, e: NonCancelableCustomEvent<DatePickerProps.ChangeDetail>) => {
      formOnChange(e.detail.value);
    },
    []
  );

  const scheduleFormHeader = (
    <Header
      description={
        <>
          <b>Note: </b>Dates are entered and read in local, 24 hour time.
        </>
      }
    >
      {formHeaderText}
    </Header>
  );
  const scheduleFormContainer = (
    <Container header={scheduleFormHeader}>
      <SpaceBetween size="l">
        <>
          <Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }]}>
            <Controller
              name="effectiveDate"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormField
                  label="Effective Date"
                  description="Effective date of the schedule"
                  errorText={error && error.message}
                >
                  <DatePicker
                    isDateEnabled={(date) => date > subDays(Date.now(), 1)}
                    value={value}
                    onChange={handleOnChange.bind(null, onChange)}
                    placeholder="YYYY/MM/DD"
                    {...DATE_PICKER_ARIA_LABELS}
                  />
                </FormField>
              )}
            />
            <Controller
              name="effectiveTime"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormField
                  label="Effective Time"
                  description="Effective time of the schedule"
                  errorText={error && error.message}
                >
                  <TimeInput
                    value={value}
                    placeholder="Effective Time i.e. 00:00"
                    onChange={({ detail }) => onChange(detail.value)}
                    format="hh:mm"
                  />
                </FormField>
              )}
            />
          </Grid>
          <Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }]}>
            <Controller
              name="endDate"
              control={control}
              render={({ field: { onChange, value = '' }, fieldState: { error } }) => (
                <FormField
                  label={getOptionalLabel('End Date')}
                  description="End date of the schedule"
                  errorText={error && error.message}
                >
                  <DatePicker
                    value={value}
                    isDateEnabled={(date) => date > parse(effectiveDate ?? '', POLARIS_DATE_PATTERN, new Date())}
                    onChange={handleOnChange.bind(null, onChange)}
                    placeholder="YYYY/MM/DD"
                    {...DATE_PICKER_ARIA_LABELS}
                  />
                </FormField>
              )}
            />
            <Controller
              name="endTime"
              control={control}
              render={({ field: { onChange, value = '' }, fieldState: { error } }) => {
                const endTimeValue = getEndTimeFromSetEffectiveTime(
                  value,
                  effectiveDate,
                  effectiveTime,
                  endDate,
                  endTime
                );
                return (
                  <FormField
                    label={getOptionalLabel('End Time')}
                    description="Time the schedule ends"
                    errorText={error && error.message}
                  >
                    <TimeInput
                      value={endTimeValue}
                      placeholder="End Time i.e. 00:00"
                      onChange={({ detail }) => onChange(detail.value)}
                      format="hh:mm"
                    />
                  </FormField>
                );
              }}
            />
          </Grid>
        </>
        <Controller
          name="scheduleStatus"
          control={control}
          render={({ field: { onChange, value = false }, fieldState: { error } }) => (
            <FormField
              label="Schedule Status"
              description="Do you want this schedule to be marked active?"
              errorText={error && error.message}
            >
              <Toggle checked={value} onChange={(e) => onChange(e.detail.checked)}>
                {value ? 'Active' : 'Inactive'}
              </Toggle>
            </FormField>
          )}
        />
        <Controller
          name="routeStatus"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormField
              label="Route Status"
              description="What is this route's status from a trade perspective?"
              errorText={error && error.message}
            >
              <Select
                selectedOption={value ?? null}
                options={ROUTE_STATUSES}
                onChange={(e) => onChange(e.detail.selectedOption)}
                placeholder="Select a value"
              />
            </FormField>
          )}
        />
        <Controller
          name="manualTradeActionRequired"
          control={control}
          render={({ field: { onChange, value = false }, fieldState: { error } }) => (
            <FormField
              label="Manual Action Required"
              description="Does this route require manual action to be taken from the trade team?"
              errorText={error && error.message}
            >
              <Toggle checked={value} onChange={(e) => onChange(e.detail.checked)}>
                {value ? 'Yes' : 'No'}
              </Toggle>
            </FormField>
          )}
        />
        <Controller
          name="commercialInvoiceRequired"
          control={control}
          render={({ field: { onChange, value = false }, fieldState: { error } }) => (
            <FormField
              label="Commercial Invoice Required"
              description="Does this trade route require a commercial invoice?"
              errorText={error && error.message}
            >
              <Toggle checked={value} onChange={(e) => onChange(e.detail.checked)}>
                {value ? 'Yes' : 'No'}
              </Toggle>
            </FormField>
          )}
        />
        <Controller
          name="ciStatement"
          control={control}
          render={({ field: { onChange, value = '' }, fieldState: { error } }) => (
            <FormField
              label={getOptionalLabel('Commercial Invoice Statement')}
              description="CI statement that will appear on the generated CI"
              errorText={error && error.message}
            >
              <Textarea value={value} onChange={(e) => onChange(e.detail.value)} placeholder="Enter a value" />
            </FormField>
          )}
        />
        <Controller
          name="taxRateSchema.vatInvoiceRequired"
          control={control}
          render={({ field: { onChange, value = false }, fieldState: { error } }) => (
            <FormField
              label="Tax Invoice Required"
              description="Does this trade route require a tax invoice?"
              errorText={error && error.message}
            >
              <Toggle checked={value} onChange={(e) => onChange(e.detail.checked)}>
                {value ? 'Yes' : 'No'}
              </Toggle>
            </FormField>
          )}
        />
        {taxRateSchema?.vatInvoiceRequired && (
          <>
            <Controller
              name="taxRateSchema.hasTaxRate"
              control={control}
              render={({ field: { onChange, value = false }, fieldState: { error } }) => (
                <FormField
                  label="Does this route have an approved tax rate?"
                  description="Select 'No' if tax rate is not approved"
                  errorText={error && error.message}
                >
                  <Toggle checked={value} onChange={(e) => onChange(e.detail.checked)}>
                    {value ? 'Yes' : 'No'}
                  </Toggle>
                </FormField>
              )}
            />
            {taxRateSchema?.hasTaxRate && (
              <>
                <Controller
                  name="taxRateSchema.isTaxRateLEBased"
                  control={control}
                  render={({ field: { onChange, value = false }, fieldState: { error } }) => (
                    <FormField
                      label="Legal Entity Based Tax Rate"
                      description="Is tax rate determined by legal entities in the transaction?"
                      errorText={error && error.message}
                    >
                      <Toggle checked={value} onChange={(e) => onChange(e.detail.checked)}>
                        {value ? 'Yes' : 'No'}
                      </Toggle>
                    </FormField>
                  )}
                />
                {taxRateSchema?.isTaxRateLEBased ? (
                  <>
                    <Controller
                      name="taxRateSchema.taxRateSameLE"
                      control={control}
                      render={({ field: { onChange, value = '' }, fieldState: { error } }) => (
                        <FormField
                          label={getOptionalLabel('Tax Rate: Same Legal Entities')}
                          description="Tax rate used if legal entities are the same. LEAVE BLANK FOR NO TAX INVOICE REQUIRED."
                          errorText={error && error.message}
                        >
                          <Input
                            type="number"
                            inputMode="numeric"
                            value={String(value)}
                            onChange={(e) => {
                              const parsed = Number.parseFloat(e.detail.value);
                              onChange(isNaN(parsed) ? null : Number.parseFloat(parsed.toFixed(2)));
                            }}
                          />
                        </FormField>
                      )}
                    />
                    <Controller
                      name="taxRateSchema.taxRateDiffLE"
                      control={control}
                      render={({ field: { onChange, value = '' }, fieldState: { error } }) => (
                        <FormField
                          label="Tax Rate: Different Legal Entities"
                          description="Tax rate used if legal entities are different"
                          errorText={error && error.message}
                        >
                          <Input
                            type="number"
                            inputMode="numeric"
                            value={String(value)}
                            onChange={(e) => {
                              const parsed = Number.parseFloat(e.detail.value);
                              onChange(isNaN(parsed) ? null : Number.parseFloat(parsed.toFixed(2)));
                            }}
                          />
                        </FormField>
                      )}
                    />
                  </>
                ) : (
                  <Controller
                    name="taxRateSchema.taxRate"
                    control={control}
                    render={({ field: { onChange, value = '' }, fieldState: { error } }) => (
                      <FormField
                        label="Tax Rate"
                        description="Generic tax rate used regardless of legal entities"
                        errorText={error && error.message}
                      >
                        <Input
                          type="number"
                          inputMode="numeric"
                          value={String(value)}
                          onChange={(e) => {
                            const parsed = Number.parseFloat(e.detail.value);
                            onChange(isNaN(parsed) ? null : Number.parseFloat(parsed.toFixed(2)));
                          }}
                        />
                      </FormField>
                    )}
                  />
                )}
              </>
            )}
            <Controller
              name="taxRateSchema.taxComments"
              control={control}
              render={({ field: { onChange, value = '' }, fieldState: { error } }) => (
                <FormField
                  label={getOptionalLabel('Tax Comments')}
                  description="Comments that will be sent to SC.os during document generation"
                  errorText={error && error.message}
                >
                  <Textarea
                    value={value ?? ''}
                    onChange={(e) => onChange(e.detail.value)}
                    placeholder="Enter a value"
                  />
                </FormField>
              )}
            />
          </>
        )}
        <Controller
          name="vatStatement"
          control={control}
          render={({ field: { onChange, value = '' }, fieldState: { error } }) => (
            <FormField
              label={getOptionalLabel('Tax Invoice Statement')}
              description="Statement that will appear on the generated tax invoice"
              errorText={error && error.message}
            >
              <Textarea value={value} onChange={(e) => onChange(e.detail.value)} placeholder="Enter a value" />
            </FormField>
          )}
        />

        <Controller
          name="packingListRequired"
          control={control}
          render={({ field: { onChange, value = false }, fieldState: { error } }) => (
            <FormField
              label="Packing List Required"
              description="Does this trade route require a packing list?"
              errorText={error && error.message}
            >
              <Toggle checked={value} onChange={(e) => onChange(e.detail.checked)}>
                {value ? 'Yes' : 'No'}
              </Toggle>
            </FormField>
          )}
        />
        <Controller
          name="usedEquipmentAllowed"
          control={control}
          render={({ field: { onChange, value = false }, fieldState: { error } }) => (
            <FormField
              label="Used Equipment Allowed"
              description="Does this trade route allow used equipment?"
              errorText={error && error.message}
            >
              <Toggle checked={value} onChange={(e) => onChange(e.detail.checked)}>
                {value ? 'Yes' : 'No'}
              </Toggle>
            </FormField>
          )}
        />
        <Controller
          name="ueeeNeeded"
          control={control}
          render={({ field: { onChange, value = false }, fieldState: { error } }) => (
            <FormField
              label="UEEE Declaration Required"
              info={
                <InfoLink
                  id="ueee-info-link"
                  onFollow={() => {
                    dispatch(setTRSSideNavState({ toolsContentId: 'ueee-info-link', toolsOpen: true }));
                  }}
                />
              }
              description="Does this trade route require a UEEE declaration?"
              errorText={error && error.message}
            >
              <Toggle checked={value} onChange={(e) => onChange(e.detail.checked)}>
                {value ? 'Yes' : 'No'}
              </Toggle>
            </FormField>
          )}
        />
        <Controller
          name="cooRequired"
          control={control}
          render={({ field: { onChange, value = false }, fieldState: { error } }) => (
            <FormField
              label="COO Required"
              description="Does this trade route require a Certificate of Origin?"
              errorText={error && error.message}
            >
              <Toggle checked={value} onChange={(e) => onChange(e.detail.checked)}>
                {value ? 'Yes' : 'No'}
              </Toggle>
            </FormField>
          )}
        />

        <Controller
          name="ior"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormField label={getOptionalLabel('Importer of Record')} errorText={error && error.message}>
              <Select
                selectedOption={value ?? null}
                options={RECORD_ROLES_IOR}
                onChange={(e) => onChange(e.detail.selectedOption)}
                placeholder="Select a value"
              />
            </FormField>
          )}
        />
        <Controller
          name="eor"
          control={control}
          render={({ field: { onChange, value = null }, fieldState: { error } }) => (
            <FormField label={getOptionalLabel('Exporter of Record')} errorText={error && error.message}>
              <Select
                selectedOption={value}
                options={RECORD_ROLES_EOR}
                onChange={(e) => onChange(e.detail.selectedOption)}
                placeholder="Select a value"
              />
            </FormField>
          )}
        />
        <Controller
          name="incoterms"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormField label="Incoterms Rule" errorText={error && error.message}>
              <Select
                selectedOption={value as OptionDefinition}
                options={INCOTERMS}
                onChange={(e) => onChange(e.detail.selectedOption)}
                placeholder="Select value"
              />
            </FormField>
          )}
        />
        <Controller
          name="changeNotes"
          control={control}
          render={({ field: { onChange, value = '' }, fieldState: { error } }) => (
            <FormField
              label={`${isEdit ? 'Change ' : ''}Notes`}
              description="Internal notes for auditing purposes"
              errorText={error && error.message}
            >
              <Textarea value={value} onChange={(e) => onChange(e.detail.value)} placeholder="Change notes" />
            </FormField>
          )}
        />
      </SpaceBetween>
    </Container>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Form
        actions={
          <SpaceBetween size="xs" direction="horizontal">
            <Link to={cancelRoute}>
              <Button disabled={isLoading}>Cancel</Button>
            </Link>
            <Button
              disabled={isLoading}
              loading={isLoading}
              formAction="submit"
              variant="primary"
              data-testid="submit-form-btn"
            >
              {submitText}
            </Button>
          </SpaceBetween>
        }
      >
        {scheduleFormContainer}
      </Form>
    </form>
  );
};

export default TRSCommonForm;
