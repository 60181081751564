import { Box, ColumnLayout, Container } from '@amzn/awsui-components-react';
import errorImg from 'assets/icons/error_page_img.png';

const NotAuthorized: React.FC = () => {
  return (
    <Container>
      <ColumnLayout columns={3}>
        <Box variant="div" />
        <Box variant="div" textAlign="left">
          <img alt="Error" src={errorImg} height={150} />
          <Box variant="h1">Unauthorized</Box>
          <Box variant="p">You are not authorized to view this page</Box>
        </Box>
        <Box variant="div" />
      </ColumnLayout>
    </Container>
  );
};

export default NotAuthorized;
