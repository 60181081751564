import * as yup from 'yup';
import {
  CreateIncotermRelationInput,
  IdentifierType,
  IncotermRelation,
  RelationType,
  UpdateIncotermRelationInput,
} from '@amzn/cip-bff-schema';
import {
  DEFAULT_REQUIRED_MSG,
  getOptionByValue,
  isOneNullish,
  mapObj,
  yupOptionDefinitionSchema,
} from 'utils/formUtils';
import { DECISION_VALUES_ALL, PROGRAM_TYPES, YES_NO_OPTIONAL } from 'config/incotermConstants';

export const incotermFormSchema = yup.object().shape({
  relationValue: yupOptionDefinitionSchema.required(DEFAULT_REQUIRED_MSG),
  destinationCountry: yupOptionDefinitionSchema.required(DEFAULT_REQUIRED_MSG),
  supplier: yupOptionDefinitionSchema.required(DEFAULT_REQUIRED_MSG),
  /* required when FREIGHT */
  productCategory: yupOptionDefinitionSchema.when('$relationType', ([relationType], schema) =>
    relationType === RelationType.FREIGHT ? schema.required(DEFAULT_REQUIRED_MSG) : schema.optional()
  ),
  /* required when IOR */
  orderingEntity: yupOptionDefinitionSchema.when('$relationType', ([relationType], schema) =>
    relationType === RelationType.IOR ? schema.required(DEFAULT_REQUIRED_MSG) : schema.optional()
  ),
  programType: yupOptionDefinitionSchema.when('$relationType', ([relationType], schema) =>
    relationType === RelationType.IOR ? schema.required(DEFAULT_REQUIRED_MSG) : schema.optional()
  ),
  /* optional */
  supplierHasImportCapability: yupOptionDefinitionSchema.partial().nullable(),
  awsHasImportCapability: yupOptionDefinitionSchema.partial().nullable(),
  changeNotes: yup.string().nullable().max(1000),
});

export const incotermFormEditSchema = yup.object().shape({
  relationValue: yupOptionDefinitionSchema.required(DEFAULT_REQUIRED_MSG),
  changeNotes: yup.string().nullable().max(1000),
});

export type IncotermRelationFormInputs = yup.InferType<typeof incotermFormSchema>;

export const incoFormSchemaToApiSchema = (
  relationInput: IncotermRelationFormInputs,
  relationType: RelationType
): CreateIncotermRelationInput => {
  const {
    relationValue,
    orderingEntity,
    destinationCountry,
    productCategory,
    supplier,
    programType,
    supplierHasImportCapability,
    awsHasImportCapability,
    changeNotes,
  } = relationInput;
  const programTypeVal = programType?.value;
  const inferredIdentifierType = PROGRAM_TYPES.find((pt) => pt.value === programTypeVal)?.identifierType;
  return {
    identifierTypes: [inferredIdentifierType ?? IdentifierType.PURCHASE_ORDER_DEFAULT],
    relationType,
    relationValue: relationValue.value,
    input: {
      orderingEntity: orderingEntity?.value,
      orderingEntityName: orderingEntity?.label,
      destinationCountry: destinationCountry?.value,
      destinationCountryName: destinationCountry?.label,
      supplier: supplier?.value,
      supplierName: supplier?.label,
      productCategory: productCategory?.value,
      programType: programTypeVal,
      supplierHasImportCapability: !supplierHasImportCapability?.value
        ? null
        : supplierHasImportCapability?.value === 'YES',
      awsHasImportCapability: !awsHasImportCapability?.value ? null : awsHasImportCapability?.value === 'YES',
      changeNotes,
    },
  };
};

export type IncotermRelationFormEditInputs = yup.InferType<typeof incotermFormEditSchema>;

export const incoFormSchemaToEditSchema = (
  relationInput: IncotermRelationFormInputs,
  relation: IncotermRelation
): UpdateIncotermRelationInput => {
  // map all nulls to undefiend so we don't override atributes
  const relationWithoutNulls = mapObj(relation, (val) => val ?? undefined);
  const { destinationCountry, orderingEntity, productCategory, supplier, programType } = relationWithoutNulls;
  const { changeNotes, relationValue, awsHasImportCapability, supplierHasImportCapability } = relationInput;

  return {
    identifierType: relation.identifierType,
    relationType: relation.relationType,
    // input required to query the decision
    input: {
      destinationCountry,
      orderingEntity,
      productCategory,
      supplier,
      programType: relation.relationType === RelationType.IOR ? programType : undefined,
    },
    // extra updateable attributes
    updateAdditionalValues: {
      changeNotes,
      supplierHasImportCapability: !supplierHasImportCapability?.value
        ? null
        : supplierHasImportCapability?.value === 'YES',
      awsHasImportCapability: !awsHasImportCapability?.value ? null : awsHasImportCapability?.value === 'YES',
    },
    // update value and pass version
    relationValue: relationValue?.value,
    version: relation.version,
  };
};

export const incoApiSchemaToFormSchema = (relation: IncotermRelation): Partial<IncotermRelationFormInputs> => {
  const { relationValue, changeNotes, supplierHasImportCapability, awsHasImportCapability } = relation;
  const supplierImportDisplay = !isOneNullish(supplierHasImportCapability)
    ? YES_NO_OPTIONAL.find((op) => (supplierHasImportCapability === true ? op.value === 'YES' : op.value === 'NO'))
    : null;
  const awsImportDisplay = !isOneNullish(awsHasImportCapability)
    ? YES_NO_OPTIONAL.find((op) => (awsHasImportCapability === true ? op.value === 'YES' : op.value === 'NO'))
    : null;
  return {
    relationValue: getOptionByValue(relationValue, DECISION_VALUES_ALL),
    changeNotes,
    supplierHasImportCapability: !isOneNullish(supplierImportDisplay?.value) ? supplierImportDisplay : null,
    awsHasImportCapability: !isOneNullish(awsImportDisplay?.value) ? awsImportDisplay : null,
  };
};

export { default } from './IncotermCommon';
