import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FlashbarItemsProps } from 'types';

export type AssignLicenseLocalState = {
  assignLicenseFlashbarItems: FlashbarItemsProps[];
};

const initialState: AssignLicenseLocalState = {
  assignLicenseFlashbarItems: [],
};

/** Assign License Slice */
const { reducer, actions } = createSlice({
  name: 'assignLicense',
  initialState,
  reducers: {
    setAssignLicenseFlashbarItems: (state, { payload }: PayloadAction<FlashbarItemsProps[]>) => {
      state.assignLicenseFlashbarItems = payload;
    },
  },
});

export default reducer;

export const { setAssignLicenseFlashbarItems } = actions;
