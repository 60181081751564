import {
  Button,
  ColumnLayout,
  Container,
  FormField,
  Header,
  Pagination,
  Select,
  SpaceBetween,
  Table,
} from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { COUNTRIES, TRANSACTION_TYPES } from '@amzn/gtpccipstatic-config/dist/constants';
import { Link } from 'react-router-dom';
import { useListTradeRouteSchedulesQuery } from 'api/baseApi';
import { getTableSelectionLabels } from 'utils/tableUtils';
import { getColumnDefs } from '.';
import useQueryParams from 'hooks/useQueryParams';
import { getOptionByValue } from 'utils/formUtils';
import { Maybe, RouteSchedule, TransactionType } from '@amzn/cip-bff-schema';
import useDeleteTRSScheduleModal from 'hooks/useDeleteTRSScheduleModal';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setTRSLandingFlashbarItems } from 'store/trs';
import { v4 as uuid } from 'uuid';

const TRSLandingPage: React.FC = () => {
  const dispatch = useDispatch();
  const [transactionTypeQp, setTransactionType] = useQueryParams<string>('tt');
  const [fromCountryQp, setFromCountryDefault] = useQueryParams<string>('fc');
  const [toCountryQp, setToCountryDefault] = useQueryParams<string>('tc');
  const transactionType = getOptionByValue(transactionTypeQp, TRANSACTION_TYPES);
  const fromCountry = getOptionByValue(fromCountryQp, COUNTRIES);
  const toCountry = getOptionByValue(toCountryQp, COUNTRIES);
  const hasRequiredFields = transactionType && fromCountry && toCountry;
  const [pageSize] = useState(15);

  const { data, isLoading, isError, requestId } = useListTradeRouteSchedulesQuery(
    {
      fromCountry: fromCountryQp,
      toCountry: toCountryQp,
      transactionType: transactionTypeQp as TransactionType,
    },
    {
      skip: !hasRequiredFields,
    }
  );

  useEffect(() => {
    if (isError)
      dispatch(
        setTRSLandingFlashbarItems([
          {
            header: 'Error',
            type: 'error',
            content: `Could not retrieve trade route schedules. Please wait a moment and try again.`,
            dismissId: uuid(),
          },
        ])
      );
  }, [dispatch, isError, requestId]);

  const routeSelectionHeader = <Header>Select a Route</Header>;
  const routeSelectionContainer = (
    <Container header={routeSelectionHeader}>
      <ColumnLayout columns={2}>
        <FormField
          label="Transaction Type"
          description="What type of transaction are you managing?"
          data-testid="transactionType"
        >
          <Select
            selectedOption={transactionType ?? null}
            onChange={({ detail }) => {
              setTransactionType(detail.selectedOption.value);
            }}
            placeholder="Select a transaction type"
            filteringType="auto"
            options={TRANSACTION_TYPES}
          />
        </FormField>
      </ColumnLayout>
      <ColumnLayout columns={2}>
        <FormField label="From Country" description="Where are you shipping from?" data-testid="fromCountry">
          <Select
            selectedOption={fromCountry ?? null}
            onChange={({ detail }) => {
              setFromCountryDefault(detail.selectedOption.value);
            }}
            placeholder="Select a country"
            filteringType="auto"
            options={COUNTRIES}
          />
        </FormField>
      </ColumnLayout>
      <ColumnLayout columns={2}>
        <FormField label="To Country" description="Where are you shipping to?" data-testid="toCountry">
          <Select
            selectedOption={toCountry ?? null}
            onChange={({ detail }) => {
              setToCountryDefault(detail.selectedOption.value);
            }}
            placeholder="Select a country"
            filteringType="auto"
            options={COUNTRIES}
          />
        </FormField>
      </ColumnLayout>
    </Container>
  );

  const headerDesc = hasRequiredFields && (
    <>
      {`${transactionType?.label} trade route between ${fromCountry?.label} and ${toCountry?.label}. `}
      <b>Note: </b>
      {`The bold schedule represents the value
      currently within the effective date range.`}
    </>
  );

  const headerTitle = hasRequiredFields && `${transactionType?.label}: ${fromCountry?.label} to ${toCountry?.label}`;

  const { items, collectionProps, paginationProps } = useCollection<Maybe<RouteSchedule>>(
    hasRequiredFields && data?.routeSchedules?.schedules ? data?.routeSchedules?.schedules : [],
    {
      filtering: {
        empty: hasRequiredFields ? 'No trade route schedules' : 'Please select a trade route schedule',
        noMatch: 'No match',
      },
      sorting: {},
      pagination: { pageSize },
      selection: {},
    }
  );

  const { selectedItems } = collectionProps;

  const isOnlyOneSelected = selectedItems?.length === 1;
  const selectedItem = isOnlyOneSelected ? (selectedItems[0] as RouteSchedule) : undefined;
  const baseQueryParams = `tt=${transactionType?.value}&fc=${fromCountry?.value}&tc=${toCountry?.value}`;

  const [deleteModal, setDeleteModalOpen] = useDeleteTRSScheduleModal(selectedItem);

  const scheduleTableHeader = (
    <Header
      description={headerDesc}
      actions={
        <SpaceBetween size="xs" direction="horizontal">
          <Link to={`/trade-routes/history?${baseQueryParams}`}>
            <Button disabled={!hasRequiredFields}>Audit History</Button>
          </Link>
          <Link to={`/trade-routes/details/${selectedItem?.scheduleId}?${baseQueryParams}`}>
            <Button disabled={!isOnlyOneSelected}>View details</Button>
          </Link>
          <Link to={`/trade-routes/edit/${selectedItem?.scheduleId}?${baseQueryParams}`}>
            <Button disabled={!isOnlyOneSelected}>Edit</Button>
          </Link>
          <Button data-testid="trs-delete" disabled={!isOnlyOneSelected} onClick={() => setDeleteModalOpen(true)}>
            Delete
          </Button>
          <Link to={`/trade-routes/create?${baseQueryParams}`}>
            <Button disabled={!hasRequiredFields} variant="primary">
              Create Schedule
            </Button>
          </Link>
        </SpaceBetween>
      }
    >
      {headerTitle}
    </Header>
  );

  return (
    <>
      {deleteModal}
      <SpaceBetween size="l">
        {routeSelectionContainer}
        <Table
          header={scheduleTableHeader}
          columnDefinitions={getColumnDefs(data?.routeSchedules?.currentScheduleId ?? '')}
          items={items}
          selectionType="single"
          ariaLabels={getTableSelectionLabels<Maybe<RouteSchedule>>('Trade route schedule')}
          loading={isLoading}
          pagination={<Pagination {...paginationProps} />}
          {...collectionProps}
        />
      </SpaceBetween>
    </>
  );
};

export default TRSLandingPage;
