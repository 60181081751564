import { AppLayout, BreadcrumbGroup, Flashbar, Header } from '@amzn/awsui-components-react';
import { CIPSideNavigation } from 'components/SideNavigation/SideNavigation';
import { APP_LAYOUT_LABELS, HEADER_SELECTOR } from 'config/navConfig';
import { getTRSBaseQueryParams } from 'hooks/useQueryParams/useQueryParams';
import { useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import ToolsContent from 'features/TradeRouteCommon/TradeRouteToolsContent';
import { useAppSelector } from 'store/store';
import { setTRSEditFlashbarItems, setTRSSideNavState } from 'store/trs';
import { flashbarItemsCreator } from 'utils/formUtils';
import TRSEditPageContent from './TRSEditPage';

const TRSEditPage: React.FC = () => {
  const dispatch = useDispatch();
  const [queryParams] = useSearchParams();
  const { scheduleId: scheduleIdVal } = useParams();
  const scheduleId = scheduleIdVal || '';

  const baseQueryParams = getTRSBaseQueryParams(queryParams);

  const { trsEditFlashbarItems, trsSideNavState } = useAppSelector((state) => state.trs);
  const { toolsOpen, toolsContentId } = trsSideNavState;

  return (
    <AppLayout
      contentType="form"
      headerSelector={HEADER_SELECTOR}
      navigation={<CIPSideNavigation />}
      contentHeader={<Header variant="h1">Edit a Trade Route Schedule</Header>}
      breadcrumbs={
        <BreadcrumbGroup
          items={[
            { text: 'Compliance Integration', href: '/' },
            { text: 'Trade Routes', href: `/trade-routes?${baseQueryParams}` },
            { text: 'Edit', href: '#' },
            { text: scheduleId, href: '#' },
          ]}
        />
      }
      content={<TRSEditPageContent scheduleId={scheduleId} />}
      ariaLabels={APP_LAYOUT_LABELS}
      notifications={<Flashbar items={flashbarItemsCreator(trsEditFlashbarItems, dispatch, setTRSEditFlashbarItems)} />}
      toolsHide={!toolsOpen}
      tools={ToolsContent[toolsContentId]}
      toolsOpen={toolsOpen}
      onToolsChange={({ detail }) => dispatch(setTRSSideNavState({ toolsContentId, toolsOpen: detail.open }))}
      stickyNotifications
    />
  );
};

export default TRSEditPage;
