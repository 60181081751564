import { Mutation, Query } from '@amzn/cip-bff-schema';
import { SerializedError } from '@reduxjs/toolkit';
import { ErrorResponse } from 'api/baseApi';

/**
 * Type guard for API response so we can get the correct data response without casting
 * @param response API response
 * @returns boolean that indicates if response is without error
 */
export const hasResponseWithoutErrors = (
  response:
    | {
        data: Partial<Mutation | Query>;
      }
    | {
        error: Pick<ErrorResponse, 'message' | 'name' | 'stack'> | SerializedError;
      }
): response is {
  data: Mutation | Query;
} => {
  const hasData = 'data' in response;
  const hasError = 'error' in response;
  return response && hasData && !hasError;
};

/**
 * Type guard for API response so we can get the correct error response type without casting
 * @param response API response
 * @returns boolean that indicates if type has GQL error(s)
 */
export const hasGQLError = (
  response:
    | {
        data: Partial<Mutation | Query>;
      }
    | {
        error: Pick<ErrorResponse, 'message' | 'name' | 'stack'> | SerializedError;
      }
): response is { error: ErrorResponse } => {
  const hasError = 'error' in response;
  return response && hasError && 'errors' in response.error;
};
