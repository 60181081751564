import {
  Box,
  Button,
  Container,
  Form,
  FormField,
  Header,
  Select,
  SelectProps,
  SpaceBetween,
  StatusIndicator,
  Textarea,
} from '@amzn/awsui-components-react';
import { COUNTRIES } from '@amzn/gtpccipstatic-config/dist/constants';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IncotermRelationFormInputs, incoApiSchemaToFormSchema, incotermFormEditSchema, incotermFormSchema } from '.';
import {
  IncotermRelation,
  RelationType,
  SearchVendorCombineOperator,
  SearchVendorEntityResult,
} from '@amzn/cip-bff-schema';
import {
  DECISION_VALUES_ALL,
  DECISION_VALUES_FREIGHT,
  PRODUCT_CATEGORIES,
  PROGRAM_TYPES,
  YES_NO_OPTIONAL,
  staticSearchVendorFilters,
} from 'config/incotermConstants';
import { useLazySearchVendorsQuery } from 'api/baseApi';
import { getOptionalLabel } from 'utils/formUtils';
import { useGetLegalEntitiesQuery } from 'api/s3BucketApi';

interface IncotermCommonFormProps {
  onSubmit: SubmitHandler<IncotermRelationFormInputs>;
  formHeaderText: string;
  submitText: string;
  cancelRoute: string;
  relationType: RelationType;
  isLoading?: boolean;
  isEdit?: boolean;
  incotermRelation?: IncotermRelation;
  defaultValues?: Partial<IncotermRelationFormInputs>;
}

const IncotermCommonForm: React.FC<IncotermCommonFormProps> = ({
  onSubmit,
  formHeaderText,
  submitText,
  cancelRoute,
  relationType,
  incotermRelation,
  isLoading = false,
  isEdit = false,
  defaultValues,
}) => {
  const [supplierStatusType, setSupplierStatusType] = useState<SelectProps['statusType']>('pending');
  const [vmsResults, setVmsResults] = useState<SearchVendorEntityResult['vendorEntitySummaryList']>([]);
  const [vmsLoadItemsProps, setVmsLoadItemsProps] = useState<{ firstPage: boolean; filteringText: string }>({
    firstPage: true,
    filteringText: '',
  });
  const { filteringText } = vmsLoadItemsProps;

  const { control, handleSubmit, watch } = useForm<IncotermRelationFormInputs>({
    defaultValues: incotermRelation ? incoApiSchemaToFormSchema(incotermRelation) : defaultValues,
    resolver: yupResolver(isEdit ? incotermFormEditSchema : incotermFormSchema),
    mode: 'onChange',
    context: { relationType },
  });
  const { destinationCountry, orderingEntity } = watch();

  const {
    data: orderingEntities,
    isFetching: isOrderingEntitiesFetching,
    isError: isOrderingEntitiesError,
  } = useGetLegalEntitiesQuery();

  const [trigger, result] = useLazySearchVendorsQuery();
  const { data } = result;

  const onVMSLoadItems: SelectProps['onLoadItems'] = async ({ detail: { filteringText, firstPage } }) => {
    setSupplierStatusType('loading');
    setVmsLoadItemsProps({ filteringText, firstPage });
    if (firstPage) {
      setVmsResults([]);
    }
    try {
      const result = await trigger(
        {
          maxResults: 50,
          query: {
            conditions: [
              {
                name: {
                  isOneOfPhraseMatch: filteringText ? [filteringText] : undefined,
                  regExMatch: filteringText ? undefined : '.*',
                },
                ...staticSearchVendorFilters,
                combineWith: SearchVendorCombineOperator.AND,
                propertyCombineWith: SearchVendorCombineOperator.AND,
              },
              {
                id: {
                  exactMatch: filteringText.toUpperCase(),
                },
                ...staticSearchVendorFilters,
                combineWith: SearchVendorCombineOperator.AND,
                propertyCombineWith: SearchVendorCombineOperator.AND,
              },
            ],
          },
          nextToken: firstPage ? undefined : data?.searchVendors?.nextToken,
        },
        true
      ).unwrap();
      if (firstPage) {
        setVmsResults(result?.searchVendors?.vendorEntitySummaryList);
      } else {
        setVmsResults((old) => old.concat(result?.searchVendors?.vendorEntitySummaryList));
      }
      setSupplierStatusType('finished');
      if (result?.searchVendors?.nextToken) {
        setSupplierStatusType('pending');
      }
    } catch (e) {
      setSupplierStatusType('error');
    }
  };

  const formHeader = <Header>{formHeaderText}</Header>;
  const formContainer = (
    <Container header={formHeader}>
      <SpaceBetween size="l">
        {!isEdit && (
          <>
            <Controller
              name="destinationCountry"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormField
                  label="Destination Country"
                  description="What is the destination country?"
                  errorText={error && error.message}
                >
                  <Select
                    filteringType="auto"
                    selectedOption={value ?? null}
                    options={COUNTRIES}
                    onChange={(e) => onChange(e.detail.selectedOption)}
                    placeholder="Choose a value"
                  />
                </FormField>
              )}
            />
            {relationType === RelationType.IOR && (
              <Controller
                name="orderingEntity"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <FormField label="Ordering Entity" errorText={error && error.message}>
                    <Select
                      filteringType="auto"
                      selectedOption={value ?? null}
                      options={orderingEntities}
                      onChange={(e) => onChange(e.detail.selectedOption)}
                      placeholder="Choose a value"
                      statusType={
                        isOrderingEntitiesFetching ? 'loading' : isOrderingEntitiesError ? 'error' : 'finished'
                      }
                    />
                  </FormField>
                )}
              />
            )}
            {destinationCountry?.value &&
              orderingEntity?.value &&
              !orderingEntity?.filteringTags?.includes(destinationCountry?.value) && (
                <Box>
                  <StatusIndicator type="info">
                    Ordering Entity Country:{' '}
                    {orderingEntity?.filteringTags?.length ? orderingEntity?.filteringTags : 'N/A'}
                  </StatusIndicator>
                </Box>
              )}
            {relationType === RelationType.FREIGHT && (
              <Controller
                name="productCategory"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <FormField
                    label="Product Category"
                    description="What category does this relation apply to?"
                    errorText={error && error.message}
                  >
                    <Select
                      filteringType="auto"
                      selectedOption={value ?? null}
                      options={PRODUCT_CATEGORIES}
                      onChange={(e) => onChange(e.detail.selectedOption)}
                      placeholder="Choose a value"
                    />
                  </FormField>
                )}
              />
            )}
            <Controller
              name="supplier"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormField
                  label="Supplier/Vendor"
                  description="Enter the vendor ID or name associated with this relation"
                  errorText={error && error.message}
                >
                  <SpaceBetween size="l">
                    <Select
                      selectedOption={value ?? null}
                      options={vmsResults.map((v) => ({
                        label: v?.name ?? '',
                        value: v?.id ?? '',
                        description: v?.id ?? '',
                      }))}
                      onChange={(e) => onChange(e.detail.selectedOption)}
                      placeholder="Choose a value"
                      filteringType="manual"
                      onLoadItems={onVMSLoadItems}
                      statusType={supplierStatusType}
                      loadingText="Loading suppliers..."
                      finishedText={filteringText ? `End of "${filteringText}" results` : 'End of all results'}
                      empty="No suppliers found"
                      filteringPlaceholder="Search for a supplier"
                      errorText="Error fetching results."
                      recoveryText="Retry"
                    />
                    {value?.value && (
                      <Box>
                        <StatusIndicator type="info">Selected Vendor ID: {value?.value}</StatusIndicator>
                      </Box>
                    )}
                  </SpaceBetween>
                </FormField>
              )}
            />
          </>
        )}
        {relationType === RelationType.IOR && (
          <>
            <Controller
              name="supplierHasImportCapability"
              control={control}
              render={({ field: { onChange, value = null }, fieldState: { error } }) => {
                return (
                  <FormField
                    label={getOptionalLabel('Supplier Import Capability')}
                    description="Is the supplier able to import?"
                    errorText={error && error.message}
                  >
                    <Select
                      filteringType="auto"
                      selectedOption={value ?? null}
                      options={YES_NO_OPTIONAL}
                      onChange={(e) => onChange(e.detail.selectedOption)}
                      placeholder="Choose a value"
                    />
                  </FormField>
                );
              }}
            />
            <Controller
              name="awsHasImportCapability"
              control={control}
              render={({ field: { onChange, value = null }, fieldState: { error } }) => (
                <FormField
                  label={getOptionalLabel('AWS Import Capability')}
                  description="Is AWS able to import?"
                  errorText={error && error.message}
                >
                  <Select
                    filteringType="auto"
                    selectedOption={value ?? null}
                    options={YES_NO_OPTIONAL}
                    onChange={(e) => onChange(e.detail.selectedOption)}
                    placeholder="Choose a value"
                  />
                </FormField>
              )}
            />
            {!isEdit && (
              <Controller
                name="programType"
                control={control}
                render={({ field: { onChange, value = null }, fieldState: { error } }) => (
                  <FormField
                    label="Program Type"
                    description="What program does this decision apply to? The default 'All' will be used if not specified."
                    errorText={error && error.message}
                  >
                    <Select
                      filteringType="auto"
                      selectedOption={value}
                      options={PROGRAM_TYPES}
                      onChange={(e) => onChange(e.detail.selectedOption)}
                      placeholder="Choose a value"
                    />
                  </FormField>
                )}
              />
            )}
          </>
        )}
        <Controller
          name="relationValue"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormField
              label={`Decision Value`}
              description="What is the value of this decision?"
              errorText={error && error.message}
            >
              <Select
                filteringType="auto"
                selectedOption={value ?? null}
                options={relationType === RelationType.IOR ? DECISION_VALUES_ALL : DECISION_VALUES_FREIGHT}
                onChange={(e) => onChange(e.detail.selectedOption)}
                placeholder="Choose a value"
              />
            </FormField>
          )}
        />
        <Controller
          name="changeNotes"
          control={control}
          render={({ field: { onChange, value = '' }, fieldState: { error } }) => (
            <FormField
              label={`${isEdit ? 'Change ' : ''}Notes`}
              description="Internal notes for auditing purposes"
              errorText={error && error.message}
            >
              <Textarea value={value ?? ''} onChange={(e) => onChange(e.detail.value)} placeholder="Change notes" />
            </FormField>
          )}
        />
      </SpaceBetween>
    </Container>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Form
        actions={
          <SpaceBetween size="xs" direction="horizontal">
            <Link to={cancelRoute}>
              <Button disabled={isLoading}>Cancel</Button>
            </Link>
            <Button
              disabled={isLoading}
              loading={isLoading}
              formAction="submit"
              variant="primary"
              data-testid="submit-form-btn"
            >
              {submitText}
            </Button>
          </SpaceBetween>
        }
      >
        {formContainer}
      </Form>
    </form>
  );
};

export default IncotermCommonForm;
