import { AppLayout, BreadcrumbGroup, Flashbar, Header } from '@amzn/awsui-components-react';
import { CIPSideNavigation } from 'components/SideNavigation/SideNavigation';
import { APP_LAYOUT_LABELS, HEADER_SELECTOR } from 'config/navConfig';
import { INCO_RELATION_TYPE_DISPLAY, IncotermRelationLandingPageProps } from 'features/IncotermRelations';
import ToolsContent from 'features/IncotermCommon/IncotermToolsContent';
import { useDispatch } from 'react-redux';
import { setIncoEditFlashbarItems, setIncoSideNavState } from 'store/incoterms';
import { useAppSelector } from 'store/store';
import { flashbarItemsCreator } from 'utils/formUtils';
import IREditContent from './IREdit';

const IREditPage: React.FC<IncotermRelationLandingPageProps> = ({ relationType }) => {
  const dispatch = useDispatch();
  const { incoSideNavState, incoEditFlashbarItems } = useAppSelector((state) => state.incoterms);
  const { toolsOpen, toolsContentId } = incoSideNavState;

  const { shortName: relationShortName } = INCO_RELATION_TYPE_DISPLAY[relationType];
  const routePath = relationShortName.toLowerCase();

  return (
    <AppLayout
      contentType="form"
      headerSelector={HEADER_SELECTOR}
      navigation={<CIPSideNavigation />}
      contentHeader={<Header variant="h1">Edit {relationShortName} Decision</Header>}
      breadcrumbs={
        <BreadcrumbGroup
          items={[
            { text: 'Compliance Integration', href: '/' },
            { text: 'Incoterms', href: `/incoterms/${routePath}` },
            { text: `${relationShortName} Decision`, href: `/incoterms/${routePath}` },
            { text: 'Edit', href: '#' },
          ]}
        />
      }
      toolsHide={!toolsOpen}
      tools={ToolsContent[toolsContentId]}
      toolsOpen={toolsOpen}
      onToolsChange={({ detail }) => dispatch(setIncoSideNavState({ toolsContentId, toolsOpen: detail.open }))}
      content={<IREditContent relationType={relationType} relationDisplay={INCO_RELATION_TYPE_DISPLAY[relationType]} />}
      ariaLabels={APP_LAYOUT_LABELS}
      notifications={
        <Flashbar items={flashbarItemsCreator(incoEditFlashbarItems, dispatch, setIncoEditFlashbarItems)} />
      }
      stickyNotifications
    />
  );
};

export default IREditPage;
