import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import PageWrapper from 'components/PageWrapper';
import { AppDispatch, useAppSelector } from 'store/store';
import { getUser } from 'store/userSlice';

import './App.scss';
import useAppInit from 'hooks/useAppInit';
import AppRouter, { UnauthedRouter } from './AppRouter';
import ErrorBoundary from 'components/Error/ErrorBoundary';

const App: React.FC = () => {
  let content = null;
  const dispatch = useDispatch<AppDispatch>();
  const { initialized, cwr } = useAppInit();

  const { userLoading, token, authError } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (!token && initialized) dispatch(getUser({}));
  }, [dispatch, token, initialized]);

  if (authError) {
    return <UnauthedRouter />;
  }

  content =
    userLoading !== 'pending' && token ? (
      <Router>
        <PageWrapper pageTitle="CIP" loading={false}>
          <AppRouter />
        </PageWrapper>
      </Router>
    ) : null;

  return <ErrorBoundary cwr={cwr}>{content}</ErrorBoundary>;
};

export default App;
