import { EnumDisplayMapping } from '@amzn/gtpccipstatic-config/dist/types';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { LEGAL_ENTITIES_JSON_FILE_NAME } from 'config/incotermConstants';
import { OrderingEntity } from 'types/constants';

export const websiteBucketApi = createApi({
  reducerPath: 'websiteBucketApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/' }),
  endpoints: (builder) => ({
    getLegalEntities: builder.query<(EnumDisplayMapping & { filteringTags?: string[] })[], void>({
      query: () => LEGAL_ENTITIES_JSON_FILE_NAME,
      // transform response for dropdown
      transformResponse: (response: string) => {
        try {
          const responseJson: OrderingEntity[] = JSON.parse(response);
          return responseJson
            ? responseJson.map((le) => ({
                // JSON.parse might parse OracleCompanyNumbers as a number, make sure its a string
                value: String(le?.OracleCompanyNumbers),
                label: le?.BusinessEntityName,
                description: String(le?.OracleCompanyNumbers),
                filteringTags: le?.Country ? [le.Country] : [],
              }))
            : [];
        } catch (e) {
          // log and fail silently in case JSON.parse fails
          console.error(e);
          return [];
        }
      },
    }),
  }),
});

export const { useGetLegalEntitiesQuery } = websiteBucketApi;
