import { AppLayout, BreadcrumbGroup, Flashbar, Header } from '@amzn/awsui-components-react';
import { CIPSideNavigation } from 'components/SideNavigation/SideNavigation';
import { APP_LAYOUT_LABELS, HEADER_SELECTOR } from 'config/navConfig';
import IncotermIORContent from './IncotermRealtionshipLandingPage';
import { RelationType } from '@amzn/cip-bff-schema';
import { useAppSelector } from 'store/store';
import { useDispatch } from 'react-redux';
import { flashbarItemsCreator } from 'utils/formUtils';
import { IncotermRelationDisplay } from 'types/constants';
import { setIncoLandingFlashbarItems, setIncoSideNavState } from 'store/incoterms';
import ToolsContent from 'features/IncotermCommon/IncotermToolsContent';

export const INCO_RELATION_TYPE_DISPLAY: IncotermRelationDisplay = {
  [RelationType.IOR]: {
    longName: 'Importer of Record',
    shortName: 'IOR',
  },
  [RelationType.FREIGHT]: {
    longName: 'Freight',
    shortName: 'Freight',
  },
};

export interface IncotermRelationLandingPageProps {
  relationType: RelationType;
}

const IncotermIORLandingPage: React.FC<IncotermRelationLandingPageProps> = ({ relationType }) => {
  const relationDisplay = INCO_RELATION_TYPE_DISPLAY[relationType];
  const dispatch = useDispatch();
  const { incoSideNavState, incoLandingFlashbarItems } = useAppSelector((state) => state.incoterms);
  const { toolsOpen, toolsContentId } = incoSideNavState;

  return (
    <AppLayout
      contentType="table"
      headerSelector={HEADER_SELECTOR}
      toolsHide={!toolsOpen}
      tools={ToolsContent[toolsContentId]}
      toolsOpen={toolsOpen}
      onToolsChange={({ detail }) => dispatch(setIncoSideNavState({ toolsContentId, toolsOpen: detail.open }))}
      navigation={<CIPSideNavigation />}
      contentHeader={
        <Header variant="h1" description={`Manage incoterm decisions for ${relationDisplay.longName}`}>
          {`Incoterms: ${relationDisplay.shortName} Decisions`}
        </Header>
      }
      notifications={
        <Flashbar items={flashbarItemsCreator(incoLandingFlashbarItems, dispatch, setIncoLandingFlashbarItems)} />
      }
      stickyNotifications
      breadcrumbs={
        <BreadcrumbGroup
          items={[
            { text: 'Compliance Integration', href: '/' },
            { text: 'Incoterms', href: '#' },
            { text: `${relationDisplay.shortName} Decisions`, href: '#' },
          ]}
        />
      }
      content={<IncotermIORContent relationType={relationType} />}
      ariaLabels={APP_LAYOUT_LABELS}
    />
  );
};

export default IncotermIORLandingPage;
