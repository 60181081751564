import { AppLayout, BreadcrumbGroup, Flashbar, Header, TableProps } from '@amzn/awsui-components-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CIPSideNavigation } from 'components/SideNavigation/SideNavigation';
import { APP_LAYOUT_LABELS, HEADER_SELECTOR } from 'config/navConfig';
import TRSHistoryPageContent from './TRSHistoryPage';
import { Audit } from '@amzn/cip-bff-schema';
import useQueryParams from 'hooks/useQueryParams';
import ToolsContent from 'features/TradeRouteCommon/TradeRouteToolsContent';
import { COUNTRIES, TRANSACTION_TYPES } from '@amzn/gtpccipstatic-config/dist/constants';
import { flashbarItemsCreator, getOptionByValue } from 'utils/formUtils';
import { useEffect } from 'react';
import { formatFromEpoch } from 'utils/dateTimeUtils';
import { useAppSelector } from 'store/store';
import { setTRSAuditFlashbarItems, setTRSSideNavState } from 'store/trs';
import { useDispatch } from 'react-redux';
import { getTRSBaseQueryParams } from 'hooks/useQueryParams';

export const COLUMN_DEFS: TableProps.ColumnDefinition<Audit>[] = [
  {
    id: 'scheduleId',
    header: 'Schedule ID',
    cell: (item) => item.scheduleId,
    sortingField: 'scheduleId',
  },
  {
    id: 'modifiedAt',
    header: 'Modified At',
    cell: (item) => formatFromEpoch(item?.modifiedAt),
    sortingField: 'modifiedAt',
  },
  {
    id: 'modifiedBy',
    header: 'Modified By',
    cell: (item) => item.modifiedBy,
    sortingField: 'modifiedBy',
  },
  {
    id: 'changeNotes',
    header: 'Change Notes',
    cell: (item) => item.changeNotes,
    sortingField: 'changeNotes',
  },
];

const TRSRouteHistoryPage: React.FC = () => {
  const dispatch = useDispatch();
  const { trsAuditFlashbarItems, trsSideNavState } = useAppSelector((state) => state.trs);
  const { toolsOpen, toolsContentId } = trsSideNavState;
  const navigate = useNavigate();
  const [transactionTypeQp] = useQueryParams<string>('tt');
  const [fromCountryQp] = useQueryParams<string>('fc');
  const [toCountryQp] = useQueryParams<string>('tc');
  const transactionType = getOptionByValue(transactionTypeQp, TRANSACTION_TYPES);
  const fromCountry = getOptionByValue(fromCountryQp, COUNTRIES);
  const toCountry = getOptionByValue(toCountryQp, COUNTRIES);
  const hasRequiredFields = transactionType?.value && fromCountry?.value && toCountry?.value ? true : false;

  const [queryParams] = useSearchParams();
  const baseQueryParams = getTRSBaseQueryParams(queryParams);

  useEffect(() => {
    if (!hasRequiredFields) {
      navigate('/trade-routes');
    }
  }, [hasRequiredFields, navigate]);

  const headerText = hasRequiredFields
    ? `- ${transactionType?.label}: ${fromCountry?.label} to ${toCountry?.label}`
    : '';

  return (
    <AppLayout
      contentType="table"
      headerSelector={HEADER_SELECTOR}
      navigation={<CIPSideNavigation />}
      contentHeader={<Header variant="h1">{`Audit History ${headerText}`}</Header>}
      notifications={
        <Flashbar items={flashbarItemsCreator(trsAuditFlashbarItems, dispatch, setTRSAuditFlashbarItems)} />
      }
      stickyNotifications
      breadcrumbs={
        <BreadcrumbGroup
          items={[
            { text: 'Compliance Integration', href: '/' },
            { text: 'Trade Routes', href: `/trade-routes?${baseQueryParams}` },
            { text: 'Audit History', href: '#' },
          ]}
        />
      }
      content={
        <TRSHistoryPageContent
          fromCountry={fromCountry}
          toCountry={toCountry}
          transactionType={transactionType}
          hasRequiredFields={hasRequiredFields}
        />
      }
      toolsHide={!toolsOpen}
      tools={ToolsContent[toolsContentId]}
      toolsOpen={toolsOpen}
      onToolsChange={({ detail }) => dispatch(setTRSSideNavState({ toolsContentId, toolsOpen: detail.open }))}
      ariaLabels={APP_LAYOUT_LABELS}
    />
  );
};

export default TRSRouteHistoryPage;
