import { BrowserRouter, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import NotAuthorized from 'components/Error/NotAuthorized';
import NotFound from 'components/Error/NotFound';
import TRSLandingPage from 'features/TradeRoutes';
import TRSCreatePage from 'features/TradeRouteCreate';
import TRSEditPage from 'features/TradeRouteEdit';
import TRSDetailPage from 'features/TradeRouteDetail';
import TRSAuditHistoryPage from 'features/TradeRouteHistory';
import AssignLicensePage from 'features/AssignLicense';
import { useAppSelector } from 'store/store';
import { ASSIGN_LICENSE_GROUPS } from 'config/navConfig';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAppNotifications } from 'store/configSlice';
import { v4 as uuid } from 'uuid';
import IncotermRelationsLandingPage from 'features/IncotermRelations';
import { RelationType } from '@amzn/cip-bff-schema';
import IncotermRelationCreatePage from 'features/IncotermRelationCreate';
import IncotermRelationEditPage from 'features/IncotermRelationEdit';
import DraftCIPage from 'features/DraftCI';

const AppRouter: React.FC = () => {
  return (
    <Routes>
      <Route path="trade-routes" element={<TRSLandingPage />} />
      <Route path="trade-routes/create" element={<TRSCreatePage />} />
      <Route path="trade-routes/edit/:scheduleId" element={<TRSEditPage />} />
      <Route path="trade-routes/details/:scheduleId" element={<TRSDetailPage />} />
      <Route path="trade-routes/history" element={<TRSAuditHistoryPage />} />
      <Route path="incoterms">
        <Route index element={<Navigate to="ior" />} />
        <Route path="ior" element={<IncotermRelationsLandingPage relationType={RelationType.IOR} />} />
        <Route path="freight" element={<IncotermRelationsLandingPage relationType={RelationType.FREIGHT} />} />
        <Route path="ior/create" element={<IncotermRelationCreatePage relationType={RelationType.IOR} />} />
        <Route path="freight/create" element={<IncotermRelationCreatePage relationType={RelationType.FREIGHT} />} />
        <Route path="ior/edit" element={<IncotermRelationEditPage relationType={RelationType.IOR} />} />
        <Route path="freight/edit" element={<IncotermRelationEditPage relationType={RelationType.FREIGHT} />} />
      </Route>
      <Route
        path="assign-license"
        element={<ProtectedRoute groupAllowList={ASSIGN_LICENSE_GROUPS} component={<AssignLicensePage />} />}
      />
      <Route path="draft-ci" element={<DraftCIPage />} />
      <Route path="*" element={<Navigate to="/trade-routes" />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const UnauthedRouter: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<NotAuthorized />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

interface ProtectedRouteProps {
  groupAllowList: string[];
  redirectPath?: string;
  component: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ redirectPath = '/', groupAllowList = [], component }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { groupMembership } = useAppSelector((state) => state.user);
  const [isGroupMember, setIsGroupMember] = useState(false);
  const [isGroupMemberChanged, setIsGroupMemberChanged] = useState(false);

  useEffect(() => {
    setIsGroupMember(groupMembership.some((r) => groupAllowList.indexOf(r) >= 0));
    setIsGroupMemberChanged(true);
  }, [groupMembership, groupAllowList]);

  useEffect(() => {
    if (!isGroupMember && isGroupMemberChanged) {
      dispatch(
        setAppNotifications([
          {
            header: 'Authorization Error',
            type: 'error',
            staticContentId: 'unauthorized',
            dismissId: uuid(),
          },
        ])
      );
      navigate(redirectPath);
    }
  }, [dispatch, isGroupMember, isGroupMemberChanged, navigate, redirectPath]);

  if (isGroupMember) return <>{component}</>;
  return null;
};

export { UnauthedRouter, ProtectedRoute };

export default AppRouter;
