import { Button, Container, Form, FormField, Header, Input, Select, SpaceBetween } from '@amzn/awsui-components-react';
import { CommercialInvoiceReviewStatusFromUser, TransactionType } from '@amzn/cip-bff-schema';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { DraftCIFormInputs, draftCISchema } from '.';
import { useSearchParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { DRAFT_CI_STATUS, TRANSACTION_TYPES } from '@amzn/gtpccipstatic-config/dist/constants';
import useSubmitDraftCIModal from 'hooks/useSubmitDraftCIModal';

const DraftCI: React.FC = () => {
  const [queryParams] = useSearchParams();

  const transactionId = queryParams.get('transactionId');
  const transactionType = queryParams.get('transactionType');

  const { control, handleSubmit } = useForm<DraftCIFormInputs>({
    resolver: yupResolver(draftCISchema),
    defaultValues: {
      transactionId: transactionId ?? undefined,
      transactionType: TRANSACTION_TYPES.find((tt) => tt.value === transactionType) ?? TRANSACTION_TYPES[0],
    },
    mode: 'onChange',
  });

  const [submitModal, setSubmitModalOpen, setSubmitModalInput] = useSubmitDraftCIModal();

  const onSubmit: SubmitHandler<DraftCIFormInputs> = async (data) => {
    const draftCIInput = {
      ...data,
      // safe casts to enums since these values can only ever come from matching dropdowns
      status: data?.status?.value as CommercialInvoiceReviewStatusFromUser,
      transactionType: data?.transactionType?.value as TransactionType,
    };
    setSubmitModalInput(draftCIInput);
    setSubmitModalOpen(true);
  };

  const formContent = (
    <Container
      header={<Header description="Please only utilize transaction IDs received via SIM ticket">Submit Review</Header>}
    >
      <SpaceBetween size="l">
        <Controller
          name="transactionId"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormField label="Transaction ID" description="TOA transaction ID" errorText={error && error.message}>
              <Input value={value} onChange={(e) => onChange(e.detail.value)} placeholder="Enter a transaction ID" />
            </FormField>
          )}
        />
        <Controller
          name="transactionType"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormField
              label="Transaction Type"
              description="What type of transaction is the TOA?"
              data-testid="transactionType"
              errorText={error && error.message}
            >
              <Select
                selectedOption={value ?? TRANSACTION_TYPES[0]}
                onChange={(e) => onChange(e.detail.selectedOption)}
                placeholder="Choose a transaction type"
                filteringType="auto"
                options={TRANSACTION_TYPES}
              />
            </FormField>
          )}
        />
        <Controller
          name="status"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormField
              label="Review Status"
              description="Approve or reject the current iteration of this transaction's Commercial Invoice"
              errorText={error && error.message}
            >
              <Select
                filteringType="auto"
                selectedOption={value ?? null}
                options={DRAFT_CI_STATUS}
                onChange={(e) => onChange(e.detail.selectedOption)}
                placeholder="Choose a status"
              />
            </FormField>
          )}
        />
      </SpaceBetween>
    </Container>
  );

  return (
    <>
      {submitModal}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Form
          actions={
            <SpaceBetween size="xs" direction="horizontal">
              <Button formAction="submit" variant="primary" data-testid="submit-form-btn">
                Submit
              </Button>
            </SpaceBetween>
          }
        >
          {formContent}
        </Form>
      </form>
    </>
  );
};

export default DraftCI;
