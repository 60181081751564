import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import { ClientError, GraphQLClient } from 'graphql-request';
import { DocumentNode } from 'graphql/language/ast';
import { RootState } from 'store/store';
import { GraphqlRequestBaseQueryArgs } from 'types/config';
import {
  AssignLicenseInput,
  CreateIncotermRelationInput,
  CreateRouteScheduleInput,
  DeleteIncotermRelationInput,
  GetIncotermRelationInput,
  ListIncotermRelationsInput,
  Mutation,
  Query,
  RouteAuditHistoryInput,
  RouteSchedulesInput,
  SearchVendorsInput,
  TransactionError,
  TrsError,
  UpdateCommercialInvoiceReviewInput,
  UpdateIncotermRelationInput,
  UpdateRouteScheduleInput,
} from '@amzn/cip-bff-schema';
import {
  AUDIT_HISTORY_TRS,
  CREATE_TRS,
  GET_INCOTERM_RELATION,
  LIST_INCOTERM_RELATIONS,
  LIST_TRS,
  SEARCH_VENDORS,
  UPDATE_TRS,
} from 'api';
import {
  ASSIGN_LICENSE,
  CREATE_INCOTERM_RELATION,
  DELETE_INCOTERM_RELATION,
  UPDATE_CI_REVIEW,
  UPDATE_INCOTERM_RELATION,
} from './graphql/mutations';
import { getUser } from 'store/userSlice';
import { IncotermRelationViewModel } from 'types/incoterms';
import { getOptionByValue } from 'utils/formUtils';
import { COUNTRIES } from '@amzn/gtpccipstatic-config/dist/constants';
import { DECISION_VALUES_ALL, PRODUCT_CATEGORIES, PROGRAM_TYPES } from 'config/incotermConstants';

const SCHEDULE_TAG = 'SCHEDULE';
const AUDIT_HISTORY_TAG = 'AUDIT_HISTORY';
const SEARCH_VENDORS_TAG = 'SEARCH_VENDORS';
const INCO_RS_GET_TAG = 'INCO_RS_GET';
const INCO_RS_LIST_TAG = 'INCO_RS_LIST';
const tagTypes = [SCHEDULE_TAG, AUDIT_HISTORY_TAG, SEARCH_VENDORS_TAG, INCO_RS_GET_TAG, INCO_RS_LIST_TAG];

export interface ErrorResponse extends Pick<ClientError, 'name' | 'message' | 'stack'> {
  errors: (TransactionError | TrsError)[];
}

/**
 * /**
 * Custom version of graphqlRequestBaseQuery that allows for initialization of the graphql client at action dispatch time.
 * This allows the API endpoint to be specified after the app is initialized and we have {ApplicationSettings}
 * @param {GraphqlRequestBaseQueryArgs} requestBaseQueryArgs base query args
 * @return {BaseQueryFn} augmented query result
 */
export const graphqlRequestBaseQuery = ({
  prepareHeaders = (headers) => headers,
}: GraphqlRequestBaseQueryArgs): BaseQueryFn<
  { document: string | DocumentNode; variables?: { [key: string]: unknown } },
  unknown,
  Pick<ErrorResponse, 'name' | 'message' | 'stack'>,
  Partial<Pick<ClientError, 'request' | 'response'>>
> => {
  return async ({ document, variables }, { getState, dispatch, endpoint, forced, type }) => {
    try {
      const state = getState() as RootState;
      const headers = new Headers();

      const client = new GraphQLClient(state.user?.apiUrl ? state.user?.apiUrl : 'http://localhost/');

      client.setHeaders(await prepareHeaders(headers, { getState, endpoint, forced, type }));
      const res = { data: await client.request(document, variables), meta: {} };
      return res;
    } catch (error) {
      if (error instanceof ClientError) {
        if (error.response.status === 401) {
          // unauthenticated, need to attempt to re-auth
          // the call to 'getSession' in the existing getUser function will check token refresh is needed
          dispatch(getUser({ isRefresh: true }));
        } else {
          const { name, message, stack, request, response } = error;
          return { error: { name, message, stack, errors: response.errors }, meta: { request, response } };
        }
      }
      throw error;
    }
  };
};

export const apiSlice = createApi({
  baseQuery: graphqlRequestBaseQuery({
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).user?.token;
      if (token) {
        headers.set('Authorization', token);
      }
      return headers;
    },
  }),
  tagTypes,
  endpoints: (builder) => ({
    /** TRS */
    listTradeRouteSchedules: builder.query<Query, RouteSchedulesInput>({
      query: (input) => ({
        document: LIST_TRS,
        variables: { input: input },
      }),
      providesTags: [SCHEDULE_TAG],
    }),
    createTradeRouteSchedule: builder.mutation<Mutation, CreateRouteScheduleInput>({
      query: (input) => ({
        document: CREATE_TRS,
        variables: { input: input },
      }),
      invalidatesTags: [SCHEDULE_TAG],
    }),
    updateTradeRouteSchedule: builder.mutation<Mutation, UpdateRouteScheduleInput>({
      query: (input) => ({
        document: UPDATE_TRS,
        variables: { input: input },
      }),
      invalidatesTags: [SCHEDULE_TAG, AUDIT_HISTORY_TAG],
    }),
    getTradeRouteAuditHistory: builder.query<Query, RouteAuditHistoryInput>({
      query: (input) => ({
        document: AUDIT_HISTORY_TRS,
        variables: { input: input },
      }),
      providesTags: [AUDIT_HISTORY_TAG],
    }),
    /** Assign License */
    assignLicense: builder.mutation<Mutation, AssignLicenseInput>({
      query: (input) => ({
        document: ASSIGN_LICENSE,
        variables: { input: input },
      }),
    }),
    /** CI Review */
    updateCIReview: builder.mutation<Mutation, UpdateCommercialInvoiceReviewInput>({
      query: (input) => ({
        document: UPDATE_CI_REVIEW,
        variables: { input },
      }),
    }),
    /* Search Vendors */
    searchVendors: builder.query<Query, SearchVendorsInput>({
      query: (input) => ({
        document: SEARCH_VENDORS,
        variables: {
          input,
        },
      }),
    }),
    /* Incoterms */
    createIncotermRelation: builder.mutation<Mutation, CreateIncotermRelationInput>({
      query: (input) => ({
        document: CREATE_INCOTERM_RELATION,
        variables: {
          input,
        },
      }),
      invalidatesTags: [INCO_RS_LIST_TAG],
    }),
    getIncotermRelation: builder.query<Query, GetIncotermRelationInput>({
      query: (input) => ({
        document: GET_INCOTERM_RELATION,
        variables: {
          input,
        },
      }),
      providesTags: [INCO_RS_GET_TAG],
    }),
    listIncotermRelations: builder.query<
      { nextToken?: string | null; incotermRelations?: IncotermRelationViewModel[] },
      ListIncotermRelationsInput
    >({
      query: (input) => ({
        document: LIST_INCOTERM_RELATIONS,
        variables: {
          input,
        },
      }),
      providesTags: [INCO_RS_LIST_TAG],
      transformResponse: (response: Partial<Query>, meta, arg) => {
        const res = response?.listIncotermRelations;
        const viewModel = res?.incotermRelations?.map((relation) => {
          return {
            ...relation,
            destinationCountryLabel: getOptionByValue(relation?.destinationCountry, COUNTRIES)?.label,
            decisionLabel: getOptionByValue(relation?.relationValue, DECISION_VALUES_ALL)?.label,
            productCategoryLabel: getOptionByValue(relation?.productCategory, PRODUCT_CATEGORIES)?.label,
            programTypeLabel: getOptionByValue(relation?.programType, PROGRAM_TYPES)?.label ?? 'All',
          };
        });
        return { nextToken: res?.nextToken, incotermRelations: viewModel };
      },
    }),
    updateIncotermRelation: builder.mutation<Mutation, UpdateIncotermRelationInput>({
      query: (input) => ({
        document: UPDATE_INCOTERM_RELATION,
        variables: {
          input,
        },
      }),
      invalidatesTags: [INCO_RS_GET_TAG, INCO_RS_LIST_TAG],
    }),
    deleteIncotermRelation: builder.mutation<Mutation, DeleteIncotermRelationInput>({
      query: (input) => ({
        document: DELETE_INCOTERM_RELATION,
        variables: {
          input,
        },
      }),
      invalidatesTags: [INCO_RS_GET_TAG, INCO_RS_LIST_TAG],
    }),
  }),
});

export const {
  /** TRS */
  useListTradeRouteSchedulesQuery,
  useCreateTradeRouteScheduleMutation,
  useUpdateTradeRouteScheduleMutation,
  useGetTradeRouteAuditHistoryQuery,
  /** Assign License */
  useAssignLicenseMutation,
  /** CI Review */
  useUpdateCIReviewMutation,
  /* Search Vendors */
  useSearchVendorsQuery,
  useLazySearchVendorsQuery,
  /* IncoRS */
  useGetIncotermRelationQuery,
  useListIncotermRelationsQuery,
  useLazyListIncotermRelationsQuery,
  useCreateIncotermRelationMutation,
  useUpdateIncotermRelationMutation,
  useDeleteIncotermRelationMutation,
} = apiSlice;
