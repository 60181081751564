import { Auth } from '@aws-amplify/auth';
import getApplicationSettings from './settingsConfig';
import { initRUM } from './rumConfig';
import { ApplicationSettings } from 'types/config';
import { AwsRum } from 'aws-rum-web';

/**
 * Initialize the application settings and configure amplify for Cognito authentication. Init CW RUM after coming back from auth.
 * @return {ApplicationSettings | undefined} app settings object
 * @return {AwsRum | undefined} RUM client
 */
export async function appInit(): Promise<{
  settings: ApplicationSettings | undefined;
  cwr: AwsRum | undefined;
}> {
  const settings = await getApplicationSettings();
  let cwr: AwsRum | undefined;
  const { rumGuestRoleArn, rumIdentityPoolId, rumAppMonitorId, cognito, stage } = settings;
  const domainStage = stage === 'local' ? 'beta' : stage;
  try {
    Auth.configure({
      Auth: {
        userPoolId: cognito?.userPoolId,
        userPoolWebClientId: cognito?.userPoolWebClientId,
      },
      oauth: {
        domain: `cip-bff-${domainStage}.auth.us-west-2.amazoncognito.com`,
        scope: ['openid'],
        responseType: 'code',
        redirectSignIn: settings.appUrl,
        redirectSignOut: settings.appUrl,
      },
    });
  } catch (error) {
    console.error(error);
  } finally {
    // initialize RUM even if something happens in authentication, initialize after auth to prevent a race condition on redirect to Midway
    cwr = initRUM(rumAppMonitorId, {
      identityPoolId: rumIdentityPoolId,
      guestRoleArn: rumGuestRoleArn,
    });
  }

  return { settings, cwr };
}
