import { AppLayout, BreadcrumbGroup, Flashbar, Header } from '@amzn/awsui-components-react';
import { CIPSideNavigation } from 'components/SideNavigation/SideNavigation';
import { APP_LAYOUT_LABELS, HEADER_SELECTOR } from 'config/navConfig';
import { useDispatch } from 'react-redux';
import * as z from 'zod';
import { setAssignLicenseFlashbarItems } from 'store/assignLicense';
import { useAppSelector } from 'store/store';
import { flashbarItemsCreator, optionDefinitionSchema } from 'utils/formUtils';
import AssignLicense from './AssignLicense';
import { LicenseCategory, LicenseType } from '@amzn/cip-bff-schema';
import { ZOD_NONEMPTY_STRING } from '@amzn/gtpccipstatic-config/dist/constants';

export const assignLicenseFormSchema = z.object({
  transactionId: ZOD_NONEMPTY_STRING,
  partId: ZOD_NONEMPTY_STRING,
  licenseData: z
    .object({
      licenseType: optionDefinitionSchema,
      licenseCategory: optionDefinitionSchema,
      licenseValue: z.string(),
    })
    .superRefine((data, ctx) => {
      // To prevent user from putting License UUID (36 characters) as License Exception, we set the limit to 32
      if (data?.licenseType?.value === 'LICENSE_EXCEPTION' && data?.licenseValue?.length > 32) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['licenseValue'],
          message:
            'License Exception value has a length limit of 32 characters. Please avoid putting License ID for this license type.',
        });
      }
    }),
});

export type AssignLicenseFormInputs = z.infer<typeof assignLicenseFormSchema>;

export const formSchemaToApiSchema = assignLicenseFormSchema.transform((la) => {
  // LICENSE_ID type will be sent to backend as LICENSE_NUMBER and handled as licenseId for LM integration
  const licenseType =
    la.licenseData.licenseType.value === 'LICENSE_ID' ? 'LICENSE_NUMBER' : la.licenseData.licenseType.value;
  return {
    ...la,
    licenseData: {
      ...la.licenseData,
      licenseType: licenseType as LicenseType,
      licenseCategory: la.licenseData.licenseCategory.value as LicenseCategory,
    },
  };
});

const AssignLicensePage: React.FC = () => {
  const dispatch = useDispatch();
  const { assignLicenseFlashbarItems } = useAppSelector((state) => state.assignLicense);

  return (
    <AppLayout
      maxContentWidth={800}
      headerSelector={HEADER_SELECTOR}
      toolsHide
      contentHeader={
        <Header variant="h1" description="Temporary form to assign an authorization to a transaction and part">
          Authorization Assignment
        </Header>
      }
      stickyNotifications
      notifications={
        <Flashbar items={flashbarItemsCreator(assignLicenseFlashbarItems, dispatch, setAssignLicenseFlashbarItems)} />
      }
      navigation={<CIPSideNavigation />}
      breadcrumbs={
        <BreadcrumbGroup
          items={[
            { text: 'Compliance Integration', href: '/' },
            { text: 'Assign Authorization', href: '#' },
          ]}
        />
      }
      content={<AssignLicense />}
      ariaLabels={APP_LAYOUT_LABELS}
    />
  );
};

export default AssignLicensePage;
