import { useListTradeRouteSchedulesQuery } from 'api/baseApi';
import { RouteSchedule, TransactionType } from '@amzn/cip-bff-schema';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { setTRSLandingFlashbarItems } from 'store/trs';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';

const useValidateScheduleParams = (
  scheduleId: string,
  fromCountry: string,
  toCountry: string,
  transactionType: TransactionType
): { schedule: RouteSchedule | undefined; isCurrentSchedule: boolean; isLoading: boolean } => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [matchingScheduleState, setMatchingSchedule] = useState<RouteSchedule | undefined>();
  const [isCurrentSchedule, setIsCurrentSchedule] = useState<boolean>(false);
  const hasRequiredFields = scheduleId && transactionType && fromCountry && toCountry;
  const { data, isSuccess, isLoading } = useListTradeRouteSchedulesQuery(
    {
      fromCountry: fromCountry,
      toCountry: toCountry,
      transactionType: transactionType as TransactionType,
    },
    { skip: !hasRequiredFields }
  );

  useEffect(() => {
    if (!hasRequiredFields) {
      dispatch(
        setTRSLandingFlashbarItems([
          {
            header: 'Error',
            type: 'error',
            content: `Please provide From Country, To Country, Transaction Type, and Schedule ID in order to view schedule details.`,
            dismissId: uuid(),
          },
        ])
      );
      navigate('/trade-routes');
    } else if (isSuccess) {
      const matchingSchedule = data?.routeSchedules?.schedules.find((s: RouteSchedule) => s.scheduleId === scheduleId);
      setMatchingSchedule(matchingSchedule);
      if (data?.routeSchedules?.currentScheduleId && matchingSchedule?.scheduleId)
        setIsCurrentSchedule(data?.routeSchedules?.currentScheduleId === matchingSchedule?.scheduleId);

      if (!matchingSchedule) {
        dispatch(
          setTRSLandingFlashbarItems([
            {
              header: 'Error',
              type: 'error',
              content: `No schedule found in trade route ${transactionType} from ${fromCountry} to ${toCountry} with schedule ID ${scheduleId}`,
              dismissId: uuid(),
            },
          ])
        );
        const baseQueryParams = `tt=${transactionType}&fc=${fromCountry}&tc=${toCountry}`;
        navigate(`/trade-routes?${baseQueryParams}`);
      }
    }
  }, [
    scheduleId,
    data?.routeSchedules?.schedules,
    hasRequiredFields,
    navigate,
    isSuccess,
    transactionType,
    fromCountry,
    toCountry,
    dispatch,
    data?.routeSchedules?.currentScheduleId,
  ]);

  return { schedule: matchingScheduleState, isCurrentSchedule, isLoading };
};

export default useValidateScheduleParams;
