import { DECISION_VALUES_ALL, PRODUCT_CATEGORIES } from 'config/incotermConstants';
import { FilteringProps } from 'types/components';
import { IncotermRelation, Maybe } from '@amzn/cip-bff-schema';
import { getOptionByValue } from 'utils/formUtils';
import { TableProps } from '@amzn/awsui-components-react';
import { DATE_TIME_PATTERN_SHORT } from 'utils/dateTimeUtils';
import { format } from 'date-fns';

const country: TableProps.ColumnDefinition<Maybe<IncotermRelation>>[] = [
  {
    id: 'destinationCountryName',
    header: 'Destination Country',
    cell: (item) => (item?.destinationCountryName ? item?.destinationCountryName : '-'),
  },
  {
    id: 'destinationCountry',
    header: 'Country ISO',
    cell: (item) => (item?.destinationCountry ? item?.destinationCountry : '-'),
  },
];

const supplier: TableProps.ColumnDefinition<Maybe<IncotermRelation>>[] = [
  {
    id: 'supplierName',
    header: 'Supplier',
    cell: (item) => (item?.supplierName && item?.supplier !== 'dismissed' ? item?.supplierName : '-'),
  },
  {
    id: 'supplier',
    header: 'Supplier ID',
    cell: (item) => (item?.supplier && item?.supplier !== 'dismissed' ? item?.supplier : '-'),
  },
];

const value: TableProps.ColumnDefinition<Maybe<IncotermRelation>> = {
  id: 'relationValue',
  header: 'Decision',
  cell: (item) => (item?.relationValue ? getOptionByValue(item?.relationValue, DECISION_VALUES_ALL)?.label : '-'),
};

const metadata: TableProps.ColumnDefinition<Maybe<IncotermRelation>>[] = [
  {
    id: 'modifiedBy',
    header: 'Modified By',
    cell: (item) => (item?.modifiedBy ? item?.modifiedBy : '-'),
  },
  {
    id: 'modifiedAt',
    header: 'Modified At',
    cell: (item) => (item?.modifiedAt ? format(item?.modifiedAt, DATE_TIME_PATTERN_SHORT) : '-'),
  },
];

const importFields: TableProps.ColumnDefinition<Maybe<IncotermRelation>>[] = [
  {
    id: 'awsHasImportCapability',
    header: 'AWS Import',
    // != used for null and undefined
    cell: (item) => (item?.awsHasImportCapability != null ? (item?.supplierHasImportCapability ? 'Yes' : 'No') : '-'),
  },
  {
    id: 'supplierHasImportCapability',
    header: 'Supplier Import',
    // != used for null and undefined
    cell: (item) =>
      item?.supplierHasImportCapability != null ? (item?.supplierHasImportCapability ? 'Yes' : 'No') : '-',
  },
];

export const columnDefsFreight: TableProps.ColumnDefinition<Maybe<IncotermRelation>>[] = [
  ...supplier,
  ...country,
  {
    id: 'productCategory',
    header: 'Product Category',
    cell: (item) => (item?.productCategory ? getOptionByValue(item?.productCategory, PRODUCT_CATEGORIES)?.label : '-'),
  },
  value,
  ...importFields,
  ...metadata,
];

export const getColumnDefsIOR = (): TableProps.ColumnDefinition<Maybe<IncotermRelation>>[] => {
  const columnDefsIOR: TableProps.ColumnDefinition<Maybe<IncotermRelation>>[] = [
    ...supplier,
    ...country,
    {
      id: 'orderingEntityName',
      header: 'Ordering Entity',
      cell: (item) => (item?.orderingEntityName ? item?.orderingEntityName : '-'),
    },
    {
      id: 'orderingEntity',
      header: 'Ordering Entity ID',
      cell: (item) => (item?.orderingEntity ? item?.orderingEntity : '-'),
    },
    {
      id: 'programType',
      header: 'Program Type',
      cell: (item) => (item?.programType ? item?.programType : 'All'),
    },
    value,
    ...importFields,
    ...metadata,
  ];
  return columnDefsIOR;
};

export const VISIBLE_COLUMNS = [
  'destinationCountryName',
  'programType',
  'orderingEntity',
  'orderingEntityName',
  'productCategory',
  'relationValue',
  'supplier',
  'supplierName',
  'destinationCountry',
  'modifiedBy',
  'modifiedAt',
  'awsHasImportCapability',
  'supplierHasImportCapability',
];

export const FILTERING_PROPERTIES: FilteringProps = [
  {
    key: 'destinationCountryLabel',
    operators: [{ operator: '=' }],
    propertyLabel: 'Destination Country',
    groupValuesLabel: 'Destination country values',
  },
  {
    key: 'destinationCountry',
    operators: [{ operator: '=' }],
    propertyLabel: 'Destination Country ISO',
    groupValuesLabel: 'Destination country ISO',
  },
  {
    key: 'supplierName',
    operators: [{ operator: '=' }],
    propertyLabel: 'Supplier',
    groupValuesLabel: 'Supplier names',
  },
  {
    key: 'supplier',
    operators: [{ operator: '=' }],
    propertyLabel: 'Supplier Value',
    groupValuesLabel: 'Supplier values',
  },
  {
    key: 'decisionLabel',
    operators: [
      {
        operator: '=',
      },
    ],
    propertyLabel: 'Decision',
    groupValuesLabel: 'Decision values',
  },
];

const PRODUCT_CATEGORY_FP: FilteringProps = [
  {
    key: 'productCategoryLabel',
    operators: [{ operator: '=', format: (value) => getOptionByValue(value, PRODUCT_CATEGORIES)?.label ?? value }],
    propertyLabel: 'Product Category',
    groupValuesLabel: 'Product category values',
  },
];

const PROGRAM_TYPE_FP: FilteringProps = [
  {
    key: 'programTypeLabel',
    operators: [{ operator: '=' }],
    propertyLabel: 'Program Type',
    groupValuesLabel: 'Program type values',
  },
];

const ORDERING_ENTITY_FP: FilteringProps = [
  {
    key: 'orderingEntityName',
    operators: [{ operator: '=' }],
    propertyLabel: 'Ordering Entity',
    groupValuesLabel: 'Ordering entity names',
  },
  {
    key: 'orderingEntity',
    operators: [{ operator: '=' }],
    propertyLabel: 'Ordering Entity ID',
    groupValuesLabel: 'Ordering entity values',
  },
];

const METADATA_FP: FilteringProps = [
  // TODO: add modifiedAt as a filtering property
  // {
  //   key: 'modifiedAt',
  //   // operators: ['<', '<=', '>', '>='].map((operator) => ({
  //   //   operator,
  //   //   // form: DateTimeForm,
  //   //   // format: formatDateTime,
  //   //   match: 'datetime',
  //   // })),
  //   propertyLabel: 'Modified At',
  //   groupValuesLabel: 'Modified At values',
  // },
  {
    key: 'modifiedBy',
    operators: [
      {
        operator: '=',
      },
    ],
    propertyLabel: 'Modified By',
    groupValuesLabel: 'Enter a valid modified by value',
  },
];

export const IOR_FILTERING_PROPERTIES = [
  ...FILTERING_PROPERTIES,
  ...ORDERING_ENTITY_FP,
  ...PROGRAM_TYPE_FP,
  ...METADATA_FP,
];
export const FREIGHT_FILTERING_PROPERTIES = [...FILTERING_PROPERTIES, ...PRODUCT_CATEGORY_FP, ...METADATA_FP];
