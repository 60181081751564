import { AwsRum } from 'aws-rum-web';
import React from 'react';
import GenericError from '../GenericError';

type ErrorBoundaryProps = { cwr?: AwsRum | undefined };
type ErrorBoundaryState = { hasError: boolean };

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error): void {
    this.setState({ hasError: true });
    if (this.props.cwr) {
      this.props.cwr?.recordError(error);
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  render() {
    if (this.state.hasError) {
      return <GenericError />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
