import { Box, ColumnLayout, Container, Link } from '@amzn/awsui-components-react';
import errorImg from 'assets/icons/error_page_img.png';

const ERROR_REPORT_TEMPLATE = 'https://t.corp.amazon.com/create/templates/26b42824-c649-4153-95ad-dc96460385ab';

const GenericError: React.FC = () => {
  return (
    <Container>
      <ColumnLayout columns={3}>
        <Box variant="div" />
        <Box variant="div" textAlign="left">
          <img alt="Error" src={errorImg} height={150} />
          <Box variant="h1">Error</Box>
          <Box variant="p">
            Oops, an unrecoverable error has occured! We have recorded this failure and will ensure it is addressed
            soon. To aid in this process, please cut a ticket to the GTPC Tech team using
            <Link href={ERROR_REPORT_TEMPLATE} external>
              &nbsp;this ticket template
            </Link>
            .
          </Box>
          <Box variant="p">
            <Link href="/">Return home</Link>
          </Box>
        </Box>
        <Box variant="div" />
      </ColumnLayout>
    </Container>
  );
};

export default GenericError;
