import { gql } from 'graphql-request';

export const LIST_TRS = gql`
  query RouteSchedules($input: RouteSchedulesInput!) {
    routeSchedules(input: $input) {
      schedules {
        scheduleId
        scheduleStatus
        fromCountry
        toCountry
        transactionType
        effectiveDate
        endDate
        usedEquipmentAllowed
        routeStatus
        vatInvoiceRequired
        commercialInvoiceRequired
        manualTradeActionRequired
        vatStatement
        ciStatement
        ior
        eor
        ueeeNeeded
        packingListRequired
        cooRequired
        incoterms
        taxInfo {
          taxRateStatus
          legalEntityBasedTaxRate {
            sameLegalEntityTaxDocumentNotRequired
            sameLegalEntityTaxRatePercentage
            differentLegalEntitiesTaxRatePercentage
          }
          genericTaxRatePercentage
          taxComments
        }
      }
      lastEvaluatedToken
      currentScheduleId
    }
  }
`;

export const AUDIT_HISTORY_TRS = gql`
  query RouteAuditHistory($input: RouteAuditHistoryInput!) {
    routeAuditHistory(input: $input) {
      auditHistory {
        scheduleId
        modifiedAt
        modifiedBy
        oldData
        newData
        changeNotes
      }
      lastEvaluatedToken
    }
  }
`;

/* INCOTERMS */

export const SEARCH_VENDORS = gql`
  query SearchVendors($input: SearchVendorsInput!) {
    searchVendors(input: $input) {
      vendorEntitySummaryList {
        id
        name
        friendlyName
        addressId
        classId
        parentVendorEntityId
        lifeCycleGraphId
        currentState
        website
        parentCompany
        feinNumber
        ebrNumber
        isnNumber
        leiNumber
        dunsNumber
        cageNumber
        governanceIds
        tradeNames
        alternateNames
        otherAddressIds
        isFinancialAccountOnboarded
        isPhysicalSiteOnboarded
      }
      nextToken
    }
  }
`;

export const GET_INCOTERM_RELATION = gql`
  query GetIncotermRelation($input: GetIncotermRelationInput!) {
    getIncotermRelation(input: $input) {
      identifierType
      relationType
      supplier
      supplierName
      supplierHasImportCapability
      awsHasImportCapability
      programType
      productCategory
      destinationCountry
      destinationCountryName
      orderingEntity
      orderingEntityName
      relationValue
      changeNotes
      modifiedBy
      modifiedAt
      version
    }
  }
`;

export const LIST_INCOTERM_RELATIONS = gql`
  query ListIncotermRelations($input: ListIncotermRelationsInput!) {
    listIncotermRelations(input: $input) {
      incotermRelations {
        identifierType
        relationType
        supplier
        supplierName
        supplierHasImportCapability
        awsHasImportCapability
        programType
        productCategory
        destinationCountry
        destinationCountryName
        orderingEntity
        orderingEntityName
        relationValue
        changeNotes
        modifiedBy
        modifiedAt
        version
      }
      nextToken
    }
  }
`;
