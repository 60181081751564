import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FlashbarItemsProps, SideNavState } from 'types';
import TradeRouteToolsContent from 'features/TradeRouteCommon/TradeRouteToolsContent';

interface TRSSideNavState extends SideNavState {
  toolsContentId: keyof typeof TradeRouteToolsContent;
}

export type TRSLocalState = {
  trsLandingFlashbarItems: FlashbarItemsProps[];
  trsCreateFlashbarItems: FlashbarItemsProps[];
  trsEditFlashbarItems: FlashbarItemsProps[];
  trsAuditFlashbarItems: FlashbarItemsProps[];
  trsSideNavState: TRSSideNavState;
};

const initialState: TRSLocalState = {
  trsLandingFlashbarItems: [],
  trsCreateFlashbarItems: [],
  trsEditFlashbarItems: [],
  trsAuditFlashbarItems: [],
  trsSideNavState: {
    toolsOpen: false,
    toolsContentId: 'ueee-info-link',
  },
};

/** TRS Slice */
const { reducer, actions } = createSlice({
  name: 'trs',
  initialState,
  reducers: {
    setTRSLandingFlashbarItems: (state, { payload }: PayloadAction<FlashbarItemsProps[]>) => {
      state.trsLandingFlashbarItems = payload;
    },
    setTRSCreateFlashbarItems: (state, { payload }: PayloadAction<FlashbarItemsProps[]>) => {
      state.trsCreateFlashbarItems = payload;
    },
    setTRSEditFlashbarItems: (state, { payload }: PayloadAction<FlashbarItemsProps[]>) => {
      state.trsEditFlashbarItems = payload;
    },
    setTRSAuditFlashbarItems: (state, { payload }: PayloadAction<FlashbarItemsProps[]>) => {
      state.trsAuditFlashbarItems = payload;
    },
    setTRSSideNavState: (state, { payload: sideNavState }: PayloadAction<TRSSideNavState>) => {
      state.trsSideNavState = sideNavState;
    },
  },
});

export default reducer;

export const {
  setTRSLandingFlashbarItems,
  setTRSCreateFlashbarItems,
  setTRSEditFlashbarItems,
  setTRSAuditFlashbarItems,
  setTRSSideNavState,
} = actions;
