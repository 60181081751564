import { PayloadAction, SerializedError, createAsyncThunk, createSlice, miniSerializeError } from '@reduxjs/toolkit';
import { Auth, CognitoUser } from '@aws-amplify/auth';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { LoadingState } from '../types/constants';
import { ICredentials } from '@aws-amplify/core';
import { ApplicationSettings } from 'types/config';
import { apiSlice } from 'api/baseApi';
import { rum } from 'config/rumConfig';

export type UserState = {
  token: string;
  alias: string;
  apiUrl: string;
  groupMembership: string[];
  userLoading: LoadingState;
  authError: SerializedError | null;
};

const initialState: UserState = {
  token: '',
  alias: '',
  apiUrl: '',
  groupMembership: [],
  userLoading: 'pending',
  authError: null,
};

const getCurrentUser = async (): Promise<CognitoUser | null> => {
  try {
    return await Auth.currentAuthenticatedUser();
  } catch {
    // currentAuthenticatedUser throws an Error if not signed in
    return null;
  }
};

const signIn = (): Promise<ICredentials> => Auth.federatedSignIn({ customProvider: 'Federate' });

/**
 * get the authenticated user
 * @param {boolean} isRefresh - if this getUser call is a token refresh or not
 * @returns {Object}
 */
export const getUser = createAsyncThunk<
  { alias: string; token: string; groupMembership: string[] },
  { isRefresh?: boolean }
>('user/getUser', async ({ isRefresh = false }, { dispatch }) => {
  const res = { alias: '', token: '', groupMembership: [] };
  const user = await getCurrentUser();
  if (!user) {
    await signIn();
  } else {
    user?.getSession((_: Error | null, session: CognitoUserSession) => {
      if (session) {
        const payload = session.getIdToken().decodePayload();
        res.token = session.getIdToken().getJwtToken();
        if (payload?.identities) res.alias = payload?.identities[0]?.userId;
        if (payload['custom:UserGroups']) res.groupMembership = payload['custom:UserGroups']?.split(',');
        if (isRefresh) {
          // invalidate cache so the previously empty unauthed responses can retry
          dispatch(apiSlice.util.resetApiState());
        }
      }
    });
  }
  return res;
});

/** User Slice */
const { reducer, actions } = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUserFailure: (state) => {
      state.userLoading = 'rejected';
    },
    setAppSyncURL: (state, { payload }: PayloadAction<ApplicationSettings | undefined>) => {
      if (payload) {
        const domainStage = payload?.stage === 'local' ? 'beta' : payload?.stage;
        state.apiUrl = `https://${payload?.region}.${domainStage}.cip-bff.api.gtpc-tech.aws.dev/graphql`;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.pending, (state) => {
      state.userLoading = 'pending';
    });
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getUser.fulfilled, (state, { payload }) => {
      const { alias, token, groupMembership } = payload;
      state.token = token;
      state.alias = alias;
      state.groupMembership = groupMembership;
      state.userLoading = 'fulfilled';
      rum?.addSessionAttributes({ alias });
    });
    builder.addCase(getUser.rejected, (state, { error }) => {
      state.authError = miniSerializeError(error);
      state.userLoading = 'rejected';
    });
  },
});

export default reducer;

export const { setAppSyncURL } = actions;
