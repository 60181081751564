import React from 'react';
import { DATE_TIME_PATTERN } from 'utils/dateTimeUtils';
import { format, isValid } from 'date-fns';
import { SpaceBetween } from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { Mode } from '@amzn/awsui-global-styles';

export type ChangeType = 'UPDATE' | 'CREATE' | 'DELETE';

interface ObjectDiffProps<T> {
  changeType?: ChangeType;
  oldField?: T;
  newField?: T;
  displayValueMapping?: OptionDefinition[];
  visualMode?: Mode;
}

const LIGHT_MODE_GREEN = '#f2f8f0';
const DARK_MODE_GREEN = '#172211';
const OUTLINE_GREEN = '#1d8102';

const LIGHT_MODE_RED = '#fdf3f1';
const DARK_MODE_RED = '#270a11';
const OUTLINE_RED = '#d13212';

const ObjectFieldDiff = <T extends object | string | number | boolean | Date | null>({
  changeType = 'UPDATE',
  oldField,
  newField,
  displayValueMapping,
  visualMode = Mode.Light,
}: ObjectDiffProps<T>): JSX.Element => {
  let oldFieldDisplay: OptionDefinition | undefined = { value: oldField?.toString(), label: oldField?.toString() };
  let newFieldDisplay: OptionDefinition | undefined = { value: newField?.toString(), label: newField?.toString() };
  if (newField instanceof Date && isValid(newField)) {
    newFieldDisplay.label = format(newField, DATE_TIME_PATTERN);
  }
  if (oldField instanceof Date && isValid(oldField)) {
    oldFieldDisplay.label = format(oldField, DATE_TIME_PATTERN);
  }
  if (displayValueMapping) {
    oldFieldDisplay = displayValueMapping.find((mapping) => mapping.value === oldField);
    newFieldDisplay = displayValueMapping.find((mapping) => mapping.value === newField);
  }

  const newFieldStyles = { backgroundColor: LIGHT_MODE_GREEN, outline: `1px solid ${OUTLINE_GREEN}` };
  const oldFieldStyles = { backgroundColor: LIGHT_MODE_RED, outline: `1px solid ${OUTLINE_RED}` };
  if (visualMode === Mode.Dark) {
    newFieldStyles.backgroundColor = DARK_MODE_GREEN;
    oldFieldStyles.backgroundColor = DARK_MODE_RED;
  }

  let changeComponent = <div>{newField ?? '-'}</div>;
  switch (changeType) {
    case 'CREATE':
      changeComponent = (
        <SpaceBetween size="xs">
          {newField ? <div style={newFieldStyles}>&nbsp;+ {newFieldDisplay?.label}</div> : '-'}
        </SpaceBetween>
      );
      break;
    case 'DELETE':
      changeComponent = (
        <SpaceBetween size="xs">
          {oldField ? <div style={oldFieldStyles}>&nbsp;- {oldFieldDisplay?.label}</div> : '-'}
        </SpaceBetween>
      );
      break;
    default:
      changeComponent =
        oldField === newField ? (
          changeComponent
        ) : (
          <SpaceBetween size="xs">
            {newField && <div style={newFieldStyles}>&nbsp;+ {newFieldDisplay?.label}</div>}
            {oldField && <div style={oldFieldStyles}>&nbsp;- {oldFieldDisplay?.label}</div>}
          </SpaceBetween>
        );
  }

  return changeComponent;
};

export default ObjectFieldDiff;
