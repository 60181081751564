import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { AppDispatch } from 'store/store';
import * as z from 'zod';
import * as yup from 'yup';
import { FlashbarItemsProps } from 'types';
import Link from '@amzn/awsui-components-react/polaris/link';

export const DEFAULT_REQUIRED_MSG = 'Required';

export const optionDefinitionSchema = z.object({
  value: z.string().optional(),
  label: z.string().optional(),
});

export const yupOptionDefinitionSchema = yup
  .object()
  .shape({
    value: yup.string().required(DEFAULT_REQUIRED_MSG),
    label: yup.string(),
    description: yup.string(),
    filteringTags: yup.array(yup.string().required()),
  })
  .default(undefined);

export const getOptionByValue = <T extends Record<string, unknown>>(
  value: string | undefined | null,
  options: T[]
): T | undefined => {
  return options.find((o) => o.value === value);
};

export const getOptionalLabel = (value: string | JSX.Element | undefined | null): JSX.Element => {
  return (
    <>
      {value} <i>- optional</i>
    </>
  );
};

/** We can't send un-serializable state to Redux for storage. Instead, use a map to get static content that needs to use JSX for formatting or links. */
export const APP_STATIC_NOTIFICATIONS = {
  unauthorized: (
    <>
      Sorry, you are not currently authorized to view that page. If you believe you should have access please fill
      out&nbsp;
      <Link
        color="inverted"
        external
        href="https://t.corp.amazon.com/create/templates/eaf75528-e626-4153-bb1c-4d4f456d2435"
      >
        this ticket template
      </Link>
      .
    </>
  ),
};

export const flashbarItemsCreator = (
  items: FlashbarItemsProps[],
  dispatch: AppDispatch,
  actionCreator: ActionCreatorWithPayload<FlashbarItemsProps[], string>
): FlashbarItemsProps[] => {
  return items.map((fbItem) => ({
    ...fbItem,
    content: fbItem?.staticContentId ? APP_STATIC_NOTIFICATIONS[fbItem?.staticContentId] : fbItem?.content,
    dismissible: true,
    dismissLabel: 'Dismiss',
    onDismiss: () => {
      dispatch(actionCreator(items.filter((fb) => fb.dismissId !== fbItem.dismissId)));
    },
  }));
};

export const DATE_PICKER_ARIA_LABELS = {
  todayAriaLabel: 'Today',
  nextMonthAriaLabel: 'Next Month',
  previousMonthAriaLabel: 'Previous Month',
};

export const areAllNullish = (...args: unknown[]): boolean => {
  const res = args.every((arg) => arg === null || arg === undefined);
  return res;
};

export const isOneNullish = (...args: unknown[]): boolean => {
  const res = args.some((arg) => arg === null || arg === undefined);
  return res;
};

/**
 * Map entries of an object and perform an action on each value
 * @param values object we are performing an operation on
 * @param fn callback function to define operation you want to perform on each value in object
 * @returns modified object
 */
export const mapObj = <K extends string, T, U>(
  values: Partial<Record<K, T>>,
  fn: (t: T | undefined) => U
): Partial<Record<K, U>> => {
  const result: Partial<Record<K, U>> = {};
  for (const key in values) {
    if (Object.prototype.hasOwnProperty.call(values, key)) {
      result[key] = fn(values[key]);
    }
  }
  return result;
};
