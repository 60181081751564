import axios from 'axios';
import { ApplicationSettings } from '../types/config';

const getApplicationSettings = async (): Promise<ApplicationSettings> => {
  try {
    const { data } = await axios.get('/settings.json');
    return data as ApplicationSettings;
  } catch (e) {
    console.error('No settings found in /public/settings');
    return {} as ApplicationSettings;
  }
};

export default getApplicationSettings;
