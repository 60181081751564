import { TableProps } from '@amzn/awsui-components-react';
import { Primitive } from 'react-hook-form';

export const makeBold = <T extends Exclude<Primitive, bigint | symbol>>(
  field: T,
  condition: boolean
): JSX.Element | T => {
  return condition ? <b style={{ fontWeight: '999' }}>{field}</b> : field;
};

export const getTableSelectionLabels = <T extends object | null>(selectionName?: string): TableProps.AriaLabels<T> => ({
  itemSelectionLabel: () => `Select row`,
  allItemsSelectionLabel: () => 'Select all',
  selectionGroupLabel: `${selectionName ?? 'Table'} selection`,
});

export const PROPERTY_FILTERING_I18N_CONSTANTS = {
  filteringAriaLabel: 'your choice',
  dismissAriaLabel: 'Dismiss',
  filteringPlaceholder: 'Filter',
  groupValuesText: 'Values',
  groupPropertiesText: 'Properties',
  operatorsText: 'Operators',
  operationAndText: 'and',
  operationOrText: 'or',
  operatorLessText: 'Less than',
  operatorLessOrEqualText: 'Less than or equal',
  operatorGreaterText: 'Greater than',
  operatorGreaterOrEqualText: 'Greater than or equal',
  operatorContainsText: 'Contains',
  operatorDoesNotContainText: 'Does not contain',
  operatorEqualsText: 'Equals',
  operatorDoesNotEqualText: 'Does not equal',
  editTokenHeader: 'Edit filter',
  propertyText: 'Property',
  operatorText: 'Operator',
  valueText: 'Value',
  cancelActionText: 'Cancel',
  applyActionText: 'Apply',
  allPropertiesLabel: 'All properties',
  tokenLimitShowMore: 'Show more',
  tokenLimitShowFewer: 'Show fewer',
  clearFiltersText: 'Clear filters',
  removeTokenButtonAriaLabel: () => 'Remove token',
  enteredTextLabel: (text: string) => `Use: "${text}"`,
};
