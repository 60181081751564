import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import IncotermToolsContent from 'features/IncotermCommon/IncotermToolsContent';
import { FlashbarItemsProps, SideNavState } from 'types';

interface IncotermSideNavState extends SideNavState {
  toolsContentId: keyof typeof IncotermToolsContent;
}

export type IncotermLocalState = {
  incoLandingFlashbarItems: FlashbarItemsProps[];
  incoCreateFlashbarItems: FlashbarItemsProps[];
  incoEditFlashbarItems: FlashbarItemsProps[];
  incoSideNavState: IncotermSideNavState;
};

export const initialState: IncotermLocalState = {
  incoLandingFlashbarItems: [],
  incoCreateFlashbarItems: [],
  incoEditFlashbarItems: [],
  incoSideNavState: {
    toolsOpen: false,
    toolsContentId: 'incoterms-info-link',
  },
};

/** IncoRS Slice */
const { reducer, actions } = createSlice({
  name: 'incoterms',
  initialState,
  reducers: {
    setIncoLandingFlashbarItems: (state, { payload }: PayloadAction<FlashbarItemsProps[]>) => {
      state.incoLandingFlashbarItems = payload;
    },
    setIncoCreateFlashbarItems: (state, { payload }: PayloadAction<FlashbarItemsProps[]>) => {
      state.incoCreateFlashbarItems = payload;
    },
    setIncoEditFlashbarItems: (state, { payload }: PayloadAction<FlashbarItemsProps[]>) => {
      state.incoEditFlashbarItems = payload;
    },
    setIncoSideNavState: (state, { payload: sideNavState }: PayloadAction<IncotermSideNavState>) => {
      state.incoSideNavState = sideNavState;
    },
  },
});

export default reducer;

export const {
  setIncoLandingFlashbarItems,
  setIncoCreateFlashbarItems,
  setIncoEditFlashbarItems,
  setIncoSideNavState,
} = actions;
