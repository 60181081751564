import { useGetIncotermRelationQuery } from 'api/baseApi';
import { IdentifierType, IncotermRelation, RelationType } from '@amzn/cip-bff-schema';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { setIncoLandingFlashbarItems } from 'store/incoterms';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';
import useQueryParams from 'hooks/useQueryParams';
import { SUPPLIER_DISMISSED } from 'config/incotermConstants';

const useValidateIncoRelationParams = (
  relationType: RelationType
): { relation: IncotermRelation | undefined; isLoading: boolean } => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [countryParam] = useQueryParams<string>('dc');
  const [idTypeParam] = useQueryParams<IdentifierType>('idType');
  const [pcParam] = useQueryParams<string>('pc');
  const [supplierParam] = useQueryParams<string>('supplier');
  const [orderingEntityParam] = useQueryParams<string>('oe');
  const [programTypeParam] = useQueryParams<string>('pt');

  const hasRequiredFields = relationType && countryParam && idTypeParam;
  const { data, isLoading, isSuccess, isError } = useGetIncotermRelationQuery(
    {
      identifierType: idTypeParam,
      input: {
        productCategory: pcParam ?? undefined,
        supplier: supplierParam ?? SUPPLIER_DISMISSED,
        destinationCountry: countryParam ?? undefined,
        orderingEntity: orderingEntityParam ?? undefined,
        programType: programTypeParam && relationType === RelationType.IOR ? programTypeParam : undefined,
      },
      relationType,
    },
    { skip: !hasRequiredFields }
  );
  const relationSingle = data?.getIncotermRelation[0];

  useEffect(() => {
    if (!hasRequiredFields) {
      dispatch(
        setIncoLandingFlashbarItems([
          {
            header: 'Error',
            type: 'error',
            content: `Please provide required incoterm relation parameters in order to view relation details.`,
            dismissId: uuid(),
          },
        ])
      );
      navigate(`/incoterms/${relationType.toLowerCase()}`);
    }
    if (isError || (isSuccess && data?.getIncotermRelation.length === 0)) {
      dispatch(
        setIncoLandingFlashbarItems([
          {
            header: 'Error',
            type: 'error',
            content: `No incoterm relation found with provided parameters.`,
            dismissId: uuid(),
          },
        ])
      );
      navigate(`/incoterms/${relationType.toLowerCase()}`);
    }
  }, [hasRequiredFields, navigate, dispatch, relationType, isSuccess, data?.getIncotermRelation.length, isError]);

  return { relation: relationSingle, isLoading };
};

export default useValidateIncoRelationParams;
