import { ColumnLayout, Container, Header, SpaceBetween } from '@amzn/awsui-components-react';
import ValueWithLabel from 'components/ValueWithLabel';
import { getOptionByValue } from 'utils/formUtils';
import { COUNTRIES, TRANSACTION_TYPES } from '@amzn/gtpccipstatic-config/dist/constants';
import TRSCommonForm, { formSchemaToApiSchema } from 'features/TradeRouteCommon';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { TradeRouteScheduleFormInputs } from '../TradeRouteCommon';
import { useCreateTradeRouteScheduleMutation } from 'api/baseApi';
import { Mutation, TransactionType } from '@amzn/cip-bff-schema';
import { useDispatch } from 'react-redux';
import { setTRSCreateFlashbarItems, setTRSLandingFlashbarItems } from 'store/trs';
import { v4 as uuid } from 'uuid';

const TRSCreatePage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const [createSchedule, { isLoading }] = useCreateTradeRouteScheduleMutation();

  const transactionType = getOptionByValue(queryParams.get('tt'), TRANSACTION_TYPES);
  const fromCountry = getOptionByValue(queryParams.get('fc'), COUNTRIES);
  const toCountry = getOptionByValue(queryParams.get('tc'), COUNTRIES);
  const hasRequiredFields = transactionType && fromCountry && toCountry;

  useEffect(() => {
    if (!hasRequiredFields) {
      navigate('/trade-routes');
    }
  }, [hasRequiredFields, navigate]);

  const onSubmit: SubmitHandler<TradeRouteScheduleFormInputs> = async (data) => {
    const apiSchemaRouteSchedule = formSchemaToApiSchema.parse(data);
    const res = await createSchedule({
      fromCountry: fromCountry?.value as string,
      toCountry: toCountry?.value as string,
      transactionType: (transactionType?.value as TransactionType) ?? '',
      ...apiSchemaRouteSchedule,
    });

    if ((res as { data: Mutation })?.data) {
      dispatch(
        setTRSLandingFlashbarItems([
          {
            header: 'Schedule Created Successfully',
            type: 'success',
            content: `The trade route schedule has been created successfully.`,
            dismissId: uuid(),
          },
        ])
      );
      navigate(`/trade-routes?${queryParams.toString()}`);
    } else {
      dispatch(
        setTRSCreateFlashbarItems([
          {
            header: 'Error',
            type: 'error',
            content: `There was an error creating the trade route schedule.`,
            dismissId: uuid(),
          },
        ])
      );
    }
  };

  const scheduleInfoHeader = <Header>Schedule Info</Header>;
  const scheduleInfo = (
    <Container header={scheduleInfoHeader}>
      <ColumnLayout columns={2} variant="text-grid">
        <SpaceBetween size="l">
          <ValueWithLabel label="From Country">{fromCountry?.label ?? '-'}</ValueWithLabel>
          <ValueWithLabel label="To Country">{toCountry?.label ?? '-'}</ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="l">
          <ValueWithLabel label="Transaction Type">{transactionType?.label ?? '-'}</ValueWithLabel>
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  );

  const scheduleCreate = (
    <TRSCommonForm
      isLoading={isLoading}
      onSubmit={onSubmit}
      formHeaderText="Schedule Create"
      submitText="Submit"
      cancelRoute={`/trade-routes?${queryParams.toString()}`}
      defaultValues={{ commercialInvoiceRequired: fromCountry?.value !== toCountry?.value }}
    />
  );

  return (
    <SpaceBetween size="l">
      {scheduleInfo}
      {scheduleCreate}
    </SpaceBetween>
  );
};

export default TRSCreatePage;
