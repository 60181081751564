import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Mode } from '@amzn/awsui-global-styles';
import { FlashbarItemsProps } from 'types';

export type ApplicationConfigState = {
  visualMode: Mode;
  appNotifications: FlashbarItemsProps[];
  stage?: string;
};

export const initialState: ApplicationConfigState = {
  visualMode: Mode.Light,
  appNotifications: [],
  stage: undefined,
};

/** Config Slice */
const { reducer, actions } = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setStage: (state, { payload: stage }: PayloadAction<string | undefined>) => {
      state.stage = stage;
    },
    setVisualMode: (state, { payload: mode }: PayloadAction<Mode>) => {
      state.visualMode = mode;
    },
    setAppNotifications: (state, { payload: appNotifications }: PayloadAction<FlashbarItemsProps[]>) => {
      state.appNotifications = appNotifications;
    },
  },
});

export default reducer;

export const { setVisualMode, setAppNotifications, setStage } = actions;
