import { useEffect, useState } from 'react';
import { appInit } from 'config';
import { ApplicationSettings } from 'types/config';
import { useDispatch } from 'react-redux';
import { setAppSyncURL } from 'store/userSlice';
import { AwsRum } from 'aws-rum-web';
import { setStage } from 'store/configSlice';

const useAppInit = (): { initialized: boolean; appSettings?: ApplicationSettings; cwr: AwsRum | undefined } => {
  const dispatch = useDispatch();
  const [initialized, setInit] = useState(false);
  const [appSettings, setAppSettings] = useState<ApplicationSettings | undefined>({} as ApplicationSettings);
  const [cwr, setCwr] = useState<AwsRum | undefined>();
  useEffect(() => {
    appInit().then((init) => {
      setAppSettings(init.settings);
      setCwr(init.cwr);
      setInit(true);
      dispatch(setAppSyncURL(init.settings));
      dispatch(setStage(init.settings?.stage));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { initialized, appSettings, cwr };
};

export default useAppInit;
