import { AppLayout, BreadcrumbGroup, Flashbar, Header, TableProps } from '@amzn/awsui-components-react';
import StatusIndicator from '@amzn/awsui-components-react/polaris/status-indicator';
import { CIPSideNavigation } from 'components/SideNavigation/SideNavigation';
import { APP_LAYOUT_LABELS, HEADER_SELECTOR } from 'config/navConfig';
import TRSLandingPageContent from './TRSLandingPage';
import { makeBold } from 'utils/tableUtils';
import { Maybe, RouteSchedule } from '@amzn/cip-bff-schema';
import { formatFromEpoch } from 'utils/dateTimeUtils';
import { useAppSelector } from 'store/store';
import { useDispatch } from 'react-redux';
import { setTRSLandingFlashbarItems } from 'store/trs';
import { flashbarItemsCreator } from 'utils/formUtils';

export const getColumnDefs = (currentScheduleId: string): TableProps.ColumnDefinition<Maybe<RouteSchedule>>[] => {
  const columnDefs: TableProps.ColumnDefinition<Maybe<RouteSchedule>>[] = [
    {
      id: 'scheduleId',
      header: 'Schedule ID',
      cell: (item) => makeBold(item?.scheduleId, currentScheduleId === item?.scheduleId),
      sortingField: 'scheduleId',
    },
    {
      id: 'effectiveDate',
      header: 'Effective Date',
      cell: (item) => makeBold(formatFromEpoch(item?.effectiveDate), currentScheduleId === item?.scheduleId),
      sortingField: 'effectiveDate',
    },
    {
      id: 'endDate',
      header: 'End Date',
      cell: (item) =>
        makeBold(item?.endDate ? formatFromEpoch(item?.endDate) : '-', currentScheduleId === item?.scheduleId),
      sortingField: 'endDate',
    },
    {
      id: 'status',
      header: 'Status',
      cell: (item) => (
        <StatusIndicator type={item?.scheduleStatus === 'INACTIVE' ? 'error' : 'success'}>
          {makeBold(item?.scheduleStatus, currentScheduleId === item?.scheduleId)}
        </StatusIndicator>
      ),
      sortingField: 'status',
    },
  ];
  return columnDefs;
};

const TRSLandingPage: React.FC = () => {
  const dispatch = useDispatch();
  const { trsLandingFlashbarItems } = useAppSelector((state) => state.trs);

  return (
    <AppLayout
      contentType="table"
      headerSelector={HEADER_SELECTOR}
      toolsHide
      navigation={<CIPSideNavigation />}
      contentHeader={
        <Header variant="h1" description="Manage trade routes and their corresponding schedules">
          Trade Route Management
        </Header>
      }
      notifications={
        <Flashbar items={flashbarItemsCreator(trsLandingFlashbarItems, dispatch, setTRSLandingFlashbarItems)} />
      }
      stickyNotifications
      breadcrumbs={
        <BreadcrumbGroup
          items={[
            { text: 'Compliance Integration', href: '/' },
            { text: 'Trade Routes', href: '/trade-routes' },
          ]}
        />
      }
      content={<TRSLandingPageContent />}
      ariaLabels={APP_LAYOUT_LABELS}
    />
  );
};

export default TRSLandingPage;
