import { AppLayout, BreadcrumbGroup, Flashbar, Header } from '@amzn/awsui-components-react';
import { CIPSideNavigation } from 'components/SideNavigation/SideNavigation';
import { APP_LAYOUT_LABELS, HEADER_SELECTOR } from 'config/navConfig';
import ToolsContent from 'features/TradeRouteCommon/TradeRouteToolsContent';
import { getTRSBaseQueryParams } from 'hooks/useQueryParams/useQueryParams';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from 'store/store';
import { setTRSCreateFlashbarItems, setTRSSideNavState } from 'store/trs';
import { flashbarItemsCreator } from 'utils/formUtils';
import TRSCreatePageContent from './TRSCreatePage';

const TRSCreatePage: React.FC = () => {
  const dispatch = useDispatch();
  const { trsCreateFlashbarItems, trsSideNavState } = useAppSelector((state) => state.trs);
  const { toolsOpen, toolsContentId } = trsSideNavState;

  const [queryParams] = useSearchParams();
  const baseQueryParams = getTRSBaseQueryParams(queryParams);

  return (
    <AppLayout
      contentType="form"
      headerSelector={HEADER_SELECTOR}
      navigation={<CIPSideNavigation />}
      contentHeader={<Header variant="h1">Create a Trade Route Schedule</Header>}
      breadcrumbs={
        <BreadcrumbGroup
          items={[
            { text: 'Compliance Integration', href: '/' },
            { text: 'Trade Routes', href: `/trade-routes?${baseQueryParams}` },
            { text: 'Create', href: '#' },
          ]}
        />
      }
      toolsHide={!toolsOpen}
      tools={ToolsContent[toolsContentId]}
      toolsOpen={toolsOpen}
      onToolsChange={({ detail }) => dispatch(setTRSSideNavState({ toolsContentId, toolsOpen: detail.open }))}
      content={<TRSCreatePageContent />}
      ariaLabels={APP_LAYOUT_LABELS}
      notifications={
        <Flashbar items={flashbarItemsCreator(trsCreateFlashbarItems, dispatch, setTRSCreateFlashbarItems)} />
      }
      stickyNotifications
    />
  );
};

export default TRSCreatePage;
