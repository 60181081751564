import { Box, Button, Header, Pagination, PropertyFilter, SpaceBetween, Table } from '@amzn/awsui-components-react';
import { Link } from 'react-router-dom';
import { getTableSelectionLabels } from 'utils/tableUtils';
import { INCO_RELATION_TYPE_DISPLAY, IncotermRelationLandingPageProps } from '.';
import { VISIBLE_COLUMNS, columnDefsFreight, getColumnDefsIOR } from './incotermTableConfig';
import { IncotermRelation, Maybe, RelationType } from '@amzn/cip-bff-schema';
import { useState } from 'react';
import useDeleteIncotermModal from 'hooks/useDeleteIncotermModal';
import { buildIncoRSQueryParams } from 'hooks/useQueryParams';
import useIncotermsServerFiltration from 'hooks/useServerFiltration';

const IncotermIORLandingPage: React.FC<IncotermRelationLandingPageProps> = ({ relationType }) => {
  const { longName: relationLongName, shortName: relationShortName } = INCO_RELATION_TYPE_DISPLAY[relationType];
  const routePath = relationShortName.toLowerCase();
  const [pageSize] = useState(20);
  const [selectedItems, setSelectedItems] = useState<Maybe<IncotermRelation>[]>([]);

  const {
    paginationProps,
    propertyFilteringProps,
    relations,
    isFetching,
    countText,
    upperLimitReached,
  } = useIncotermsServerFiltration({
    relationType,
    pageSize,
  });

  const headerTitle = `${relationLongName} Decisions`;

  const filter = <PropertyFilter {...propertyFilteringProps} />;

  const isOnlyOneSelected = selectedItems?.length === 1;
  const selectedItem = isOnlyOneSelected && selectedItems[0] ? selectedItems[0] : undefined;

  const [deleteModal, setDeleteModalOpen] = useDeleteIncotermModal(selectedItem);

  const scheduleTableHeader = (
    <Header
      counter={`(${countText ?? 0})`}
      actions={
        <SpaceBetween size="xs" direction="horizontal">
          <Link to={`/incoterms/${routePath}/edit?${buildIncoRSQueryParams(selectedItem)}`}>
            <Button disabled={!isOnlyOneSelected}>Edit</Button>
          </Link>
          <Button data-testid="incoterm-delete" disabled={!isOnlyOneSelected} onClick={() => setDeleteModalOpen(true)}>
            Delete
          </Button>
          <Link to={`/incoterms/${routePath}/create`}>
            <Button variant="primary">Create</Button>
          </Link>
        </SpaceBetween>
      }
      description={
        upperLimitReached ? (
          <Box color="text-status-error">
            Too many results loaded into the UI, please use filters to narrow your search
          </Box>
        ) : null
      }
    >
      {headerTitle}
    </Header>
  );

  return (
    <>
      {deleteModal}
      <Table
        visibleColumns={VISIBLE_COLUMNS}
        header={scheduleTableHeader}
        columnDefinitions={relationType === RelationType.IOR ? getColumnDefsIOR() : columnDefsFreight}
        items={relations}
        selectionType="single"
        ariaLabels={getTableSelectionLabels<Maybe<IncotermRelation>>('Incoterm')}
        loading={isFetching}
        pagination={<Pagination {...paginationProps} />}
        filter={filter}
        selectedItems={selectedItems}
        onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
        empty="No results"
      />
    </>
  );
};

export default IncotermIORLandingPage;
