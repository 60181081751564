import { useEffect, useState } from 'react';
import PolarisTopNavigation, { TopNavigationProps } from '@amzn/awsui-components-react/polaris/top-navigation';
import { Mode, applyMode } from '@amzn/awsui-global-styles';
import './TopNavigation.scss';

import awsLogo from 'assets/icons/ICON-AWS.svg';
import './TopNavigation.scss';
import 'assets/styles/global.scss';
import { useDispatch } from 'react-redux';
import { Flashbar } from '@amzn/awsui-components-react';
import { setAppNotifications, setVisualMode } from 'store/configSlice';
import { useAppSelector } from 'store/store';
import { flashbarItemsCreator } from 'utils/formUtils';

type VisualMode = {
  mode: Mode;
  modeToggleText: 'Dark' | 'Light';
};

const getPreferredVisualMode = (): VisualMode => {
  if (window.matchMedia) {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      return { mode: Mode.Dark, modeToggleText: 'Light' };
    }
    return { mode: Mode.Light, modeToggleText: 'Dark' };
  }
  return { mode: Mode.Light, modeToggleText: 'Dark' };
};

const TopNavigation: React.FC<{ utilities?: TopNavigationProps.Utility[] }> = ({ utilities = [] }) => {
  const visualMode = getPreferredVisualMode();
  const dispatch = useDispatch();
  const { appNotifications } = useAppSelector((state) => state.config);
  const [darkMode, setDarkMode] = useState(visualMode.mode === Mode.Dark);
  const [modeText, setModeText] = useState(visualMode.modeToggleText);

  useEffect(() => {
    applyMode(visualMode.mode);
    dispatch(setVisualMode(visualMode.mode));
    if (window.matchMedia) {
      const colorSchemeQuery = window.matchMedia('(prefers-color-scheme: dark)');
      colorSchemeQuery.addEventListener('change', () => {
        const visualModeChanged = getPreferredVisualMode();
        setModeText(visualModeChanged.modeToggleText);
        setDarkMode(visualModeChanged.mode === Mode.Dark);
        applyMode(visualModeChanged.mode);
      });
    }
  }, [dispatch, visualMode.mode]);

  const onDarkModeChange = (): void => {
    if (!darkMode) {
      dispatch(setVisualMode(Mode.Dark));
      applyMode(Mode.Dark);
      setModeText('Light');
    } else {
      dispatch(setVisualMode(Mode.Light));
      applyMode(Mode.Light);
      setModeText('Dark');
    }
    setDarkMode((old) => !old);
  };

  return (
    <div id="app-header" className="sticky-header">
      <PolarisTopNavigation
        identity={{
          title: 'Global Trade and Product Compliance',
          href: '/',
          logo: { src: awsLogo, alt: 'AWS' },
        }}
        utilities={[
          {
            type: 'button',
            text: `${modeText} Mode`,
            onClick: onDarkModeChange,
            ariaLabel: `${modeText} Mode`,
          },
          ...utilities,
        ]}
        i18nStrings={{ overflowMenuTriggerText: 'More', overflowMenuTitleText: 'Overflow Menu' }}
      />
      <Flashbar items={flashbarItemsCreator(appNotifications, dispatch, setAppNotifications)} />
    </div>
  );
};

export default TopNavigation;
