import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import userSlice from 'store/userSlice';
import configSlice from 'store/configSlice';
import trsSlice from 'store/trs';
import assignLicenseSlice from 'store/assignLicense';
import draftCISlice from 'store/draftCI';
import incotermsSlice from 'store/incoterms';
import { apiSlice } from 'api/baseApi';
import { websiteBucketApi } from 'api/s3BucketApi';

export const reducers = {
  [apiSlice.reducerPath]: apiSlice.reducer,
  [websiteBucketApi.reducerPath]: websiteBucketApi.reducer,
  trs: trsSlice,
  user: userSlice,
  config: configSlice,
  assignLicense: assignLicenseSlice,
  incoterms: incotermsSlice,
  draftCI: draftCISlice,
};

const store = configureStore({
  reducer: reducers,
  middleware: (gDM) => gDM().concat(apiSlice.middleware).concat(websiteBucketApi.middleware),
});
export type RootState = ReturnType<typeof store.getState>;
export type AppStore = typeof store;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
