import { IdentifierType, SearchVendorQueryConditions } from '@amzn/cip-bff-schema';
import { COUNTRIES } from '@amzn/gtpccipstatic-config/dist/constants';

export const LEGAL_ENTITIES_JSON_FILE_NAME = 'legalEntities.json';

// TODO: move all applicable incotermConstants to a static config
export const PROGRAM_TYPES = [
  { value: 'ALL', label: 'All', identifierType: IdentifierType.PURCHASE_ORDER_DEFAULT },
  { value: 'Buy-Sell DBS CVG', label: 'Buy-Sell DBS CVG', identifierType: IdentifierType.PURCHASE_ORDER_BUY_SELL },
  { value: 'Buy-Sell DBS DUB', label: 'Buy-Sell DBS DUB', identifierType: IdentifierType.PURCHASE_ORDER_BUY_SELL },
  { value: 'Buy-Sell DBS SIN', label: 'Buy-Sell DBS SIN', identifierType: IdentifierType.PURCHASE_ORDER_BUY_SELL },
  { value: 'Buy-Sell DBS SLC', label: 'Buy-Sell DBS SLC', identifierType: IdentifierType.PURCHASE_ORDER_BUY_SELL },
  { value: 'Buy-Sell Direct', label: 'Buy-Sell Direct', identifierType: IdentifierType.PURCHASE_ORDER_BUY_SELL },
  {
    value: 'Buy-Sell OEM-Direct',
    label: 'Buy-Sell OEM-Direct',
    identifierType: IdentifierType.PURCHASE_ORDER_BUY_SELL,
  },
  {
    value: 'Buy-Sell Strategic Inventory',
    label: 'Buy-Sell Strategic Inventory',
    identifierType: IdentifierType.PURCHASE_ORDER_BUY_SELL,
  },
  {
    value: 'Buy-Sell All Source Type',
    label: 'Buy-Sell All Source Type',
    identifierType: IdentifierType.PURCHASE_ORDER_BUY_SELL,
  },
  {
    value: 'Infrastructure: Sparetacus',
    label: 'Infrastructure: Sparetacus',
    identifierType: IdentifierType.PURCHASE_ORDER_INFRASTRUCTURE,
  },
];

export const PRODUCT_CATEGORIES = [
  { value: 'RACK', label: 'Rack' },
  { value: 'LOOSE_GEAR', label: 'Loose Gear' },
];

export const DECISION_VALUES_FREIGHT = [
  { value: 'AWS', label: 'AWS' },
  { value: 'SUPPLIER', label: 'Supplier' },
];

export const DECISION_VALUES_ALL = [...DECISION_VALUES_FREIGHT, { value: 'AWS_3P', label: 'AWS/3P' }];

export const YES_NO_OPTIONAL = [
  { label: '-', value: undefined },
  { label: 'Yes', value: 'YES' },
  { label: 'No', value: 'NO' },
];

export const IDENTIFIER_TYPES = [
  { value: IdentifierType.PURCHASE_ORDER_DEFAULT, label: 'Purchase Order' },
  { value: IdentifierType.PURCHASE_ORDER_BUY_SELL, label: 'Buy/Sell' },
  { value: IdentifierType.PURCHASE_ORDER_INFRASTRUCTURE, label: 'Infrastructure' },
];

export const SUPPLIER_DISMISSED = 'dismissed';

export const LABEL_TO_VALUE_KEY_OPTIONS_MAP = {
  destinationCountryLabel: { key: 'destinationCountry', options: COUNTRIES },
  decisionLabel: { key: 'relationValue', options: DECISION_VALUES_ALL },
  productCategoryLabel: { key: 'productCategory', options: PRODUCT_CATEGORIES },
  programTypeLabel: { key: 'programType', options: PROGRAM_TYPES },
};

export const VALUE_KEY_OPTIONS_MAP = {
  destinationCountry: { key: 'destinationCountry', options: COUNTRIES },
};

export const staticSearchVendorFilters: SearchVendorQueryConditions = {
  currentState: {
    isOneOf: [
      'ON_BOARDED',
      'INFO_COLLECTION_IN_PROGRESS',
      'INFO_COLLECTION_COMPLETED',
      'DRAFT',
      'BLOCKED',
      'REVIEW_IN_PROGRESS',
      'APPROVED_FOR_REVIEW',
    ],
  },
  classId: {
    exactMatch: 'BusinessEntityClassType',
  },
};
