import React from 'react';
import { Box, Button, Modal, SpaceBetween } from '@amzn/awsui-components-react';

interface SubmitModalProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  header: React.ReactNode;
  submitText: string;
  onSubmit: () => void | Promise<void>;
  isLoading?: boolean;
}

const SubmitModal: React.FC<SubmitModalProps> = ({
  visible,
  setVisible,
  header,
  submitText,
  onSubmit,
  isLoading = false,
  children,
}) => {
  return (
    <Modal
      visible={visible}
      onDismiss={() => setVisible(false)}
      header={header}
      closeAriaLabel="Close dialog"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button data-testid="modal-cancel" variant="link" onClick={() => setVisible(false)} disabled={isLoading}>
              Cancel
            </Button>
            <Button
              data-testid="modal-submit"
              variant="primary"
              onClick={onSubmit}
              disabled={isLoading}
              loading={isLoading}
            >
              {submitText}
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      {children}
    </Modal>
  );
};

export default SubmitModal;
