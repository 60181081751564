import { SideNavigationProps, TopNavigationProps } from '@amzn/awsui-components-react';

export const headerUtilityItems: TopNavigationProps.Utility[] = [];

// replace items with our own version of items to allow for the additional groupAllowList property
export interface SideNavSection extends Omit<SideNavigationProps.Section, 'items'> {
  items: ReadonlyArray<SideNavProps>;
  groupAllowList?: string[];
  restrictedStages?: string[];
}

export type SideNavLink = SideNavigationProps.Link & {
  groupAllowList?: string[];
};

export type SideNavProps =
  | SideNavigationProps.Divider
  | SideNavLink
  | SideNavSection
  | SideNavigationProps.LinkGroup
  | SideNavigationProps.ExpandableLinkGroup;

export const ASSIGN_LICENSE_GROUPS = ['cip-assign-license-users'];
export const UEEE_WIKI_LINK =
  'https://w.amazon.com/bin/view/AWS_Global_Trade_Product_Compliance_GTPC/Program_Support/UEEE/';

export const sideNavItems: SideNavProps[] = [
  {
    type: 'section',
    text: 'Transfer Of Assets',
    defaultExpanded: true,
    items: [
      {
        type: 'link',
        text: 'Trade Routes',
        href: '/trade-routes',
        external: false,
      },
      {
        type: 'link',
        text: 'Authorization Assignment',
        href: '/assign-license',
        external: false,
        groupAllowList: ASSIGN_LICENSE_GROUPS,
      },
    ],
  },
  {
    type: 'section',
    text: 'Invoice Review',
    defaultExpanded: true,
    items: [
      {
        type: 'link',
        text: 'Commercial Invoice Review',
        href: '/draft-ci',
        external: false,
      },
    ],
  },
  {
    type: 'section',
    text: 'Incoterms',
    defaultExpanded: true,
    items: [
      {
        type: 'link',
        text: 'IOR Decisions',
        href: '/incoterms/ior',
        external: false,
      },
      {
        type: 'link',
        text: 'Freight Decisions',
        href: '/incoterms/freight',
        external: false,
      },
    ],
  },
  { type: 'divider' },
  {
    type: 'link',
    text: 'D2D',
    href: 'https://d2d.app.gtpc-tech.aws.dev/',
    external: true,
  },
  {
    type: 'link',
    text: 'GTPC Wiki',
    href: 'https://w.amazon.com/bin/view/AWS_Global_Trade_Product_Compliance_GTPC/',
    external: true,
  },
  {
    type: 'link',
    text: 'UEEE Wiki',
    href: UEEE_WIKI_LINK,
    external: true,
  },
  {
    type: 'link',
    text: 'GTPC Tech Wiki',
    href: 'https://w.amazon.com/bin/view/AWS_Global_Trade_Product_Compliance_GTPC/GTPC_Tech/',
    external: true,
  },
];

export const getSideNavItems = (): SideNavProps[] => sideNavItems;

export const APP_LAYOUT_LABELS = {
  notifications: 'Notifications',
  navigation: 'Navigation',
  navigationToggle: 'Toggle Side Navigation',
  navigationClose: 'Close Side Navigation',
  tools: 'Tools',
  toolsToggle: 'Toggle Tools',
  toolsClose: 'Close Tools',
};

export const HEADER_SELECTOR = '#app-header';

export const getRouteMappings = (): SideNavigationProps.Item[] => {
  const sections = sideNavItems.filter((item) => item.type === 'section');
  const internalLinks = [...sections, ...sideNavItems].filter((item) => item.type === 'link' && !item.external);
  return internalLinks;
};
