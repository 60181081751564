import { AwsRum, AwsRumConfig } from 'aws-rum-web';

export let rum: AwsRum;

const initRUM = (appId: string | undefined, props: AwsRumConfig): AwsRum | undefined => {
  try {
    if (!window.Cypress && process.env.NODE_ENV !== 'test') {
      const config: AwsRumConfig = {
        allowCookies: true,
        enableXRay: true,
        endpoint: 'https://dataplane.rum.us-west-2.amazonaws.com',
        telemetries: ['errors', 'performance', 'http'],
        guestRoleArn: props?.guestRoleArn,
        identityPoolId: props?.identityPoolId,
      };
      const APPLICATION_VERSION = '1.0.0';
      const APPLICATION_REGION = 'us-west-2';
      if (appId) rum = new AwsRum(appId, APPLICATION_VERSION, APPLICATION_REGION, config);
      else throw new Error('No RUM AppID');
    } else {
      console.info('In testing environment. Skipping RUM initialization...');
    }
  } catch (error) {
    console.error(error);
  }
  return rum;
};

export { initRUM };
