import { AppLayout, BreadcrumbGroup, Button, Header, SpaceBetween, Spinner } from '@amzn/awsui-components-react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { CIPSideNavigation } from 'components/SideNavigation/SideNavigation';
import { APP_LAYOUT_LABELS, HEADER_SELECTOR } from 'config/navConfig';
import TRSDetailPageContent from './TRSDetailPage';
import { RouteSchedule, TransactionType } from '@amzn/cip-bff-schema';
import useDeleteTRSScheduleModal from 'hooks/useDeleteTRSScheduleModal';
import useValidateScheduleParams from 'hooks/useValidateScheduleParams';
import { getTRSBaseQueryParams } from 'hooks/useQueryParams/useQueryParams';

const TRSDetailPage: React.FC = () => {
  const [queryParams] = useSearchParams();
  const { scheduleId: scheduleIdVal } = useParams<string>();
  const scheduleId = scheduleIdVal ?? '';
  const fromCountry = queryParams.get('fc') as string;
  const toCountry = queryParams.get('tc') as string;
  const transactionType = queryParams.get('tt') as TransactionType;

  const baseQueryParams = getTRSBaseQueryParams(queryParams);

  const { schedule, isCurrentSchedule } = useValidateScheduleParams(
    scheduleId,
    fromCountry,
    toCountry,
    transactionType
  );

  const [deleteModal, setDeleteModalOpen] = useDeleteTRSScheduleModal(schedule);

  return (
    <AppLayout
      contentType="table"
      headerSelector={HEADER_SELECTOR}
      toolsHide
      navigation={<CIPSideNavigation />}
      contentHeader={
        <Header
          variant="h1"
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Link to={`/trade-routes/history?${baseQueryParams}`}>
                <Button>Audit history</Button>
              </Link>
              <Link to={`/trade-routes/edit/${scheduleId}?${baseQueryParams}`}>
                <Button>Edit</Button>
              </Link>
              <Button data-testid="trs-delete" onClick={() => setDeleteModalOpen(true)}>
                Delete
              </Button>
            </SpaceBetween>
          }
        >
          Schedule Details
        </Header>
      }
      breadcrumbs={
        <BreadcrumbGroup
          items={[
            { text: 'Compliance Integration', href: '/' },
            { text: 'Trade Routes', href: `/trade-routes?${baseQueryParams}` },
            { text: 'Details', href: '#' },
            { text: scheduleId || '', href: '#' },
          ]}
        />
      }
      content={
        <>
          {deleteModal}
          {schedule ? (
            <TRSDetailPageContent schedule={schedule as RouteSchedule} isCurrentSchedule={isCurrentSchedule} />
          ) : (
            <Spinner />
          )}
        </>
      }
      ariaLabels={APP_LAYOUT_LABELS}
    />
  );
};

export default TRSDetailPage;
