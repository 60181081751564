import { ColumnLayout, Container, Header, SpaceBetween, Spinner } from '@amzn/awsui-components-react';
import ValueWithLabel from 'components/ValueWithLabel';
import { getOptionByValue } from 'utils/formUtils';
import { COUNTRIES, TRANSACTION_TYPES } from '@amzn/gtpccipstatic-config/dist/constants';
import TRSCommonForm from 'features/TradeRouteCommon';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';
import { TradeRouteScheduleFormInputs, formSchemaToApiSchema } from '../TradeRouteCommon';
import { TransactionType } from '@amzn/cip-bff-schema';
import { formatFromEpoch } from 'utils/dateTimeUtils';
import { Mutation } from '@amzn/cip-bff-schema';
import { useUpdateTradeRouteScheduleMutation } from 'api/baseApi';
import useValidateScheduleParams from 'hooks/useValidateScheduleParams';
import { useDispatch } from 'react-redux';
import { setTRSEditFlashbarItems, setTRSLandingFlashbarItems } from 'store/trs';
import { v4 as uuid } from 'uuid';

interface TRSEditPageProps {
  scheduleId: string;
}

const TRSEditPage: React.FC<TRSEditPageProps> = ({ scheduleId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();

  const [updateSchedule, { isLoading }] = useUpdateTradeRouteScheduleMutation();

  const fromCountryVal = queryParams.get('fc') as string;
  const toCountryVal = queryParams.get('tc') as string;
  const transactionTypeVal = queryParams.get('tt') as TransactionType;

  const { schedule, isLoading: isCheckLoading } = useValidateScheduleParams(
    scheduleId,
    fromCountryVal,
    toCountryVal,
    transactionTypeVal
  );

  const transactionType = getOptionByValue(transactionTypeVal, TRANSACTION_TYPES);
  const fromCountry = getOptionByValue(fromCountryVal, COUNTRIES);
  const toCountry = getOptionByValue(toCountryVal, COUNTRIES);

  const effectiveDate = schedule?.effectiveDate ? formatFromEpoch(schedule?.effectiveDate) : undefined;
  const endDate = schedule?.endDate ? formatFromEpoch(schedule?.endDate) : undefined;
  const hasRequiredFields = transactionType && fromCountry && toCountry && effectiveDate;

  const onSubmit: SubmitHandler<TradeRouteScheduleFormInputs> = async (data) => {
    const apiSchemaRouteSchedule = formSchemaToApiSchema.parse(data);

    const res = await updateSchedule({
      ...apiSchemaRouteSchedule,
      transactionType: transactionTypeVal,
      fromCountry: fromCountryVal,
      toCountry: toCountryVal,
      scheduleId: scheduleId as string,
    });
    if ((res as { data: Mutation })?.data) {
      dispatch(
        setTRSLandingFlashbarItems([
          {
            header: 'Schedule Updated Successfully',
            type: 'success',
            content: `The trade route schedule has been updated successfully.`,
            dismissId: uuid(),
          },
        ])
      );
      navigate(`/trade-routes?${queryParams.toString()}`);
    } else {
      dispatch(
        setTRSEditFlashbarItems([
          {
            header: 'Error',
            type: 'error',
            content: `There was an error updating the trade route schedule.`,
            dismissId: uuid(),
          },
        ])
      );
    }
  };

  const scheduleInfoHeader = <Header>Schedule Info</Header>;
  const scheduleInfo = (
    <Container header={scheduleInfoHeader}>
      <ColumnLayout columns={2} variant="text-grid">
        <SpaceBetween size="l">
          <ValueWithLabel label="From Country">{fromCountry?.label ?? '-'}</ValueWithLabel>
          <ValueWithLabel label="To Country">{toCountry?.label ?? '-'}</ValueWithLabel>
          <ValueWithLabel label="Transaction Type">{transactionType?.label ?? '-'}</ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="l">
          <ValueWithLabel label="Effective Date">{effectiveDate ?? '-'}</ValueWithLabel>
          <ValueWithLabel label="End Date">{endDate ?? '-'}</ValueWithLabel>
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  );

  const scheduleEdit = (
    <TRSCommonForm
      isLoading={isLoading}
      isEdit
      onSubmit={onSubmit}
      formHeaderText="Schedule Edit"
      submitText="Save Changes"
      cancelRoute={`/trade-routes?${queryParams.toString()}`}
      routeSchedule={schedule}
    />
  );

  return hasRequiredFields && !isCheckLoading ? (
    <SpaceBetween size="l">
      {scheduleInfo}
      {scheduleEdit}
    </SpaceBetween>
  ) : (
    <Spinner />
  );
};

export default TRSEditPage;
