import { ColumnLayout, Container, Header, SpaceBetween, StatusIndicator } from '@amzn/awsui-components-react';
import {
  COUNTRIES,
  ROUTE_STATUSES,
  SCHEDULE_STATUSES,
  TRANSACTION_TYPES,
  VAT_STATUS,
} from '@amzn/gtpccipstatic-config/dist/constants';
import { getOptionByValue } from 'utils/formUtils';
import { formatFromEpoch } from 'utils/dateTimeUtils';
import ValueWithLabel from 'components/ValueWithLabel';
import { RouteSchedule, TaxInfo } from '@amzn/cip-bff-schema';

export const TaxRateInformation: React.FC<{ taxInfo: TaxInfo }> = ({ taxInfo }) => {
  let taxRateElement;
  if (taxInfo.legalEntityBasedTaxRate) {
    const leBasedTaxRate = taxInfo.legalEntityBasedTaxRate;
    const sameLegalEntityTaxRate = leBasedTaxRate.sameLegalEntityTaxDocumentNotRequired
      ? 'Not Required'
      : `${leBasedTaxRate.sameLegalEntityTaxRatePercentage}%`;
    taxRateElement = (
      <>
        <ValueWithLabel label="Tax Rate - Same Legal Entity">{sameLegalEntityTaxRate}</ValueWithLabel>
        <ValueWithLabel label="Tax Rate - Different Legal Entities">{`${leBasedTaxRate.differentLegalEntitiesTaxRatePercentage}%`}</ValueWithLabel>
      </>
    );
  } else if (taxInfo.genericTaxRatePercentage !== undefined && taxInfo.genericTaxRatePercentage !== null) {
    taxRateElement = (
      <ValueWithLabel label="Tax Rate - Generic">{`${taxInfo.genericTaxRatePercentage}%`}</ValueWithLabel>
    );
  } else if (taxInfo.taxRateStatus) {
    const taxRateStatus = getOptionByValue(taxInfo.taxRateStatus, VAT_STATUS);
    taxRateElement = <ValueWithLabel label="Tax Rate Status">{taxRateStatus?.label ?? '-'}</ValueWithLabel>;
  }
  return (
    <SpaceBetween size="l">
      {taxRateElement}
      <ValueWithLabel label="Tax Comments"> {taxInfo.taxComments ?? '-'}</ValueWithLabel>
    </SpaceBetween>
  );
};

interface TRSDetailPageProps {
  schedule: RouteSchedule;
  isCurrentSchedule: boolean;
}

const TRSDetailPage: React.FC<TRSDetailPageProps> = ({ schedule, isCurrentSchedule }) => {
  const transactionType = getOptionByValue(schedule?.transactionType, TRANSACTION_TYPES);
  const fromCountry = getOptionByValue(schedule?.fromCountry, COUNTRIES);
  const toCountry = getOptionByValue(schedule?.toCountry, COUNTRIES);
  const routeStatus = getOptionByValue(schedule?.routeStatus, ROUTE_STATUSES);
  const scheduleStatus = getOptionByValue(schedule?.scheduleStatus, SCHEDULE_STATUSES);

  const scheduleInfoHeader = <Header>Schedule Info</Header>;
  const scheduleInfo = (
    <Container header={scheduleInfoHeader}>
      <ColumnLayout columns={3} variant="text-grid">
        <SpaceBetween size="l">
          <ValueWithLabel label="From Country">{fromCountry?.label ?? '-'}</ValueWithLabel>
          <ValueWithLabel label="To Country">{toCountry?.label ?? '-'}</ValueWithLabel>
          <ValueWithLabel label="Transaction Type">{transactionType?.label ?? '-'}</ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="l">
          <ValueWithLabel label="Effective Date">{formatFromEpoch(schedule?.effectiveDate) ?? '-'}</ValueWithLabel>
          <ValueWithLabel label="End Date">
            {schedule?.endDate ? formatFromEpoch(schedule?.endDate) : '-'}
          </ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="l">
          <ValueWithLabel label="Schedule Status">
            <StatusIndicator type={schedule?.scheduleStatus === 'INACTIVE' ? 'error' : 'success'}>
              {scheduleStatus?.label}
            </StatusIndicator>
          </ValueWithLabel>
          <ValueWithLabel label="Within Effective Date Range?">{isCurrentSchedule ? 'Yes' : 'No'}</ValueWithLabel>
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  );

  const scheduleDetailsHeader = <Header>Schedule Details</Header>;
  const scheduleDetails = (
    <Container header={scheduleDetailsHeader}>
      <ColumnLayout columns={4} variant="text-grid">
        <SpaceBetween size="l">
          <ValueWithLabel label="Route Status">{routeStatus?.label ?? '-'}</ValueWithLabel>
          <ValueWithLabel label="Used Equipment Allowed">
            {schedule?.usedEquipmentAllowed ? 'Yes' : 'No'}
          </ValueWithLabel>
          <ValueWithLabel label="UEEE Declaration Required">{schedule?.ueeeNeeded ? 'Yes' : 'No'}</ValueWithLabel>
          <ValueWithLabel label="Manual Trade Action Required">
            {schedule?.manualTradeActionRequired ? 'Yes' : 'No'}
          </ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="l">
          <ValueWithLabel label="Commercial Invoice Required">
            {schedule?.commercialInvoiceRequired ? 'Yes' : 'No'}
          </ValueWithLabel>
          <ValueWithLabel label="Tax Invoice Required"> {schedule?.vatInvoiceRequired ? 'Yes' : 'No'}</ValueWithLabel>
          {schedule?.taxInfo ? (
            <TaxRateInformation taxInfo={schedule?.taxInfo} />
          ) : (
            <ValueWithLabel label="Tax Rate Information">{'-'}</ValueWithLabel>
          )}
        </SpaceBetween>
        <SpaceBetween size="l">
          {/* Use ternaries here instead of null coalescing as the value for these string fields can be an empty string. In that case we still want to show '-' */}
          <ValueWithLabel label="CI Statement">{schedule?.ciStatement ? schedule?.ciStatement : '-'}</ValueWithLabel>
          <ValueWithLabel label="Tax Statement">{schedule?.vatStatement ? schedule?.vatStatement : '-'}</ValueWithLabel>
          <ValueWithLabel label="COO Required">{schedule?.cooRequired ? 'Yes' : 'No'}</ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size="l">
          <ValueWithLabel label="Importer of Record">{schedule?.ior ?? '-'}</ValueWithLabel>
          <ValueWithLabel label="Exporter of Record">{schedule?.eor ?? '-'}</ValueWithLabel>
          <ValueWithLabel label={<>Incoterms&reg; Rule</>}>{schedule?.incoterms ?? '-'}</ValueWithLabel>
          <ValueWithLabel label="Packing List Required"> {schedule?.packingListRequired ? 'Yes' : 'No'}</ValueWithLabel>
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  );

  return (
    <>
      <SpaceBetween size="l">
        {scheduleInfo}
        {scheduleDetails}
      </SpaceBetween>
    </>
  );
};

export default TRSDetailPage;
